import Popup from 'reactjs-popup';
import styled from 'styled-components';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { IconButton } from '../Components/HelperComponents';
import { useEffect, useState } from 'react';
import { useUserState } from "../Context/UserContext"
import { useDataState } from "../Context/DataContext"
import moment from 'moment';
import Header from '../Components/Header';
import Button from '../Components/Button';
import Colors from '../Utilities/Colors';
import DataManager from '../Managers/DataManager';
import Aux from '../Managers/AuxManager';
import { async } from '@firebase/util';
import Loader from '../Components/Loader';
import ProgressBar from "../Components/ProgressBar"

export default function AchievementsModal({ active, onClose }) {

    const [loadingLikes, setLoadingLikes] = useState(false)
    const { currentStatistics, currentUser } = useUserState()

    useEffect(() => {
        console.log(currentStatistics)
    }, [])

    const claimLikesOrComments = async (amount, isLike) => {
        try {
            if(loadingLikes || !currentUser.uid) return
            await DataManager.LikesOrCommentsClaimReward(currentUser.uid, amount, isLike).then(() => {
                
            })
            setLoadingLikes(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingLikes(false)
        }
    }

    const RenderEarn = ({ title, text, currentlyHave, totalNeeded, onClick }) => {

        const progress = currentlyHave / totalNeeded
        const isComplete = currentlyHave >= totalNeeded
        return (
            <EarnContainer>
                <EarnTitle>{title}</EarnTitle>
                <div className='flex'> 
                    <div>
                        <EarnText>{text}</EarnText>
                        <ProgressBar percentage={progress * 100} />
                    </div>
                    {isComplete && <Button text={"Claim"} size="medium" onClick={onClick} className="m-t-10" isLoading={loadingLikes} isDisabled={!isComplete}/>}
                </div>
            </EarnContainer>
        )
    }


    return (
        <StyledPopup
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close =>
                <Container>
                    <Header text="Achievements " onClose={() => close()} />
                    <RenderEarn title="Likes" text="Every 10 likes get 1$" currentlyHave={currentStatistics.likesToEarn} totalNeeded={10} onClick={() => claimLikesOrComments(1, true)} />
                    <RenderEarn title="Comments" text="Every 10 comments get 5$" currentlyHave={currentStatistics.commentsToEarn} totalNeeded={10} onClick={() => claimLikesOrComments(5, false)}/>
                </Container>
            }
        </StyledPopup>);
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 300px;
        border-radius: 10px;
        background-color: white;
    }
`;

const EarnContainer = styled.div`
    display: flex;
    flex-direction: column;
    
`
const EarnTitle = styled.h3`

`

const EarnText = styled.p`

`

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
`
