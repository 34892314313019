import React from "react";

export default function RichlessIcon({style}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style}>
      <g data-name="Layer 2" >
        <g data-name="Layer 1">
          <path fill="#fff" d="M0 0H512V512H0z"></path>
          <path
            fill="#019cf8"
            d="M403.85 315.86l-282.79 31.86 53.79-205.28 147.49-93.73z"
            data-name="Path 44"
          ></path>
          <path
            fill="#0390d6"
            d="M121.06 347.72l114.63 92.11 33.08-108.76z"
            data-name="Path 45"
          ></path>
        </g>
      </g>
    </svg>
  );
}