import styled from "styled-components"
import { useState } from "react"
import { IconButton } from "../Components/HelperComponents"
import Slider from "../Components/Slider"
import Colors from "./Colors"
import MinusIcon from 'mdi-react/MinusIcon'
import PlusIcon from "mdi-react/PlusIcon"
import ArrowDownIcon from "mdi-react/ChevronDownIcon"
import ArrowUpIcon from "mdi-react/ChevronUpIcon"
import UnfoldMoreIcon from "mdi-react/UnfoldMoreVerticalIcon"
import NumberFormat from "react-number-format";
import Button from "../Components/Button"
import { useUserState } from "../Context/UserContext"
import { useNavigate } from "react-router-dom"
import Separator from "../Components/Separator"

export default function AddAmount({ amount, setAmount, isBalance = false, isComment = false, isPost = false }) {
    const { currentUser } = useUserState();
    const [showSlider, setShowSlider] = useState(false)

    const [showExtraComment, setShowExtraComment] = useState(false)
    const navigate = useNavigate();

    const handleIncrement = (number) => {
        if (amount + number >= currentUser.amount && !isBalance) {
            setAmount(currentUser.amount)
        } else if (!amount && currentUser.amount > 0 && !isBalance) {
            setAmount(number)
        }
        if (!amount || amount < 0) {
            setAmount(number)
        }
        else {
            setAmount(amount + number);
        }
    }

    const handleBalanceIncrement = (number) => {
        if (!amount || amount < 0) {
            setAmount(number)
        }
        else {
            setAmount(amount + number);
        }
    }

    const handleDecrement = () => {
        if (!amount || amount < 0) {
            setAmount(0)
        } else {
            setAmount(amount - 1);
        }
    }

    const AddSingleAmount = ({ amount }) => {
        return (
            <Button variant="secundary" size="medium" text={"+" + amount + "$"} onClick={() => handleIncrement(amount)} />
        )
    }

    const CommentAmount = () => {
        return (
            <CommentContainer>
                <div className="flex-between">
                    <p>Amount</p>
                    <div className="flex-end">


                        <StyledNumberFormat
                            placeholder="$0"
                            value={amount}
                            thousandSeparator={true}
                            prefix={'$'}
                            allowNegative={false}
                            decimalScale={0}
                            style={{ textAlign: "right" }}
                            onValueChange={(values) => {
                                const { floatValue } = values;
                                var myValue = floatValue

                                if (floatValue > currentUser.amount && !isBalance) {
                                    myValue = currentUser.amount
                                }
                                setAmount(myValue);
                            }} />
                        <IconButton backgroundColor={Colors.secundary} onClick={() => setShowExtraComment(!showExtraComment)}>
                            {showExtraComment ?
                                <ArrowUpIcon color={Colors.secundaryDarker} />
                                :
                                <ArrowDownIcon color={Colors.secundaryDarker} />
                            }
                        </IconButton>
                    </div>
                </div>
                {showExtraComment ?
                    <div className="flex-around wrap align-center">
                        <AddSingleAmount amount={1} />
                        <AddSingleAmount amount={5} />
                        <AddSingleAmount amount={25} />
                        <AddSingleAmount amount={100} />
                        <AddSingleAmount amount={500} />
                        <IconButton onClick={() => setShowSlider(!showSlider)}>
                            <UnfoldMoreIcon />
                        </IconButton>
                    </div>
                    : null}
                {showSlider && <Slider max={currentUser.amount} min={0} value={amount} onChange={(amt) => { const num = parseInt(amt.target.value, 10); setAmount(num) }} />}
            </CommentContainer>
        )
    }

    const BalanceDetailed = () => {
        return (
            <BalanceDetailedContainer>
                <p>Current Balance</p>
                <div className="flex">
                    <CurrentAmountText>{currentUser.amount || "0"}$</CurrentAmountText>
                    <IconButton onClick={() => navigate("/account/balance")} style={{ marginLeft: 10 }} backgroundColor={Colors.primary}>
                        <PlusIcon color="white" />
                    </IconButton>
                </div>
            </BalanceDetailedContainer>
        )
    }

    const BalanceMinimal = () => {
        return (
            <>
                {/* <Separator /> */}
                <BalanceMinimalContainer>

                    <p>Available Balance</p>
                    <div className="flex">
                        <CurrentAmountText>{currentUser.amount || "0"}$</CurrentAmountText>
                        <IconButton onClick={() => navigate("/account/balance")} style={{ marginLeft: 10 }} backgroundColor={Colors.primary}>
                            <PlusIcon color="white" />
                        </IconButton>
                    </div>

                </BalanceMinimalContainer>
                {/* <Separator /> */}
            </>
        )
    }

    const NoBalance = () => {
        return (
            <NoBalanceContainer>
                <p>You need to add some balance to continue.</p>
                <Button text="Add Balance" size="small" style={{ marginLeft: 5 }} onClick={() => navigate("/account/balance")} />
            </NoBalanceContainer>
        )
    }

    const MinimalSelectAmount = () => {
        return (
            <div className="flex-between">
                <p className="m-l-10">Select Amount</p>
                <NumberContainerMinimal>

                    <StyledNumberFormat
                        placeholder="$0"
                        value={amount}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        decimalScale={0}
                        // style={{textAlign:"right", backgroundColor:"rgb(248, 248, 248)"}}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            var myValue = floatValue

                            if (floatValue > currentUser.amount && !isBalance) {
                                myValue = currentUser.amount
                            }
                            setAmount(myValue);
                        }} />

                    <div className="flex column">
                        <ArrowUpIcon className={"simple-hover"} size={17} onClick={() => handleIncrement(1)}/>
                        <ArrowDownIcon className={"simple-hover"}  size={17} onClick={() => handleDecrement(1)}/>
                    </div>
                </NumberContainerMinimal>
            </div>
        )
    }


    const SelectAmount = () => {
        return (
            <>
            <p className="">Select Amount</p>
                <NumberContainer>
                           <MinusIcon onClick={handleDecrement} color={Colors.primary} className="simple-hover"/>
                             {/* <IconButton >
                            <MinusIcon onClick={handleDecrement}/>
                        </IconButton>  */}
                    <StyledNumberFormat
                        placeholder="$0"
                        value={amount}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        decimalScale={0}
                        // style={{textAlign:"right", backgroundColor:"rgb(248, 248, 248)"}}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            var myValue = floatValue

                            if (floatValue > currentUser.amount && !isBalance) {
                                myValue = currentUser.amount
                            }
                            setAmount(myValue);
                        }} />
                        <PlusIcon onClick={() => handleIncrement(1)} color={Colors.primary} className="simple-hover"/>
                            
                </NumberContainer>
            </>
        )
    }

    const ShowSlider = () => {
        /* {showSlider &&  */ return <Slider max={isBalance ? undefined : currentUser.amount} min={0} value={amount} onChange={(amt) => { const num = parseInt(amt.target.value, 10); setAmount(num) }} /> //}
    }

    if(isComment)
        return <CommentAmount/>
    
    if(isPost){
        return <>
            <BalanceDetailed/>
            <SelectAmount/>
            <Slider max={isBalance ? undefined : currentUser.amount} min={0} value={amount} onChange={(amt) => { const num = parseInt(amt.target.value, 10); setAmount(num) }} />
            <div className="flex-around m-v-10 align-center">
                <AddSingleAmount amount={5} />
                <AddSingleAmount amount={25} />
                <AddSingleAmount amount={100} />
                <AddSingleAmount amount={500} />
            </div>
        </>
    }

    if(isBalance){
        return <>
        
        </>
    }
    return (
        <>
                { currentUser.amount > 0 || isBalance ?
                    <AmountContainer>
                        {!isBalance && <BalanceMinimal />}
                        <MinimalSelectAmount /> 
                        <div className="flex-around m-v-10 align-center">
                            <AddSingleAmount amount={5} />
                            <AddSingleAmount amount={25} />
                            <AddSingleAmount amount={100} />
                            <AddSingleAmount amount={500} />
                            <IconButton onClick={() => setShowSlider(!showSlider)}>
                                <ArrowDownIcon />
                            </IconButton>
                        </div>
                        <ShowSlider/>
                    </AmountContainer>
                    :
                    <NoBalance />
            }
        </>
    )
}

const AmountContainer = styled.div`
    //background-color: ${Colors.primaryLightest};

`
const CurrentAmountText = styled.p`
    color: #aeaeae;
    font-weight: bold;
`

const StyledNumberFormat = styled(NumberFormat)`
    border:none;
    text-align: center;
    background-color: unset;
    font-size: 1.7rem;
    font-weight: bold;
     width: 70%; 
    outline: none;

`
const NumberContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px ;
   /*   border: 1px solid; 
    border-color: ${Colors.primary};  */
    //padding: 10px ;
    //border-radius: 5px;
    //width: 70%;
    margin: auto;
`

const NumberContainerMinimal = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px ;
`

const BalanceDetailedContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const BalanceMinimalContainer = styled.div`
    display: flex;    
    justify-content: space-between;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding:10px;
`

const NoBalanceContainer = styled.div`
    background-color: ${Colors.primaryLightest};
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 5px;
    margin: 10px 0px;
    flex-direction: column;
    border: 1px solid ${Colors.primary}
`

const CommentContainer = styled.div`

`