import Popup from 'reactjs-popup';
import styled from 'styled-components';
import Header from '../Components/Header';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { IconButton } from '../Components/HelperComponents';
import {
    EmailShareButton,
    FacebookShareButton,
    //LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    EmailIcon,
    //LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import Button from '../Components/Button';
import Aux from '../Managers/AuxManager';

export default function ShareModal({active, onClose, url, hashtag, message}) {

    const shareHashtag = hashtag
    const shareUrl = url || "richless.app/"
    const shareMessage = message

    const copyToClipboard = async () => {
        
        await navigator.clipboard.writeText(shareUrl)
        
    }
    return (
        <StyledPopup
            //trigger={<button className="button"> Open Modal </button>}
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close => 
                <Container>
                    
                    <Header text="Share" onClose={() => close()} />
                    <ShareButtonsContainer>
                        <EmailShareButton
                            className="reversed-button-hover"
                            subject={shareMessage}
                            body={shareUrl}>
                            <EmailIcon round size={46}></EmailIcon>
                        </EmailShareButton>
                        <FacebookShareButton
                            className="reversed-button-hover"
                            url={shareUrl}
                            quote={shareMessage}
                            hashtag={"#" + shareHashtag}
                        >
                            <FacebookIcon round size={46}></FacebookIcon>
                        </FacebookShareButton>
                        <TwitterShareButton
                            className="reversed-button-hover"
                            url={shareUrl}
                            title={shareMessage}
                            hashtag={"#" + shareHashtag}
                        //className={classes.socialMediaButton}
                        >
                            <TwitterIcon round size={46} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            className="reversed-button-hover"
                            url={shareUrl}
                            title={shareMessage}
                            separator=":: "
                        //className={classes.socialMediaButton}
                        >
                            <WhatsappIcon round size={46} />
                        </WhatsappShareButton>
                        <RedditShareButton
                            className="reversed-button-hover"
                            title={shareMessage}
                            url={shareUrl}>
                            <RedditIcon round size={46}></RedditIcon>
                        </RedditShareButton>
                    </ShareButtonsContainer>
                    <ClipboardContainer>
                        <InputUrlArea
                            type="text"
                            value={shareUrl }
                            className="m-t-10"
                        />

                        <Button text={"Copy"} variant={"secundary"} onClick={copyToClipboard} size="medium" className={"m-t-10"}/>
                    </ClipboardContainer>

                </Container>
            }
        </StyledPopup>);
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 350px;
        border-radius: 10px;
        background-color: white;
    }
`;

const Container = styled.div`
    margin: 10px 20px;
`
const ClipboardContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

`
const InputUrlArea = styled.input`
    padding: 10px;
    //margin:  10px 0;
    border-radius:10px;
    border: 2px solid #eee;
    //margin-right: 10px; 
    outline: none;
    width: 90%;
    &:focus{
        border: 2px solid #ccc
    }
`

const ShareButtonsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`