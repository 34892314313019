import styled from 'styled-components'


export default function Slider({min, max, value, onChange}){

    
    return(
        <div className='flex align-center'>
            <ValuesText>{min || 0}$</ValuesText>
            <StyledSlider type="range" min={min || 0} max={max || 10000} step={1} value={value} onChange={onChange} />
            <ValuesText>{max || 10000}$</ValuesText>
        </div>
    )
}

const StyledSlider = styled.input`

    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0px 10px;
    &:hover{
        opacity: 1;
    }
    &::-moz-range-thumb{
        width: 25px;
        height: 25px;
        border-radius: 20px;
        background-color: black;
        border: none;
        cursor: pointer;
    }

    &::-webkit-slider-thumb{
        width: 25px;
        height: 25px;
        border-radius: 20px;
        background-color: black;
        border: none;
        cursor: pointer;
    }
`

const ValuesText = styled.p`
    color: #ccc;
`