import styled from 'styled-components'

export default function (props) {


    return (
        <>
            {props.text ?
                <div className="flex-center" style={props.style}>
                    <StyledSeparator />
                    <StyledText>
                        {props.text}
                    </StyledText>
                    <StyledSeparator />
                </div> :
                <StyledSeparator />
            }
        </>
    )
}

const StyledSeparator = styled.hr`
    height: 1px;
    border:0;
    background-color: #ccc;
    margin:15px 0;
    width: 100%;

`
const StyledText = styled.p`
    margin: 0 25px;
    color:#ccc;
`