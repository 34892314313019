import React, { useContext, useEffect, useState } from 'react'
import { db } from '../firebase'
import { getDoc, setDoc, addDoc, collection, serverTimestamp, doc, where, query, getDocs, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore'
import { useAuthState } from '../Context/AuthContext'
import Aux from '../Managers/AuxManager'
import axios from 'axios'
import moment from "moment"
import DataManager from '../Managers/DataManager'
import { useUserState } from './UserContext'
import PostsManager from '../Managers/PostsManager'


export const DataContext = React.createContext()

export const DataContextProvider = (props) => {

	const [pendingCode, setPendingCode] = useState("")
	const [pendingReferral, setPendingReferral] = useState("")

	const [currentTimestamp, setCurrentTimestamp] = useState(0)

    const [hashtags, setHashtags] = useState(null)
    const [users, setUsers] = useState(null)


	const [hasNewDailyReward, setNewDailyReward] = useState(false)
    const [currentMenu, setCurrentMenu] = useState("")
    const {currentUser} = useUserState()
    

    useEffect(() => {
        if(currentUser?.uid){
            //CheckIfDailyReward(currentUser)
        }
        
    },[currentUser])

    useEffect(() => {
        getTopHashtags();
        getTopUsers();
    },[])

    const getTopHashtags = async() => {
        try {
            PostsManager.getAllPosts("topHashtags").then((data) => {
                if(!data.empty){
                    const hashs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setHashtags(hashs)
                }
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const getTopUsers = async() => {
        try {
            PostsManager.getAllPosts("topUsers").then((data) => {
                if (!data.empty) {
                    const users = data.docs.map((doc) => ({ username: doc.data().username, amountSpent: doc.data().amountSpent, avatar: doc.data().avatar , id: doc.id }))
                    setUsers(users)
                }
            })
        } catch (error) {
            Aux.handleError(error)
        }

    }

    const getTimestampFromServer = async () => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL_TIME)
            if (result.status === 200) {
                if (result.data?.date) {
                    let newDate = moment(result.data?.date).unix()
                    setCurrentTimestamp(newDate)
                    return newDate;
                }
            }
            return null
        } catch (error) {
            Aux.handleError(error)
            return null;
        }
    }

    const getCurrentTimestamp = async () => {
        if (currentTimestamp > 0) return currentTimestamp
        else {
            const newDate = await getTimestampFromServer();
            if (newDate) return newDate;
        }
        return null;
    }

    const CheckIfDailyReward = async (user,) => {
        
        if (!user.uid) return;
        if (user.system?.lastDayReward === 0 || !user.system?.lastDayReward) {
            setNewDailyReward(true);
        }
        const currentTime = await getCurrentTimestamp()
        if (currentTime > 0 && user.system?.lastTimestamp) {
            let previousDate = moment(user.system.lastTimestamp.toDate()).unix() + 24 * 60 * 60
            if (currentTime > previousDate) {
                setNewDailyReward(true);
            }
        }
    }



    return <DataContext.Provider
        value={
            {
                pendingCode,
				setPendingCode,
				pendingReferral,
				setPendingReferral,
                currentMenu,
                setCurrentMenu,
                
				hasNewDailyReward,
				getCurrentTimestamp,

                hashtags,
                users,
                
            }} {...props}
    >
        {props.children}
    </DataContext.Provider>
}

export const useDataState = () => {
    const auth = useContext(DataContext)
    return { ...auth, }
}