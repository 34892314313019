import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from '../../Utilities/Colors'

export default function UsernameText({user, size, style, color, isBlack}){
    return(
        <StyledParagraph style={style} to={`/u/${user?.username}`} isblack={isBlack} size={size} onClick={(e) => e.stopPropagation()}>
            {user?.username || "..."}
        </StyledParagraph>
    )
}

const StyledParagraph = styled(Link)`

    font-size: ${props => props.size ? props.size : "16px"};
    color: ${props => props.color ? props.color : Colors.textDarkest};
    margin: 0;
    text-decoration: none;
    overflow: hidden;
    font-weight: ${props => props.isblack? "bold" : "500"};
    text-overflow: ellipsis;
    
    &:hover{
        opacity: 0.7;
    }
`