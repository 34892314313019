import styled from "styled-components"


export default function ProgressBar({percentage}){

    const Filler = ({percentage}) => {
        var myPercentage = percentage > 100 ? 100 : percentage
        return <StyledFiller percentage={myPercentage} style={{ width: `${myPercentage}%` }} />
    }

    return (
        <StyledProgressBar>
                <Filler percentage={percentage}/>
        </StyledProgressBar>
    )
}

const StyledFiller = styled.div`

    background: #1DA598;
    height: 100%;
    width: ${props => props.percentage}%;
    border-radius: inherit;
    transition: width .2s ease-in;
`

const StyledProgressBar = styled.div`
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 50px;
    background-color: #e9e9e9;
`