import styled from "styled-components"
import Colors from "../Utilities/Colors"

export default function SelectOption({active, Render, text, onSelect, style}){
    

    return (
        <MainContainer isActive={active} style={style} onClick={onSelect}>
            {Render && <Render style={{width:50, height:35}}/>}
            {/* <span>Credit Card</span> */}
        </MainContainer>
    )
}

const MainContainer = styled.div`
    cursor: pointer;
    border: 2px solid;
    border-color: ${props => props.isActive ? Colors.primary : "#ccc" };
    background-color: #f9f9f9;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    &:hover{
        background-color: #f1f1f1;
        border-color: ${props => props.isActive ? Colors.primary : "#888"  };
    }

    & span{
        font-size: 10px;
    }
`

const ImageRender = styled.div`
    width: 50px;
    height: 50px;
`