import Avatar from "../Components/User/Avatar";
import styled from 'styled-components'
import UsernameText from "../Components/User/UsernameText";
import ShareModal from "../Modal/ShareModal";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LikeOutlineIcon from "mdi-react/HeartOutlineIcon"
import LikeFilledIcon from "mdi-react/HeartIcon"
import ShareIcon from "mdi-react/ShareOutlineIcon"
import PostImage from "./PostImage";
import Aux from "../Managers/AuxManager";
import YoutubeEmbed from "../Components/Embed/YoutubeEmbed";
import { useUserState } from "../Context/UserContext"
import PostsManager from "../Managers/PostsManager";
import Loader from "../Components/Loader";
import EmptyContainer from "../Components/EmptyContainer";
import { setUserProperties } from "firebase/analytics";


export default function PostCard({ item, isLiked, isComment }) {
    const [post, setPost] = useState(isLiked || isComment ? null : item)
    const [shareModal, setShareModal] = useState(false);
    const navigate = useNavigate();
    const [triggerLikeIcon, setTriggerLikeIcon] = useState(false);

    const [loadingLiked, setLoadingLiked] = useState(false);
    const [loadingLike, setLoadingLike] = useState(false);
    const [liked, setLiked] = useState(isLiked || false);
    const { currentUser } = useUserState()

    useEffect(() => {
        if (item.postid) {
            getInfo(item.postid)
        }

    }, [])

    const getInfo = async (id) => {
        if (!id) return
        try {

            const snapshot = await PostsManager.getPost(id);
            if (snapshot.exists) {
                setPost({ ...snapshot.data(), id: snapshot.id })
                //checkIfLiked(snapshot.id)
            }
        } catch (error) {
            Aux.handleError(error)
        }
    }
    return (
        <>
            <ShareModal active={shareModal} onClose={() => setShareModal(false)} url={`https://richless.app/post/${post.id}`} hashtag={post.hashtag} message={post.text}/>
            {post ?
            <CardContainer onClick={() => navigate(`/post/${post.id}`, { state: { post: post, isLiked: liked } })}>
                <CardHeader>
                    <div className="flex-start align-center">
                        <Avatar user={{ avatar: post.avatar, username: post.username }} uselink height={40} />
                        <div className="flex column m-l-10">
                            <UsernameText user={{ username: post.username }} ></UsernameText>
                            {post.hashtag && <SubHashtagText to={`/hashtag/${post.hashtag}`} onClick={(e) => e.stopPropagation()}>#{post.hashtag}</SubHashtagText>}
                        </div>
                    </div>
                    <h3>{post.amount}$</h3>
                </CardHeader>


                <CardBody>
                    <RenderText post={post} />
                    {/* <span>{post.text}</span> */}
                    {post.image ? <PostImage {...post} />
                        : post.youtubeId ? <YoutubeEmbed embedId={post.youtubeId} />
                            : null}
                </CardBody>

                <div className="flex-between">
                <CardFooter>
                    <>
                        <RenderLikeButton checkIfLiked={!isLiked} post={post} setIfLiked={setLiked} isLiked={isLiked}/>
                        <LikeText>{post.likeCount || 0}</LikeText>
                        <CommentText>Comments {""} {post.commentCount || 0}</CommentText>
                    </>
                    
                </CardFooter>
                <StyledShareIcon onClick={(e) => {e.stopPropagation(); setShareModal(true)}}/>
                </div>
            </CardContainer>
            :
            <EmptyContainer isLoading/>}
        </>
    )
}

export const RenderText = ({ post }) => {
    if (!post.text) return null;

    const data = post.text.split(" ")
    if (data.lenght > 0) {
        data.map((str) => {
            if (Aux.checkIfUrl(str)) return <LinkText href={`${str}`} target="_blank" rel="noopener noreferrer">{str}</LinkText>
            else if (str.startsWith("#")) return <HashtagText to={`/hashtag/${str.toLowerCase()}`}>{str}</HashtagText>;
            return <NormalText>{str + " "}</NormalText>
        })
    } else {
        return <NormalText>{post.text}</NormalText>
    }

}

export const RenderLikeButton = ({ post, isLiked, checkIfLiked, setIfLiked }) => {

    const [liked, setLiked] = useState(isLiked || false)
    
    const [alreadyLiked, setAlreadyLiked] = useState(false);
    const [loadingLike, setLoadingLike] = useState(false);
    const { currentUser } = useUserState()

    useEffect(() => {
        if (checkIfLiked) {
            checkIsLiked()
        }
    }, [currentUser.uid])
 
    const checkIsLiked = async () => {
        if (!currentUser.uid || alreadyLiked || !post.id) return;
        try {
            setAlreadyLiked(true)
            await PostsManager.checkIfUserLikedPost(post.id, currentUser.uid).then((data) => {
                if (data.exists()) {
                    setLiked(true);
                    if(setIfLiked) setIfLiked(true);
                }
            })
        } catch (error) {
            Aux.handleError(error);
        }
    }

    const toogleLikePost = async (e) => {
        e.stopPropagation()
        if (!currentUser.uid || loadingLike || !post.id) return;

        try {
            setLoadingLike(true)
            let newLike = !liked
            setLiked(newLike)
            if(setIfLiked) setIfLiked(true);
            await PostsManager.toogleLikePost(post, currentUser.uid, newLike)
            setLoadingLike(false)

        } catch (error) {
            Aux.handleError(error);
            setLoadingLike(false)
        }
    }

    return (
        <div className="flex" onClick={toogleLikePost}>
            {liked ? <LikeFilledIcon /> : <LikeOutlineIcon />}

        </div>
    )
}

const NormalText = styled.span`

`
const LinkText = styled.a`

`

const HashtagText = styled(Link)`

`
const SubHashtagText = styled(Link)`
    color: #555;
    text-decoration: none;
    font-size: 0.7rem;
`
const LikeText = styled.p`
    margin-left: 5px;
`
const CommentText = styled.p`
    margin-left: 5px;
`
const CardContainer = styled.div`

    text-decoration: none;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    margin: 10px 0;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 25px;
    padding-bottom: 20px;
    width: 90%;
    border-radius: 10px;
    background-color: white;
`

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`
const CardBody = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin: 10px 0px;
`

const CardFooter = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    
`

const StyledShareIcon = styled(ShareIcon)`
    &:hover{
        opacity:0.7
    }
`