import { useState } from 'react'
import styled from 'styled-components'
import { css } from 'styled-components';

export default function InputTextMaterial({value, onChange, defaultValue, placeholder, title, type}) {

    
    const myValue = value || defaultValue || ""
    function handleChange(e) {
        //setValue(e.target.value);
        onChange(e.target.value);
    }
    return (
        <>
            <InputContainer value={value}>
                <input type={type || "text"} value={value} defaultValue={defaultValue} placeholder={placeholder} onChange={handleChange} />
                <label >{title}</label>
            </InputContainer>
        </>
    )


}

const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 50px;
    border: 2px #ccc solid;
    border-radius: 4px;
    margin: 10px 0px;

    &:focus-within{
        & label{
            transform: translate(0, 7px) scale(0.8);
            color: #0a53e4;
        }
    }

    & label{
        position: absolute;
        pointer-events: none;
        transform: translate(0, 16px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #999;
        font-size: 16px;
        line-height: 1;
        left: 16px;

         ${props => props.value && css`
            transform: translate(0, 7px) scale(0.8);
        `}
    }

    & input {
        height: 50px;
        border-radius: 4px;
        border: none;
        padding: 18px 16px 4px 16px;
        font-size: 16px;
        line-height: 1;
        outline: none;
        box-shadow: none;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        &:focus{
            box-shadow: 0 0 0 3px #79b1ff; 
        }
    }

    

`