import { PayPalButtons } from "@paypal/react-paypal-js"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Aux from "../Managers/AuxManager"
import styled from "styled-components"
import EmptyContainer from "../Components/EmptyContainer"
import Colors from "../Utilities/Colors"


export default function PaypalSingleton(props){
    
    const [orderAmount, setOrderAmount] = useState(0)

    const {search} = useLocation()

    useEffect(()=> {
        const url = new URLSearchParams(search);
        const myAmount =  url.get("amount");
        if(myAmount)
            setOrderAmount(myAmount)
    },[])

    const createOrder = (data, actions) => {
        if(orderAmount <= 0) return null

        try {
            return actions.order.create({
                purchase_units: [
                    {
                      amount: {
                        value: orderAmount,
                      },
                    },
                  ],
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const onApprove = async(data,actions) => {
        let order = await actions.order.capture();
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(order));
        return order;
    }

    const onError = (error) => {
        Aux.handleError(error)
        const errObj = {
            error: error,
            status: "FAILED",
          };
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
    }
    return (
    <StyledContainer>
        {orderAmount > 0 ? 
            <div>
                <div className="flex-center align-center column m-b-10">
                    <p>price:</p>
                    <p style={{ color: Colors.mainColor, marginLeft: 5, fontWeight: "bold", fontSize:20 }}> {orderAmount || 0}.00$</p>
                    <p style={{ color: Colors.textLighter, marginLeft: 5, fontWeight: "bold", fontSize:14 , marginTop:10}}>Click below to continue</p>
                </div>
                <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                    onCancel={() => onError("CANCELED")}
                    onError={(err) => onError(err)}
                />
            </div>
        : 
        <EmptyContainer text="No amount was selected."/>   
    }
    </StyledContainer>
    )
}

const StyledContainer = styled.div`
    margin: auto;
    width: 50%;
    margin-top: 150px;
`