import React, {useContext, useEffect, useState} from 'react'
import {auth} from '../firebase'
import {onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, 
  GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, updateEmail, updatePassword,
  deleteUser, EmailAuthProvider, reauthenticateWithCredential} from 'firebase/auth'

export const AuthContext = React.createContext()

export const AuthContextProvider = props => {
  const [authUser, setAuthUser] = useState({})
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true);

  const googleProvider = new GoogleAuthProvider();
  googleProvider.addScope("email")
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user)
      console.log("Authed in as ", user?.email)
      setLoading(false);
    }, setError)
    return () => unsubscribe()
  }, [])

  function loginGoogle() {
    return signInWithPopup(auth, googleProvider)
  }

  function logout() {
    return signOut(auth)
  }

  function registerUser(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function loginEmail(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function forgotPassword(email){
    return sendPasswordResetEmail(auth,email)
  }

  function changeEmail(email){
    return updateEmail(auth.currentUser, email)
  }

  function changePassword(password){
    return updatePassword(auth.currentUser, password)
  }

  function deleteAccount(){
    return deleteUser(auth.currentUser)
  }

  function reauthenticateUser(password){
    const credential = EmailAuthProvider.credential(auth.currentUser.email, password)
    return reauthenticateWithCredential(auth.currentUser, credential)
  }

  return <AuthContext.Provider 
    value= {{
      authUser, 
      error, 
      loading,
      registerUser,
      loginEmail,
      loginGoogle,
      forgotPassword,
      changeEmail,
      changePassword,
      deleteAccount,
      reauthenticateUser,
      logout,
      
    }} {...props}>
    {props.children}
  </AuthContext.Provider> 
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.authUser != null}
}

/* export function AuthProvider({children, value}) {
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthValue(){
  return useContext(AuthContext)
} */