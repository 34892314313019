import Container from "../Components/Container";
import Header from "../Components/Header";
import styled from "styled-components";


export default function PrivacyPolicy(){

    return(
        <Container minimal >
            <Header text="Privacy Policy"/>

            <div style={{marginTop:30}}>
                        <StyledTitle>
                            1. IDENTIFICATION OF THE HOLDER AND GENERAL INFORMATION
                        </StyledTitle>
                        <StyledText>
                            Holder: Godapp Studios
                            Email: godappstudios@gmail.com
                        </StyledText>

                        <StyledTitle>
                            2. INFORMATION AND CONSENT
                        </StyledTitle>
                        <StyledText>
                            In accordance with the provisions of Regulation(EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 regarding the protection of natural persons with regard to the processing of personal data and which repeals Directive 95/46/EC and any other development regulations, by accepting this Privacy Policy the user gives his informed, express, free and unequivocal consent so that the personal data that you provide though this platform are treated by the Holder of the same in accordance with the purposes indicated below in this privacy policy.
                        </StyledText>

                        <StyledTitle>
                            3. MANDATORY TO FACILITATE DATA
                        </StyledTitle>
                        <StyledText>
                            The data requested in the form of acess to this platform in general, are mandatory (unless otherwise specified in the required field) to meet the established purposes. Therefore, if such data is not provided or not provided correctly, the purposes of the app cannot be met, not withstanding that you can freely display, when available, the content of the Platform.
                        </StyledText>

                        <StyledTitle>
                            4. WHAT INFORMATION AND PERSONAL DATA WE COLLECT?
                        </StyledTitle>
                        <StyledText>
                            This platform will collect information regarding the device from which it is accessed, navigation data generated during user navigation. This information will be used for statistical studies, We guarantee the anonymity of this information.
                            Additionally, there are certain sections of out platform whose use and navigation can be performed by any user, without the need to register a user. However, in those cases in which the user decides to obtain full functionality of this platform, a registration must be carried out. during this process we may request identification and contact information.
                            Once this registration phase is completed, we have available to these users a private area where they can configure their personal profile, as well as expand the information including more contact and identification data, your photo and/or your email.
                            All data listed will be visible to other users expect your balance and email.
                        </StyledText>

                        <StyledTitle>
                            5. FOR WHAT PURPOSES, WE REQUEST YOUR INFORMATION?
                        </StyledTitle>
                        <StyledText >
                            the personal data requested for user registration on the Platform ( name and email) will be mandatory, it will not be possible to complete the registration otherwise.
                            the personal data that we process though the App will only ne used for the following purposes:
                            Manage the registration as a user registered in the App, Send our commercial communications periodically with news and news related to the App.
                            Show other users your visible information, so they can communicate with you outside the App.

                        </StyledText>

                        <StyledTitle>
                            6. LEGITIMATION OF THE TREATMENT AND TIME LIMIT FOR THE CONSERVATION OF YOUR DATA
                        </StyledTitle>
                        <StyledText >
                            The processing of your data in order to register you on the App, manage your profile and comments, is based on the consent granted by the interested party.
                            In relation to the treatment for sending of newsletters and commercial communications related to our services, they are based on the consent given by the user and, where appropriate, on the satisfaction of our App's legitimate interests, allowing the User perform opposition to this treatment at all times.
                            The data processed will be kept for the time necessary for the realization of the purposes for which they were collected, this is for the provision of the services and, later, until the prescription of the possible derived responsabilities.
                        </StyledText>

                        <StyledTitle>
                            7. COMMUNICATION OF DATA TO THIRD PARTIES
                        </StyledTitle>
                        <StyledText >
                            We inform you that we don't transfer or communicate your data to third companies.
                        </StyledText>

                        <StyledTitle>
                            8. DATA ACCURACY
                        </StyledTitle>
                        <StyledText >
                            The user guarantees that he or she is of legal age and that the information provided is true, accurate, complete and up-to-date, and the user is responsible for any direct or indirect damage or loss that may be caused as a result of the breach of such obligation. In the event that the data provided belonged to a third party, the user guarantees that he has informed said third party of the aspects contained in this document and obtained his authorization to provide his data to our App for the purposes indicated.
                        </StyledText>

                        <StyledTitle>
                            9. SECURITY MEASURES
                        </StyledTitle>
                        <StyledText >
                            Finally, we inform you that we will treat your data at all times in an absolutely confidential manner and keeping the mandatory duty of secrecy regarding them, in accordance with the provisions of the applicable regulations, adopting the technical measures and organizational requirements that guarantee the security of your data and prevent its alteration, loss, treatment or unauthorized access, taking into account the state of the technology, the nature of the stored data and the risks to which they are exposed.
                        </StyledText>
                        <div className="flex-center m-t-10">
                            <StyledText>
                                Last update: 3, March, 2020 Copyright 2020, Godapp Studios, all rights reserved
                            </StyledText>
                        </div>
                    </div>
        </Container>
    )
}

const StyledTitle = styled.h3`

`

const StyledText = styled.p`

`