import styled from 'styled-components'
import Colors from '../Utilities/Colors';

export const HeaderText = styled.h2`

`
export const SubHeader = styled.h3`
  color:#999;
`;

export const SubSubHeader = styled.h5`

`

export const InformationText = styled.p`
  color:#999;
`;

export const InformationTitle = styled.p`
  color:${Colors.secundaryDarkest};
  //font-weight: bold;
  margin: 3px 0px;
`
export const UsernameSpan = styled.span`
    font-size: ${props => props.fontsize ? props.fontsize : "16px"};
    margin: 0;
    overflow-wrap: break-word;
    &:hover{
        opacity: 0.7;
    }
`

export const SimpleOnHover = styled.div`
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
`

export const IconButton = styled.button`
  border-radius: 50%;
  background-color: ${props => props.backgroundColor || Colors.textLighest};
  height: ${props => props.size || 30}px;
  width: ${props => props.size || 30}px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color || Colors.textDarker};
  border: none;
  cursor: pointer;

  &:hover{
    opacity: 0.7;
  }
`
