import { useState } from 'react'
import Colors from '../../Utilities/Colors';
import UserIcon from 'mdi-react/AccountIcon'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

export default function Avatar({height, user, src, hide, useLink}) {
    const [loaded, setLoaded] = useState(false);

    let char = user?.username?.charAt(0) ;
    let source = hide ? null : (src || user?.avatar || null)

    const Initial = () => {
        return (
            <StyledAvatar loaded={loaded} height={height}>
                {char ?
                    <h1>
                        {char.toUpperCase()}
                    </h1>
                    :
                    <StyledUserIcon size={(height || 50)/2}/>
                }
            </StyledAvatar>
        )
    }
    
    const AvatarContainer = (prop) => {
        if(useLink && user?.username){
            return <StyledParagraph to={`/u/${user.username}`} onClick={(e) => e.stopPropagation()}>
                {prop.children}
            </StyledParagraph>
        }else{
            return <AvatarWrapper>
                {prop.children}
            </AvatarWrapper>
        }
    }

    return (
            <AvatarContainer>
                
                {loaded ? null : !source ? null : (
                    <Initial />
                )}
                {source ?
                    <StyledImage
                        //className="img-avatar"
                        //style={{ display: loaded ? "inline-block" : "none", width: height, height: height }}
                        src={source}
                        onLoad={() => setLoaded(true)}
                        alt="avatar"
                        loaded={loaded}
                        height={height}
                    />
                    :
                    <Initial />
                }
            </AvatarContainer>
    )

}


const AvatarWrapper = styled.div`

    display: flex;
    cursor: pointer;
    &:hover{
        opacity: ${props => props.useLink ? "0.7" : 1};
    }
`

const StyledParagraph = styled(Link)`
    text-decoration: none;
    display: flex;
    &:hover{
        opacity: 0.7;
    }
`

const StyledAvatar = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: ${props => props.height || 50}px;
    height: ${props =>props.height || 50}px;
    background-color: ${Colors.secundary};

    & h1{
        color: ${Colors.white};
        font-size: ${props => props.height / 2}px;
        align-self: center;
        font-weight: bold;
    }
`

const StyledImage = styled.img`
    border-radius: 50%;
    object-fit: cover;
    display: ${props => props.loaded ? "inline-block" : "none"};
    width: ${props =>props.height || 50}px;
    height: ${props =>props.height || 50}px;
`

const StyledUserIcon = styled(UserIcon)`
    color:${Colors.white};
    align-self: center;
`

