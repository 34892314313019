import styled from "styled-components"
import ProfileHeader from "./ProfileHeader"
import { useParams, useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import Aux from "../Managers/AuxManager";
import PostsManager from "../Managers/PostsManager";
import DataManager from "../Managers/DataManager";
import InfiniteScroll from "react-infinite-scroller"
import Loader from "../Components/Loader";
import PostCard from "../Posts/PostCard";
import ShowList from "../Components/ShowList";
import Container from "../Components/Container";
import EmptyContainer from "../Components/EmptyContainer";

export default function ViewUser(props) {

    const [user, setUser] = useState(null)
    const [loadingUser, setLoadingUser] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const { state } = useLocation();
    const { id, username } = useParams();


    useEffect(() => {
        if (state?.user?.uid) {
            setUser(state.user)
        } else if (id) {
            getUserInfo(id, false)
        } else if (props.username) {
            getUserInfo(username, true)
        } else {
            console.log("No user found")
            setNotFound(true)
        }
    }, [])

    const getUserInfo = async (id, isUsername) => {
        if (loadingUser || !id) return
        console.log("getting user " + id + "from " + isUsername)
        try {
            setLoadingUser(true);
            if (isUsername) {
                await DataManager.getUserInfoByUsername(id).then((data) => {
                    if (!data.empty) {
                        setUser({ ...data.docs[0].data(), uid: data.docs[0].id })
                    }else{
                        setNotFound(true);
                    }
                })

            } else {
                await DataManager.getUserInfo(id).then((data) => {
                    if (data.exists) {
                        setUser({ ...data.data(), uid: id })
                    }else{
                        setNotFound(true);
                    }
                })
            }


            setLoadingUser(false);
        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false);
        }

    }


    return (
        <Container minimal>
            {loadingUser ?
                <EmptyContainer isLoading />
                : !user ?
                    <EmptyContainer text="No user found."/>
                    :
                    <UserContainer>
                        <ProfileHeader user={user} loading={loadingUser} />
                        <ShowList
                            type="userPosts"
                            uid={user.uid}
                            useWindow={true}
                            Render={PostCard}
                            endText="There's no more posts"
                            emptyText={"No Posts found."}
                        />
                    </UserContainer>
            }
        </Container>
    )
}


const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
`