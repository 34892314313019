import axios from "axios"
import { useEffect, useState } from "react"
import styled from "styled-components"
import AlertMessage from "../Components/AlertMessage"
import Button from "../Components/Button"
import Container from "../Components/Container"
import Header from "../Components/Header"
import Loader from "../Components/Loader"
import SimpleInput from "../Components/SimpleInput"
import { useUserState } from "../Context/UserContext"
import DataManager from "../Managers/DataManager"
import Aux from "../Managers/AuxManager"
import CheckIfLogged from "../Checks/CheckIfLogged"
import { SubHeader } from "../Components/HelperComponents"
import Colors from "../Utilities/Colors"


export default function ReferralsAndPromos(props) {

    const [referralUser, setReferralUser] = useState({})
    const [loadingRefer, setLoadingRefer] = useState(false)
    const [isSuccessRefer, setIsSuccessRefer] = useState(false)
    const [errorRefer, setErrorRefer] = useState("")
    const [username, setUsername] = useState("")

    //const [promoCode, setPromoCode] = useState("")
    const [validCode, setValidCode] = useState({})
    const [loadingCode, setLoadingCode] = useState(false)
    const [loadingValidCode, setLoadingValidCode] = useState(false)
    const [isSuccessCode, setIsSuccessCode] = useState(false)
    const [errorCode, setErrorCode] = useState("")
    const [code, setCode] = useState("")

    const { currentUser } = useUserState()

    useEffect(() => {
        if (currentUser?.referralUser) {
            getReferUser(currentUser.referralUser)
        }
    }, [currentUser.referralUser])


    const getReferUser = async (refer) => {
        try {
            const referUser = await DataManager.getUserInfo(refer)
            if (referUser.exists) {
                setReferralUser(referUser.data())
            }
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const onSubmitReferral = async () => {
        if (loadingRefer) return;
        if (!currentUser) return;
        if (!username) { setErrorRefer("Insert a valid username."); return }
        if (currentUser.referralUser) { setErrorRefer("You've already used a referral."); return }
        if (currentUser.username === username) { setErrorRefer("Can't use your own username."); return }
        try {
            setLoadingRefer(true)
            const targetUser = await DataManager.getUserInfoByUsername(username)
            if (targetUser.exists) {
                await DataManager.createReferralLink(currentUser.uid, targetUser.id).then(() => {
                    setIsSuccessRefer(true)
                })
            } else {
                setErrorRefer("username is invalid.")
            }

            setLoadingRefer(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingRefer(false)
            setErrorRefer(Aux.UnexpectedError)
        }
    }

    const checkIfValidCode = async() => {
        if (loadingCode || !code) return;
        
        try {
            setLoadingCode(true)
            const result = await DataManager.checkIfPromoCodeIsValid(code)
            if(result?.code){
                    setValidCode(result)
            }else{
                setErrorCode("Code is invalid.")
            }
            
            setLoadingCode(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingCode(false)
            setErrorCode(Aux.UnexpectedError)
        }
    }

    const AddValidPromoCode = async() => {
        if (loadingValidCode) return;
        
        try {
            setLoadingValidCode(true)
            await DataManager.addPromoCode(currentUser.uid, validCode.code, validCode.reward).then(() => {
                setIsSuccessCode(true);
            })
            
            setLoadingValidCode(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingValidCode(false)
            setErrorCode(Aux.UnexpectedError)
        }
    }
    return (
        <Container>
            <Header text="Referral" goBack/>
            {currentUser.uid ?
            <>
                <HeaderText className="m-t-10">Referral</HeaderText>
                <DetailText>Insert your friends username below.</DetailText>
                <AlertMessage text={errorRefer} variant={"error"}/>
                {referralUser.username && <p>Want to add? {referralUser.username}</p>}
                <SimpleInput placeholder={"username"} value={username} onChange={setUsername} className="m-b-10"/>
                <Button text="Add Referral" size={"medium"} isLoading={loadingRefer} isSuccess={isSuccessRefer}/>

                <HeaderText className="m-t-10">Promo</HeaderText>
                <DetailText>Insert a valid promo code below.</DetailText>
                <AlertMessage text={errorCode} variant={"error"}/>
                {validCode.code && <p>Want to add? {validCode.code}</p>}

                <SimpleInput placeholder={"Promo code"} value={code} onChange={setCode} className="m-b-10"/>
                <Button size={"medium"} isLoading={loadingCode} isSuccess={isSuccessCode} text={validCode.code ? "Add Code" : "Check code"}/>
            </>
            : <CheckIfLogged/>}
        </Container>
    )
}

const DetailText = styled.p`
    color: ${Colors.secundaryDarker};
    font-size: 0.8rem;
`

const HeaderText = styled.h3`
    color: ${Colors.secundaryDarkest};

` 