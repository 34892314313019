import Container from "../Components/Container";
import AlertMessage from "../Components/AlertMessage";
import Loader from "../Components/Loader";
import Button from "../Components/Button";
import EmailIcon from "mdi-react/EmailIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
import styled from "styled-components"
import SimpleInput from "../Components/SimpleInput";
import { useState } from "react";
import Aux from "../Managers/AuxManager";
import DataManager from "../Managers/DataManager";
import {useUserState} from "../Context/UserContext"
import Header from "../Components/Header";
export default function Contact(props) {

    const [email, setEmail] = useState("")
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState("")
    const [isSuccess, setIsSuccess] = useState("")
    const {currentUser} = useUserState();

    const onSendMessage = async () => {
        if(!email || !title || !message){
            setError("All fields are required.")
            return;
        }
        if(loading) return;

        try{
            setLoading(true);

            await DataManager.sendContactMessage(currentUser?.uid, email, title, message).then(() => {
                setIsSuccess(true)
            })
            setLoading(false);
        }catch(error){
            Aux.handleError(error)
            setError(Aux.UnexpectedError)
            setLoading(false);
        }
    }

    const InformationItem = ({text, Icon}) => {
        return <StyledInformationItem>
            <Icon/>
            <p>{text}</p>
        </StyledInformationItem>
    }

    return (
        <Container>
            <Header text="Contact Us" goBack/>
            <AlertMessage variant={"error"} text={error}/>
            <AlertMessage variant={"success"} text={"You've sucessfully sent us a message, we'll reply shortly"} show={isSuccess}/>
            <InformationContainer>
                <InformationItem text="richlessapp@gmail.com" Icon={EmailIcon}/>
                <div className="flex-around flex-wrap">
                <InformationItem text="richless.app" Icon={InstagramIcon}/>
                <InformationItem text="richless.app" Icon={FacebookIcon}/>
                <InformationItem text="richlessApp" Icon={TwitterIcon}/>
                </div>
            </InformationContainer>
            <SimpleInput placeholder="Email or contact" value={email} onChange={setEmail} title={"Email"}/>
            <SimpleInput placeholder="Title of the message" value={title} onChange={setTitle} title={"Title"}/>
            <SimpleInput textArea placeholder="Write your message here..." value={message} onChange={setMessage} title={"Message"}/>
            <Button text="Send Message" onClick={onSendMessage} isSuccess={isSuccess} isLoading={loading} className={"m-t-10 w-100"}/>
        </Container>
    )
}

const InformationContainer = styled.div`
    display: flex;
    background-color: #f9f9f9;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 15px;

`

const StyledInformationItem = styled.div`
    display: flex;
    align-items: center;
    & p{
        margin-left: 5px;
        margin-right: 10px;
    }
`