import { useEffect, useState } from "react"
import PostsManager from "../Managers/PostsManager"
import styled from "styled-components"
import Container from "../Components/Container"
import Header from "../Components/Header"
import ShowList from "../Components/ShowList"
import AddIcon from "mdi-react/PlusIcon"
import Aux from "../Managers/AuxManager"
import CreateDummyModal from "./CreateDummyModal"
import { useUserState } from "../Context/UserContext"



export default function AdminPage(props) {

    //const [users, setUsers] = useState([])
    const [createDummyOpen, setCreateDummyOpen] = useState(false)
    const {createDummy, revertToOriginalUser} = useUserState()
    const RenderUser = ({item}) => {
        return <StyledUserContainer onClick={() => createDummy(item)} >
            <p>{item?.username}</p>
        </StyledUserContainer>
    }
    
    return(
        <Container>
            <CreateDummyModal active={createDummyOpen} onClose={() => setCreateDummyOpen(false)}/>
            <div className="flex-between align-center">
                <Header text="Admin"/>
                <AddIcon onClick={() => setCreateDummyOpen(true)}/>
            </div>
            
             <ShowList
                type="dummyUsers"

                useWindow={false}
                Render={RenderUser}
                
            />
        </Container>
    )
}

const StyledUserContainer = styled.div`
    display: flex;
    margin: 15px;
    align-items: center;
    cursor: pointer;
    background-color: white;
    &:hover{
        background-color: #f9f9f9;
    }
`
