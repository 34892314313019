import { useUserState } from '../Context/UserContext'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import Button from '../Components/Button'
import Container from '../Components/Container'
import AlertMessage from '../Components/AlertMessage'
import InputText from '../Components/InputTextMaterial'
import Avatar from '../Components/User/Avatar'
import Loader from '../Components/Loader'
import ProfileHeader from './ProfileHeader'
import styled from 'styled-components'
import { useState } from 'react'
import PostsManager from '../Managers/PostsManager'
import Aux from '../Managers/AuxManager'
import ShowList from '../Components/ShowList'
import PostCard from '../Posts/PostCard'
import CheckIfLogged from '../Checks/CheckIfLogged'

export default function MyProfile() {

	const { currentUser } = useUserState()
	const [currentTab, setCurrentTab] = useState("Posts")
	const [posts, setPosts] = useState([])
	const [comments, setComments] = useState([])
	const [likes, setLikes] = useState([])
	const [loadingPosts, setLoadingPosts] = useState(false)
	const [loadingComments, setLoadingComments] = useState(false)
	const [loadingLikes, setLoadingLikes] = useState(false)
	const [finishedPosts, setFinishedPosts] = useState(false)
	const [finishedComments, setFinishedComments] = useState(false)
	const [finishedLikes, setFinishedLikes] = useState(false)
	const [lastVisiblePosts, setLastVisiblePosts] = useState(false)
	const [lastVisibleComments, setLastVisibleComments] = useState(false)
	const [lastVisibleLikes, setLastVisibleLikes] = useState(false)

	const GetData = async () => {

		try {

			if (currentTab === "Posts") {
				if (loadingPosts) return
				setLoadingPosts(true);
				const data = await PostsManager.getAllPosts("userPosts", lastVisiblePosts, currentUser.uid)
				if (!data?.empty) {
					const newPosts = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

					if (newPosts.length < Aux.LIMIT) {
						setFinishedPosts(true);
					}

					setPosts([...posts, ...newPosts])
					setLastVisiblePosts(data.docs[data.docs.length - 1])
				} else {
					setFinishedPosts(true)
				}
				setLoadingPosts(false);
			} else if (currentTab === "Comments") {
				setLoadingComments(true);
				const data = await PostsManager.getAllPosts("userComments", lastVisibleComments, currentUser.uid)
				if (!data?.empty) {
					const newComments = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

					if (newComments.length < Aux.LIMIT) {
						setFinishedComments(true);
					}

					setComments([...comments, ...newComments])
					setLastVisibleComments(data.docs[data.docs.length - 1])
				} else {
					setFinishedComments(true)
				}
				setLoadingComments(false);
			} else if (currentTab === "Likes") {
				setLoadingLikes(true);
				console.log("entrou")
				const data = await PostsManager.getAllPosts("userLikes", lastVisibleLikes, currentUser.uid)
				if (!data?.empty) {
					const newLikes = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

					if (newLikes.length < Aux.LIMIT) {
						setFinishedLikes(true);
					}

					setLikes([...likes, ...newLikes])
					setLastVisibleLikes(data.docs[data.docs.length - 1])
				} else {
					setFinishedLikes(true)
				}
				setLoadingLikes(false);
			}


		} catch (error) {
			Aux.handleError(error)
			setLoadingComments(false);
			setLoadingLikes(false);
			setLoadingPosts(false);
		}

	}

	const Tab = (props) => {
		const isCurrentTab = props.type === currentTab
		return <StyledTab isCurrentTab={isCurrentTab} onClick={() => {setCurrentTab(props.type)}}>
			<h3>{props.type}</h3>
		</StyledTab>
	}

	const hasMore = () => {
		if (currentTab === "Posts") {
			return !finishedPosts
		} else if (currentTab === "Likes") {
			return !finishedLikes
		} else if (currentTab === "Comments") {
			return !finishedComments
		} else
			return false
	}

	return (
		<Container minimal>
			{currentUser.uid ?
				<>
					<ProfileHeader user={currentUser} />
					<TabsContainer>
						<Tab type="Posts" />
						<Tab type="Likes" />
						<Tab type="Comments" />
					</TabsContainer>
						<ShowList
							key={"profile-posts-list"}
							type={"userPosts"}
							uid={currentUser.uid}
							//loadMore={GetData}
							/* hasMore={currentTab === "Posts" ? !finishedPosts : currentTab === "Likes" ? !finishedLikes : currentTab === "Comments" ? !finishedComments : false}
							list={currentTab === "Posts" ? posts : currentTab === "Likes" ? likes : currentTab === "Comments" ? comments : posts}
							 */
							useWindow={true}
							Render={PostCard}
							endText="There's no more posts"
							emptyText={"No Posts found."}
							hide={currentTab !== "Posts"}

						/>
						<ShowList
							key={"profile-likes-list"}
							type={"userLikes"}
							uid={currentUser.uid}
							/* loadMore={GetData}
							hasMore={currentTab === "Posts" ? !finishedPosts : currentTab === "Likes" ? !finishedLikes : currentTab === "Comments" ? !finishedComments : false}
							list={currentTab === "Posts" ? posts : currentTab === "Likes" ? likes : currentTab === "Comments" ? comments : posts} */
							useWindow={true}
							Render={PostCard}
							endText="There's no more posts"
							emptyText={"No Posts found."}
							hide={currentTab !== "Likes"}

						/>
						<ShowList
							key={"profile-comments-list"}
							type={"userComments"}
							uid={currentUser.uid}
							/* loadMore={GetData}
							hasMore={currentTab === "Posts" ? !finishedPosts : currentTab === "Likes" ? !finishedLikes : currentTab === "Comments" ? !finishedComments : false}
							list={currentTab === "Posts" ? posts : currentTab === "Likes" ? likes : currentTab === "Comments" ? comments : posts} */
							useWindow={true}
							Render={PostCard}
							endText="There's no more posts"
							emptyText={"No Posts found."}
							hide={currentTab !== "Comments"}
						/>
				</>
				: <CheckIfLogged />}
		</Container>
	)
}

const StyledTab = styled.div`
  color:${props => props.isCurrentTab ? "black" : "grey"};
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
`
const TabsContainer = styled.div`
  display: flex;
  padding: 15px 35px;
`