import { useUserState } from "../Context/UserContext"
import styled from "styled-components"
import Loader from "../Components/Loader";

export default function CheckIfLogged(props) {

    const { userState } = useUserState();

    return (
        <>
            {userState === "loading" ?
                <div className="flex-center align-center m-t-10">
                    <Loader />
                </div>
                :

                <div className="flex-center align-center">
                    <span>Need to login</span>
                </div>
            }
        </>
    )

}