import React from "react";

function InviteFriendsImage({style}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.53 140.85" style={style}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect
            width="73.97"
            height="139.66"
            x="0.6"
            y="0.6"
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.192"
            rx="8.94"
          ></rect>
          <rect
            width="28.27"
            height="6.11"
            x="23.44"
            y="6.56"
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.192"
            rx="3.06"
          ></rect>
          <path
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.192"
            d="M6.06 16.35H69.09V117.53999999999999H6.06z"
          ></path>
          <circle
            cx="37.58"
            cy="128.88"
            r="7.65"
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.192"
          ></circle>
          <path
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.132"
            d="M225.79 39.09L189.88 6.42 187.54 24.27 177.24 39.02 225.79 39.09z"
          ></path>
          <path
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.132"
            d="M187.54 24.27L225.79 39.09"
          ></path>
          <path
            fill="none"
            stroke="#00a79d"
            strokeDasharray="4,3"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M94.85 43.22c31.5 15.88 44 16.26 48.26 11.49 6.23-6.88-5.67-23.24 1.53-32.17 5.74-7.12 20.3-5.37 34.47-1.53M219.58 112.72c-34.52 8.9-68.47 9-77.24-17.34-6.05-18.15 3.06-32.73 13.24-36.28 11.42-4 23.55-1.56 33.56 12.82 8.86 12.74 9 42-15.33 50.7-31.81 11.38-71.36 1.17-82.4-31.19"
          ></path>
          <path
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.132"
            d="M267.99 100.61L220 93.33 227.95 109.48 227.56 127.47 267.99 100.61z"
          ></path>
          <path
            fill="none"
            stroke="#00a79d"
            strokeMiterlimit="10"
            strokeWidth="1.132"
            d="M227.95 109.48L267.99 100.61"
          ></path>
          <text
            fill="#007f73"
            fontFamily="Aleo-Bold, Aleo"
            fontSize="12"
            fontWeight="700"
            transform="translate(209.38 14.94)"
          >
            +10$
          </text>
          <text
            fill="#007f73"
            fontFamily="Aleo-Bold, Aleo"
            fontSize="12"
            fontWeight="700"
            transform="translate(238.46 87.68)"
          >
            +10$
          </text>
          <text
            fill="#007f73"
            fontFamily="Aleo-Bold, Aleo"
            fontSize="19.457"
            fontWeight="700"
            transform="translate(15.55 76.76)"
          >
            +10$
          </text>
        </g>
      </g>
    </svg>
  );
}

export default InviteFriendsImage;