import {useState} from 'react'
import styled from "styled-components"
import TextareaAutosize from 'react-autosize-textarea';
import Colors from '../Utilities/Colors';

export default function InputTextResize({value, defaultValue, onChange, maxRows, minRows, placeholder, onBlur, isBorder, style, maxLenght}) {
    const [newvalue, setValue] = useState(defaultValue);

    const handleChange =(e) =>{
        e.preventDefault();
        setValue(e.target.value);
        
        onChange(e.target.value);
        console.log(e.target.value)
    }
    
    
    return(
            <StyledTextareaAutosize  
                placeholder={placeholder} value={newvalue} onChange={handleChange} 
                maxRows={maxRows || 5} rows={minRows || 3} style={style} maxLength={maxLenght}/>

    )
}

const StyledTextareaAutosize= styled(TextareaAutosize)`
    font-size: 15px;
    color: #555;
    line-height: 1.2;
    display: block;
    width: 100%;
    padding-top: 5px;
    font-family:Arial;

    resize: none;
    box-sizing: border-box;
    border-radius: 5px;
    border: ${props => props.hasborder ? "1px solid" : "none"};
    border-color: ${props => props.hasborder ? Colors.secundary : "none"};
    outline: none;
    //background-color: #f7f7f7;

    /* &:hover {
        border: 2px solid rgba(99,99,99,.5);
    }

    &:focus{
        outline: none;
        border: 2px solid #636363;
    } */

    /* ::placeholder,
    ::-webkit-input-placeholder {
        color: red;
        font-weight: 500;
    }
    :-ms-input-placeholder {
        color: red;
    } */

`