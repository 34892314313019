import Popup from 'reactjs-popup';
import styled from 'styled-components';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { IconButton } from '../Components/HelperComponents';
import { useEffect, useState } from 'react';
import { useUserState } from "../Context/UserContext"
import { useDataState } from "../Context/DataContext"
import moment from 'moment';
import Header from '../Components/Header';
import Button from '../Components/Button';
import Colors from '../Utilities/Colors';
import DataManager from '../Managers/DataManager';
import Aux from '../Managers/AuxManager';
import { async } from '@firebase/util';
import Loader from '../Components/Loader';
import Container from '../Components/Container';
export default function DailyRewardsModal({ active, onClose }) {

    const allDailyRewards = [
        { day: 1, reward: 1, state: false },
        { day: 2, reward: 1, state: false },
        { day: 3, reward: 1, state: false },
        { day: 4, reward: 2, state: false },
        { day: 5, reward: 2, state: false },
        { day: 6, reward: 2, state: false },
        { day: 7, reward: 5, state: false },
    ]

    const [currentTimestamp, setCurrentTimestamp] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingTime, setLoadingTime] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState("")
    const { currentUser } = useUserState()
    const { getCurrentTimestamp } = useDataState()

    useEffect(async () => {
        const time = await getCurrentTimestamp()
        if (time > 0) {
            setCurrentTimestamp(time)
            setLoadingTime(false)
        }
    }, [])

    const OnClaimReward = async (day, reward) => {
        if (loading || !currentUser.uid || !day || !reward) return;
        try {
            setLoading(true)
            const isLastDay = day === allDailyRewards.length
            await DataManager.claimDailyReward(currentUser.uid, day + 1, reward, isLastDay)
                .then(() => {
                    setIsSuccess(true)
                })
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setLoading(false)
            setError(Aux.UnexpectedError)
        }
    }

    const GetUpdatedState = (reward) => {

        if ((!currentUser.system?.lastDayReward || currentUser.system?.lastDayReward === 0) && reward.day === 1) {
            return "claim"
        } else if (currentUser.system?.lastDayReward === reward.day) {
            if (currentUser.system?.lastTimestamp) {
                const previousDate = moment(currentUser.system.lastTimestamp.toDate()).unix() + 24 * 60 * 60 //check if a day passed
                if (currentTimestamp > 0 && currentTimestamp > previousDate) { // TODOOOOOgetcurrentTimestamp > previousDate)
                    return "claim"
                } else {
                    return "unavailable";
                }
            }
        } else if (currentUser.system?.lastDayReward > reward.day) {
            return "rewarded"
        } else {
            return "unavailable"
        }
    }

    const DailyReward = ({ reward }) => {

        reward.state = GetUpdatedState(reward)

        return <SingleDailyContainer>
            <div className='flex'>
                <DayNumberWrapper state={reward.state}>
                    <p>{reward.day}</p>
                </DayNumberWrapper>
                <DayRewardWrapper>
                    <p>reward: {reward.reward}$</p>
                </DayRewardWrapper>
            </div>
            <div className='m-r-10'>
                <ChooseButton reward={reward} />
            </div>
        </SingleDailyContainer>
    }

    const ChooseButton = ({ reward }) => {
        switch (reward.state) {
            case "rewarded":
                return <Button text={"Rewarded"} size="medium" isDisabled={true}/>
            case "claim":
                return <Button text={"Claim"} size="medium" onClick={() => OnClaimReward(reward.day, reward.reward)} />
            default:
                return null //<Button text={"Disabled"} size="medium"/>
        }
    }

    return (
        <StyledPopup
            //trigger={<button className="button"> Open Modal </button>}
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close =>
                <MainContainer>
                    <Header text="Daily Reward" onClose={() => close()} />
                    {loadingTime ?
                        <Loader />
                        :
                        <AllDaysContainer>
                            {allDailyRewards.map((rwrd, index) => {
                                return <DailyReward reward={rwrd} key={index} />
                            })}

                        </AllDaysContainer>}
                </MainContainer>
            }
        </StyledPopup>);
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 300px;
        border-radius: 10px;
        background-color: white;
        box-sizing: border-box;
    }
`;

const MainContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
`

const AllDaysContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
`

const SingleDailyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    
`
const DayNumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.state === "claim" ? Colors.primary : props.state === "rewarded" ? Colors.success : "white"};
    
    color: ${props => props.state === "claim" || props.state === "rewarded" ? "white" : Colors.primary};
    border: 1px solid;
    border-color: ${props => props.state === "rewarded" ? Colors.success : Colors.primary}; 
    border-radius: 50%;
    width: 30px;
    height:30px;

    margin-left: 5px;
    margin-right: 10px;
    & p{

    }
`

const DayRewardWrapper = styled.div`

`




