import Avatar from "../Components/User/Avatar";
import styled from 'styled-components'
import UsernameText from "../Components/User/UsernameText";
import { useEffect, useState } from "react";
import Aux from "../Managers/AuxManager";
import DataManager from "../Managers/DataManager";
import Container from "../Components/Container";
import SimpleInput from "../Components/SimpleInput";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "mdi-react/SearchIcon"
import ShowList from "../Components/ShowList";
import Loader from "../Components/Loader";
import Button from "../Components/Button";

export default function ShowSearch(props) {


    const [searchType, setSearchType] = useState("Users")
    const [inputText, setInputText] = useState("")

    const [users, setUsers] = useState([])
    const [loadingUser, setLoadingUser] = useState(false)
    const [isFinishedUser, setIsFinishedUser] = useState(false)
    const [lastVisibleUser, setLastVisibleUser] = useState(false)

    const [hashtags, setHashtags] = useState([])
    const [loadingHashtag, setLoadingHashtag] = useState(false)
    const [isFinishedHashtag, setIsFinishedHashtag] = useState(false)
    const [lastVisibleHashtag, setLastVisibleHashtag] = useState(false)

    const { state, search } = useLocation();
    const { type, id } = useParams();

    var previousUser = "";
    var previousHashtag = "";
    useEffect(() => {
        const url = new URLSearchParams(search);
        const userUid = url.get("user")
        const hashtagUid = url.get("hashtag")

        if (userUid) {
            console.log("started searching user...")
            //setSearchType("Users")
            setInputText(userUid)
            searchUser(userUid)
            //onSearch("Users", userUid)
        } else if (hashtagUid) {
            console.log("started searching hashtag...")
            //setSearchType("Hashtag")
            setInputText(hashtagUid)
            //onSearch("Hashtags", hashtagUid)
            searchHashtag(hashtagUid)
        }

    }, [search])

    const searchUser = async (input) => {
        if (!input || loadingUser) return
        if (input === previousUser) return
        try {
            setUsers([])
            setSearchType("Users")
            setLoadingUser(true)
            previousUser = input;
            const userData = await DataManager.searchUser(input)
            if (userData && !userData?.empty) {
                const newData = userData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setUsers([...newData])
            }

            setLoadingUser(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false)
        }
    }

    const searchHashtag = async (input) => {
        if (!input || loadingHashtag) return
        if (input === previousHashtag) return
        try {
            setHashtags([])
            setSearchType("Hashtags")
            setLoadingHashtag(true)
            previousHashtag = input;
            const userData = await DataManager.searchHashtag(input)
            if (userData && !userData?.empty) {
                const newData = userData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setHashtags([...newData])
            }

            setLoadingHashtag(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingHashtag(false)
        }
    }

    
    const onSearch = async (type, id) => {

        /* if(type === "Users" && id !== previousUser){
            previousUser = id;
            console.log("searching user: " + id)
        }
        else if(type === "Hashtags" && id !== previousHashtag ){
            previousHashtag = id;
            console.log("searching hashtag: " + id)
        } */
        if (type) setSearchType(type)

        var searchingType = type || searchType
        var searchingId = id || inputText
        //console.log("previous User:" + searchingId)
        if (!searchingId) return
        if (searchingType === "Users" && searchingId === previousUser) return;
        if (searchingType === "Hashtags" && searchingId === previousHashtag) return;
        if (searchingType === "Users" && loadingUser) return;
        if (searchingType === "Hashtags" && loadingHashtag) return;
        /* setHashtags([])
        setUsers([]) */
        try {
            if (searchingType === "Users" && searchingId !== previousUser) {
                //setUsers([])
                previousUser = searchingId;
                console.log("searching user: " + searchingId)
                setLoadingUser(true)
                const userData = await DataManager.searchUser(searchingId)
                if (userData && !userData?.empty) {
                    const newData = userData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setUsers([...newData])
                }
                setLoadingUser(false)
            } else if (searchingType === "Hashtags" && searchingId !== previousHashtag)
                //setHashtags([])
                previousHashtag = searchingId;
            console.log("searching hashtag: " + searchingId)
            setLoadingHashtag(true)
            const hashtagData = await DataManager.searchHashtag(searchingId)
            if (hashtagData && !hashtagData?.empty) {
                const newData = hashtagData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                console.log(newData)
                setHashtags([...newData])
            }
            setLoadingHashtag(false)

        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false)
            setLoadingHashtag(false)

        }
    }

    const RenderUser = ({ item }) => {
        return <div>
            <p>username: {item.username}</p>
        </div>
    }

    const RenderHashtag = ({ item }) => {
        if (!item) return <p>No item</p>
        return <div>
            <p>###: {item.hashtag}</p>
        </div>
    }

    const Tab = ({ type }) => {
        const isCurrentTab = type === searchType
        return <StyledTab isCurrentTab={isCurrentTab} >
            <h3>{type}</h3>
        </StyledTab>
    }

    const onSearchClick = () => {
        if(searchType === "Users") searchUser(inputText); 
        else if(searchType === "Hashtags") searchHashtag(inputText) 
    }

    return (
        <Container>
            <HeaderContainer>
                <div className="flex w-100">
                    <SimpleInput type="text" value={inputText} onChange={setInputText} onKeyPress={e => {if(e.key === "Enter") onSearchClick()}}/>
                    <StyledSearchIcon onClick={onSearchClick} />
                </div>
                <TabsContainer>
                    <Button text="Users" size="medium" variant={searchType === "Users" ? "tabActive" : "tabInactive"}
                        className={"m-r-5"} onClick={() => { searchUser(inputText) /* onSearch("Users", inputText) *//* setSearchType("Users") */ }} />
                    <Button text="Hashtags" size="medium" variant={searchType === "Hashtags" ? "tabActive" : "tabInactive"}
                        className={"m-r-5"} onClick={() => { searchHashtag(inputText) /* onSearch("Hashtags", inputText) */ /* setSearchType("Hashtags") */ }} style={{ marginLeft: 30 }} />
                    {/* <Tab type="Users" />
                    <Tab type="Hashtag" /> */}
                </TabsContainer>
            </HeaderContainer>
            <DataContainer>
                {/* {users.map((post, index) => {
                        return <PostCard post={post} key={ index} />
                    })} */}
                {searchType === "Users" ?
                    <>
                        {loadingUser ?
                            <Loader />
                            :
                            <>
                                <ShowList
                                    loadMore={() => { }}
                                    hasMore={false}
                                    list={users}
                                    Render={RenderUser}
                                    emptyText={"No users found"}
                                    hide={searchType !== "Users"}
                                />
                            </>
                        }
                    </>
                    : searchType === "Hashtags" ?
                        <>
                            {loadingHashtag ?
                                <Loader />
                                :
                                <>
                                    {/* {hashtags.map((item, index) => {
                                    return <RenderHashtag item={item} key={index}/>
                                })}  */}
                                    <ShowList
                                        loadMore={() => { }}
                                        hasMore={false}
                                        list={hashtags}
                                        Render={RenderHashtag}
                                        emptyText={"No hashtags found"}
                                        hide={searchType !== "Users"}
                                    />
                                </>
                            }
                        </>
                        : null}
            </DataContainer>

        </Container>
    )
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
/*     border: 1px solid #ccc;
    max-width: 300px;
    height: 200px; */
`

const DataContainer = styled.div`
    display: flex;
    justify-content: center;
`

/* const StyledSearchIcon = styled(SearchIcon)`
    align-self: center;
` */

const StyledSearchIcon = styled(SearchIcon)`
    margin-left: 10px;
    cursor:pointer;
    padding:2px;
    &:hover{
        background-color: #ccc;
        border-radius: 50%;
    }
`
const StyledTab = styled.div`
  color:${props => props.isCurrentTab ? "black" : "grey"};
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
`
const TabsContainer = styled.div`
  display: flex;
  padding: 15px 35px;
`