import styled from "styled-components"
import Popup from 'reactjs-popup';
import Header from "../Components/Header";
import { useState } from "react";
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import YoutubeIcon from 'mdi-react/YoutubeIcon'
import SnapchatIcon from 'mdi-react/SnapchatIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import SimpleInput from "../Components/SimpleInput";
import Button from "../Components/Button";
import Aux from "../Managers/AuxManager";
import DataManager from "../Managers/DataManager";
import AlertMessage from "../Components/AlertMessage";

export default function CreateDummyModal({active, onClose}){

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [bio, setBio] = useState("")
    const [website, setWebsite] = useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [youtube, setYoutube] = useState("")
    const [twitter, setTwitter] = useState("")
    const [snapchat, setSnapchat] = useState("")
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [uploadedPhoto, setUploadedPhoto] = useState(null);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    
    const [isSuccess, setIsSuccess] = useState(false)
    const validate = () => {
        if (username ) {
            const errUsername = Aux.validateUsername(username)
            if (errUsername) return errUsername;
        }
        if (name) {
            const errName = Aux.validateName(name)
            if (errName) return errName;
        }
        if (email) {
            const errEmail = Aux.validateEmail(email)
            if (errEmail) return errEmail;
        }
        return null;
    }

    const onCreateDummy = async() => {

        if(loading || !email || !username) return;
        const errValidate = validate()
        if (errValidate) {
            Aux.handleError(errValidate)
            setError(errValidate)
            return;
        }

        try {
            let newUser = { social: {} }

            const freeUsername = await DataManager.checkIfUsernameExists(username); //check if username exists
            if (!freeUsername) {
                setError("Username already exists.")
                setLoading(false)
                return;
            }
            newUser.username = username.toLowerCase();
            
            if (email) { newUser.email = email;}
            if (name) { newUser.name = name;}
            if ( bio) { newUser.details.bio = bio; }
            if (website ) { newUser.social.website = website; }
            if (twitter ) { newUser.social.twitter = twitter;  }
            if (instagram ) { newUser.social.instagram = instagram;  }
            if (snapchat ) { newUser.social.snapchat = snapchat;  }
            if (youtube ) { newUser.social.youtube = youtube;  }
            if (facebook ) { newUser.social.facebook = facebook; }
            if (uploadedPhoto) {newUser.avatar = uploadedPhoto}  
            const newUid = DataManager.getNewUsersID();
            await DataManager.createDummyUser(newUid, newUser).then(() => {
                setIsSuccess(true);
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    

    return(
        <StyledPopup
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close =>
                <Container>
                    <Header text={"Create Dummy"} onClose={() => close()}/>
                    <AlertMessage text={error} variant="error"/>
                    <SimpleInput title={"Username"} placeholder={"Username"} value={username} onChange={(e) => { setUsername(e) }} />
                    <SimpleInput title={"Email"} placeholder={"Email"} value={email} onChange={(e) => { setEmail(e) }} />
                    <SimpleInput title={"Name"} placeholder={"Name"} value={name} onChange={(e) => { setName(e) }} />
                    <SimpleInput title={"Bio"} placeholder={"Bio"} value={bio} onChange={(e) => { setBio(e) }} />
                    <SimpleInput title={"Website"} placeholder={"Website"} value={website} onChange={(e) => { setWebsite(e) }} />
                    <SubHeaderText>Social Media</SubHeaderText>
                    <SimpleInput prefix={"Facebook"} prefixIcon={FacebookIcon} placeholder={"facebook"} value={facebook} onChange={(e) => setFacebook(e)} style={{ marginTop: 10 }} />
                    <SimpleInput prefix={"Instagram"} prefixIcon={InstagramIcon} placeholder={"instagram"} value={instagram} onChange={(e) => setInstagram(e)} style={{ marginTop: 10 }} />
                    <SimpleInput prefix={"Youtube"} prefixIcon={YoutubeIcon} placeholder={"youtube"} value={youtube} onChange={(e) => setYoutube(e)} style={{ marginTop: 10 }} />
                    <SimpleInput prefix={"Twitter"} prefixIcon={TwitterIcon} placeholder={"twitter"} value={twitter} onChange={(e) => setTwitter(e)} style={{ marginTop: 10 }} />
                    <SimpleInput prefix={"Snapchat"} prefixIcon={SnapchatIcon} placeholder={"snapchat"} value={snapchat} onChange={(e) => setSnapchat(e)} style={{ marginTop: 10 }} />
                    <Button text={"Create Dummy"} onClick={() => onCreateDummy()} style={{marginTop:10}}/>
                </Container>
            }
        </StyledPopup>
    );
    
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 300px;
        border-radius: 10px;
        background-color: white;
        height: 90%;
        max-height: 500px;
        overflow-y: scroll;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px 20px;
`

const SubHeaderText = styled.p`
    color:#999;
`