import styled from "styled-components"
import { Link } from "react-router-dom"
import Colors from "../Utilities/Colors"
import UsernameText from "../Components/User/UsernameText"
import { useUserState } from "../Context/UserContext"
import { SingleMenu } from "./LeftMenu"
import { useDataState } from "../Context/DataContext"
import { IconButton } from "../Components/HelperComponents"
import PlusIcon from "mdi-react/PlusIcon"
import Avatar from "../Components/User/Avatar"

export default function SideBar(props) {

    const { currentUser } = useUserState()
    const { currentMenu, hashtags, users } = useDataState()

    const ProfileMenu = () => {

        if (currentUser) {
            return <ProfileContainer>
                <UsernameText user={currentUser} />
                <div className="flex align-center">
                    <p className="m-r-5">{currentUser.amount}$</p>
                    <IconButton as={Link} to="/account/balance" backgroundcolor={Colors.primary} size={25} >
                        <PlusIcon color="white" />
                    </IconButton>
                </div>
                <SingleMenu to="/account/profile" text="Profile" isActive={currentMenu === "profile"} key={"side-profile"} />
                <SingleMenu to="/account/editprofile" text="Edit Profile" isActive={currentMenu === "editprofile"} key={"side-editprofile"} />
                <SingleMenu to="/account/achievements" text="Achievements" isActive={currentMenu === "achievements"} key={"side-achievements"} />
                <SingleMenu to="/account/invite-friends" text="Invite Friends" isActive={currentMenu === "invitefriends"} key={"side-inviteFriends"} />

                {/* <MenuItem to="/account/profile"><p>Profile</p></MenuItem>
                <MenuItem to="/account/editprofile"><p>Edit Profile</p></MenuItem>
                <MenuItem to="/account/achievements"><p>Achievements</p></MenuItem>
                <MenuItem to="/account/invite-friends"><p>Invite Friends</p></MenuItem> */}
            </ProfileContainer>
        } else return null;
    }

    const HashtagsMenu = ( ) => {
        if(hashtags && hashtags.lenght > 0){
            return <>
                {hashtags.map((hash, index) => {
                    return <SingleMenu key={hash.id} to={`/hashtag/${hash.hashtag}`} text={"#"+ hash.hashtag} 
                    isActive={currentMenu === hash.hashtag} />
                })}
            </>
        }
        return null
    }
    
    const UsersMenu = ( ) => {
        if(users && users.lenght > 0){
            return <>
            {users.map((user, index) => {
                //return <SingleMenu key={user.id} to={`/user/${user.id}`} text={user.username}/>
                return <SingleUser key={user.id} user={user} />
            })}
            </>
        }
        return null
    }

    const SingleUser = ({ user }) => {
        return (
            <SingleUserContainer>
                <Avatar user={user} height={35} />
                <div className="flex overflow-hidden align-center">
                    <UsernameText user={user} style={{marginLeft:10}} />
                    <StyledAmountText>{user.amountSpent}$</StyledAmountText>
                </div>
            </SingleUserContainer>
        )
    }

    return (
        <MenuContainer>
            <ProfileMenu />
            <HashtagsMenu/>
            <UsersMenu/>
        </MenuContainer>
    )

}

const MenuContainer = styled.div`

    background-color: white; 
    border-radius: 5px;
    width: 265px;
    overflow-x: hidden;

`

const MenuItemLink = styled(Link)`
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    height: 30px;
    border-bottom: 1px solid #ccc;
    text-decoration: none;
    &:hover{
        background-color: #f9f9f9;
    }
`

const ProfileContainer = styled.div`

`

const SingleUserContainer = styled.div`
    display: flex;
    align-items: center;
`

const StyledAmountText = styled.p`
    font-weight: bold;
    color: black;
    margin-left: 10px;
`