import Container from "../Components/Container";
import Header from "../Components/Header";
import styled from "styled-components"
import Colors from "../Utilities/Colors";
import { useUserState } from "../Context/UserContext";
import { useRef } from "react";
import CheckIfLogged from "../Checks/CheckIfLogged";
import EmptyContainer from "../Components/EmptyContainer";
import Button from "../Components/Button";
import InviteFriendsImage from "../Assets/InviteFriendsImage";

export default function InviteFriends(props) {

    const textArea = useRef(null)
    const { currentUser } = useUserState()

    const copyToClipboard = async () => {
        
        await navigator.clipboard.writeText("https://richless.app/invite/" + currentUser.details?.linkId)
        
    }

    return (
        <Container>
            <Header text="Invite Friends" />
            {currentUser.uid ?
                currentUser.details?.linkId ?
                    <InviteContainer>
                        {/* <StyledInput ref={textArea} type="text"
                            defaultValue={"https://richless.app/invite/" + currentUser.details?.linkId} name="text" /> */}
                            <InviteFriendsImage style={{width:230, marginTop:10, marginBottom:20}}/>
                        <StyledHeader>Invite your friends and both will earn <StyledMoney>10$</StyledMoney>!</StyledHeader>
                        <InputUrlArea
                            type="text"
                            value={"https://richless.app/invite/" + currentUser.details?.linkId }
                        />
                        
                        <Button text={"Copy"} variant={"secundary"} onClick={copyToClipboard} size="medium"/>
                        <StyledDetails>Copy the link above and send to your friends to earn money!</StyledDetails>
                    </InviteContainer>
                    : <EmptyContainer text="You don't have a link yet."/>
                : <CheckIfLogged />}
        </Container>
    )
}

const StyledInput = styled.input`
    width: 300px;
    text-align: center;
    outline: none;
`

const StyledHeader = styled.h3`
    width: 90%;
`

const StyledDetails = styled.p`
    width: 60%;
    color: ${Colors.secundary};
`

const StyledMoney = styled.span`
    color: ${Colors.success};
`

const InputUrlArea = styled.input`
    padding: 10px;
    margin:  10px 0;
    border-radius:10px;
    border: 2px solid #eee;
    margin-right: 10px; 
    outline: none;
    width: 70%;
    text-align: center;
    &:focus{
        border: 2px solid #ccc
    }
`

const InviteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;
    text-align: center;

    & h3{
        margin: 10px 0;
    }

    & span{
        color:${Colors.success}
    }

`