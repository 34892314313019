const Colors = {
    primary:'#0091EA',
    primaryLighter:'#40b6ff',
    primaryLightest:'#bfe7ff',
    primaryDarker:'#0077bf',
    primaryDarkest:'#002840',


    secundary:"#ccc",
    secundaryLighter:'#dfdfdf',
    secundaryLightest:'#eaeaea',
    secundaryDarker:'#606060',
    secundaryDarkest:'#202020',

    success:'#2DCC70',
    successLighter:'#cbf3dc',
    successDarker:'#239d56',

    error:'#E74C3C',
    errorLighter:'#f8cbc6',
    errorDarker:'#aa2315',

    white:'#ffffff',
    black:'#000000',

    register:"#EE4D4D",

    textNormal:'#757575',
    textLighter:'#9f9f9f',
    textLighest:'#dfdfdf',
    textDarker:'#606060',
    textDarkest:'#202020',

    facebook: "#4267B2",
    google: "#DB4437",
    twitter: "#1DA1F2",
    instagram: "#C82D8D",
    youtube: "#FF0000",
    snapchat: "#FFC315",
    link: "#0645AD",


}

export default Colors