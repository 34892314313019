import React from "react";


export default function PayPalMinimal({style}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 400.45 106.72"
      style={style}
    >
      <path
        fill="#113984"
        fillRule="evenodd"
        d="M56.44 5.57c13.07 0 25.13 7.09 23.48 24.76-2 21-13.25 32.62-31 32.67H33.41c-2.23 0-3.31 1.82-3.89 5.55l-3 19.07c-.45 2.88-1.93 4.3-4.11 4.3H8c-2.3 0-3.1-1.47-2.59-4.76L17.3 10.73c.59-3.76 2-5.16 4.57-5.16h34.57zm-23.5 40.92h11.75c7.35-.28 12.23-5.37 12.72-14.55.3-5.67-3.53-9.73-9.62-9.7l-11.06.05-3.79 24.2zm86.21 39.58c1.32-1.2 2.66-1.82 2.47-.34l-.47 3.54c-.24 1.85.49 2.83 2.21 2.83h12.82c2.16 0 3.21-.87 3.74-4.21l7.9-49.58c.4-2.49-.21-3.71-2.1-3.71h-14.1c-1.27 0-1.89.71-2.22 2.65l-.52 3c-.27 1.59-1 1.87-1.68.27-2.39-5.66-8.49-8.2-17-8C90.43 33 77.1 48 75.67 67.23c-1.1 14.88 9.56 26.57 23.62 26.57 10.2 0 14.76-3 19.9-7.7zm-10.74-7.63c-8.51 0-14.41-6.79-13.21-15.11s9.19-15.11 17.7-15.11 14.44 6.78 13.21 15.11-9.18 15.11-17.7 15.11zm64.5-44h-13c-2.68 0-3.77 2-2.92 4.46l16.14 47.26-15.83 22.49c-1.33 1.88-.3 3.59 1.57 3.59h14.61a4.47 4.47 0 004.34-2.13l49.64-71.2c1.53-2.19.81-4.49-1.7-4.49h-13.83c-2.37 0-3.32.94-4.68 2.91l-20.7 30-9.25-30.07c-.54-1.82-1.89-2.82-4.38-2.82z"
        transform="translate(-5.24 -5.55)"
      ></path>
      <path
        fill="#009ee3"
        fillRule="evenodd"
        d="M279.1 5.57c13.07 0 25.13 7.09 23.48 24.76-2 21-13.25 32.62-31 32.67h-15.5c-2.23 0-3.31 1.82-3.89 5.55l-3 19.07c-.45 2.88-1.93 4.3-4.11 4.3h-14.43c-2.3 0-3.1-1.47-2.59-4.76L240 10.71c.59-3.76 2-5.16 4.57-5.16h34.53zm-23.5 40.92h11.75c7.35-.28 12.23-5.37 12.72-14.55.3-5.67-3.53-9.73-9.62-9.7l-11.06.05-3.79 24.2zm86.21 39.58c1.32-1.2 2.66-1.82 2.47-.34l-.47 3.54c-.24 1.85.49 2.83 2.21 2.83h12.82c2.16 0 3.21-.87 3.74-4.21l7.9-49.58c.4-2.49-.21-3.71-2.1-3.71H354.3c-1.27 0-1.89.71-2.22 2.65l-.52 3c-.27 1.59-1 1.87-1.68.27-2.39-5.66-8.49-8.2-17-8-19.77.48-33.1 15.48-34.53 34.71-1.1 14.88 9.56 26.57 23.65 26.57 10.2 0 14.76-3 19.9-7.7zm-10.73-7.63c-8.51 0-14.44-6.79-13.21-15.11s9.19-15.11 17.7-15.11S350 55 348.78 63.33s-9.19 15.11-17.7 15.11zm59.13 13.74h-14.8a1.75 1.75 0 01-1.83-1.67 1.83 1.83 0 010-.33l13-82.36a2.54 2.54 0 012.46-2h14.8a1.75 1.75 0 011.83 1.67 1.83 1.83 0 010 .33l-13 82.36a2.55 2.55 0 01-2.46 2z"
        transform="translate(-5.24 -5.55)"
      ></path>
    </svg>
  );
}
