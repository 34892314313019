import styled from "styled-components"
import Colors from "../Utilities/Colors"


export default function CheckBox({ text, active, setActive, className, label }) {

    return (
        <div className={className}>

            <StyledContainer >
                <StyledInput type="checkbox" name="explicit" value="explicit" checked={active}
                    onChange={() => setActive(!active)} />
                <p onClick={() => setActive(!active)}>{text}</p>
                <StyledLabel>{label}</StyledLabel>
            </StyledContainer>

        </div>
    )
}

const StyledInput = styled.input`
    width: 20px;
    height:20px;
    margin-right: 10px;
    cursor: pointer;
`

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const StyledLabel = styled.div`
    color: ${Colors.secundaryDarker};
    font-size: 0.8rem;
    margin-left: 5px;
    cursor: auto;
`