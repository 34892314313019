import { useRef, useState } from "react";
import NumberFormat from "react-number-format";
import Avatar from "../Components/User/Avatar";
import Button from "../Components/Button";
import Container from "../Components/Container";
import Header from "../Components/Header";
import Separator from "../Components/Separator";
import SimpleInput from "../Components/SimpleInput";
import PostsManager from "../Managers/PostsManager";
import MinusIcon from 'mdi-react/MinusIcon'
import PlusIcon from "mdi-react/PlusIcon"
import ImagePlusIcon from "mdi-react/ImagePlusIcon"
import styled from 'styled-components'
import Aux from "../Managers/AuxManager";
import { useUserState } from "../Context/UserContext";
import { useDataState } from "../Context/DataContext";
import AlertMessage from "../Components/AlertMessage";
import ArrowLeft from "mdi-react/ChevronLeftIcon"
import UsernameText from "../Components/User/UsernameText";
import Colors from "../Utilities/Colors";
import Slider from "../Components/Slider";
import InputTextResize from "../Components/InputTextResize";
import YoutubeEmbed from "../Components/Embed/YoutubeEmbed";
import AddAmount from "../Utilities/AddAmount";
import DataManager from "../Managers/DataManager";
import CheckBox from "../Components/CheckBox";

export default function CreatePost() {
    const inputRef = useRef(null);

    const [amount, setAmount] = useState(10);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const [youtubeId, setYoutubeId] = useState("")
    const [tiktokId, setTikTokId] = useState("")
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [text, setText] = useState("");
    const [textCount, setTextCount] = useState(0);
    const [hashtag, setHashtag] = useState("");
    const [success, setSuccess] = useState(false)
    const [isNsfw, setNsfw] = useState(false)
    const [isHidden, setHidden] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)
    const { currentUser } = useUserState();
    const { getCurrentTimestamp } = useDataState();

    const handlePhotoClick = () => {
        if (inputRef) inputRef.current.click();
    }

    const handleChoosePhoto = (event) => {
        const uploadedFile = event?.target.files[0];
        let photo = URL.createObjectURL(event.target.files[0])
        if (!uploadedFile) return;
        setSelectedPhoto(photo)
        setUploadedPhoto(uploadedFile)
        setYoutubeId("")
    };

    const validate = () => {
        if (text.trim() === "" && !uploadedPhoto) {
            return "Post can't be empty"
        }
        if (!amount || amount < 5)
            return "Minimum amount is 5$"
        if (!currentUser?.username) {
            return "You're not logged in"
        }
        return ""
    }

    const validateYoutubeUrl = (url) => {
        if (url != undefined || url != '') {
            //var regExp = new RegExp("/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/");
            var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            //var p =  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            var match = url.match(p);
            return (match)
            // Do anything for being valid
            // if need to change the url to embed url then use below line
            //$('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');

        }
        return false
    }

    const validateTikTokUrl = (url) => {
        if (url != undefined || url != '') {
            //var p = new RegExp(`(?x)https?://(?:(?:www|m)\.(?:tiktok.com)\/(?:v|embed|trending|.*?/video)(?:\/)?(?:\?shareId=)?)(?P<id>[\da-z]+)`)
            var p = /(?:https?:\/\/)?(?:(?:www|m)\.(?:tiktok.com)\/(?:v|embed|trending|.*?\/video)(?:\/)?(?:\?shareId=)?)([\da-z]+)/
            var match = url.match(p);
            return (match)
        }

        return false
    }
    //https://www.youtube.com/watch?v=Y8PjRXMbT6c

    const checkIfUrl = (url) => {
        var urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        const output = url.match(pattern)
        return output

    }

    const handleUpload = async (e) => {
        e.preventDefault();
        if (loading || !currentUser.uid) return;
        const error = validate()
        if (error) {
            setError(error)
            console.error(error)
            return;
        }

        try {
            setLoading(true)

            const postId = PostsManager.getNewPostsID();
            let url = "";
            const currentTimestamp = await getCurrentTimestamp()
            const daysSince = DataManager.getDaysSince(currentTimestamp);
            if (daysSince <= 0) {
                setLoading(false)
                setError(Aux.UnexpectedError)
                return;
            }
            var newPost = {
                amount: amount,
                startAmount: amount,
                text: text || "",
                image: url,
                hashtag: hashtag.toLowerCase() || "",
                ranking: 0,
                timeSince: daysSince,
                hide: isHidden || false,
                nsfw: isNsfw || false,
                uid: currentUser.uid,
                avatar: currentUser.avatar || "",
                username: currentUser.username || "",
            }
            if (uploadedPhoto) {
                url = await PostsManager.uploadPhotoCompressed(postId, uploadedPhoto);
            } else if (youtubeId) {
                newPost = { ...newPost, youtubeId: youtubeId }
            } else if (tiktokId) {
                newPost = { ...newPost, tiktokId: tiktokId }
            }


            console.log(newPost)
            await PostsManager.createPost(postId, newPost).then(() => {
                setSuccess(true);

            })
            setLoading(false);
        } catch (error) {
            Aux.handleError(error);
            setLoading(false);
            setError(Aux.UnexpectedError)
        }
    }


    const onTextChange = (text) => {
        text.split(" ").map((str) => {
            const match = validateYoutubeUrl(str)
            if (match && match[1]) {
                setYoutubeId(match[1])
                setSelectedPhoto(null)
                setUploadedPhoto(null)
                setTikTokId(null)
            } else {
                const matchTiktok = validateTikTokUrl(str)
                if (matchTiktok && matchTiktok[1]) {
                    console.log(matchTiktok)
                    setYoutubeId(null)
                    setSelectedPhoto(null)
                    setUploadedPhoto(null)
                    setTikTokId(matchTiktok[1])
                }
            }
        })
        setTextCount(text.length);
        setText(text)
    }

    return (
        <Container>
            <Header text={currentTab === 0 ? "Create Post" : "Select Amount"} style={{marginBottom:10}} user={currentUser}/>

            {currentTab === 0 ?
                <InputContainer>
                    <AlertMessage variant="error" text={error} />
                    {/* <div className="flex">
                        <Avatar user={currentUser} useLink={true} height={40} />
                        <InputTextResize key="create-input-resize" placeholder={"What's on your mind?"} value={text} onChange={onTextChange} minRows={7} />
                    </div> */}
                    <InputTextResize placeholder={"What's on your mind?"} value={text} onChange={onTextChange} minRows={5} maxRows={20}/>
                    {selectedPhoto ?
                        <>
                            <StyledUploadedPhoto src={selectedPhoto} />
                        </>
                        : youtubeId ? <>
                            <YoutubeEmbed embedId={youtubeId} />
                        </>
                            : null}
                    <input type="file" accept=".png, .jpg, .jpeg" hidden ref={inputRef} onChange={handleChoosePhoto} />

                    <div className="flex-between m-t-5">
                        {(!selectedPhoto && !youtubeId) ?
                            <Button variant="outline" color={Colors.primary} darkerColor={Colors.primaryDarker} size="medium" icon={ImagePlusIcon}
                                text="Choose Photo" onClick={handlePhotoClick} />
                            : <div className="flex-between m-t-10">
                                <Button onClick={handlePhotoClick} text={youtubeId || tiktokId? "Choose photo" : "Change photo"} size="small"/>
                                <StyledPhotoText color={Colors.error} onClick={() => { setSelectedPhoto(""); setUploadedPhoto(""); setYoutubeId("") }}>{youtubeId || tiktokId? "Delete preview" : "Delete photo"}</StyledPhotoText>
                            </div>}
                        <StyledTextCount>{textCount}/500</StyledTextCount>
                    </div>
                    <Separator />
                    <AdditionalContainer>
                        <SubHeader>Additional Information</SubHeader>
                        <SimpleInput isHashtag={true} placeholder="Hashtag" value={hashtag} onChange={(text) => setHashtag(text)} prefix="#" maxLenght={50}/>
                        <div className="flex flex-wrap align-center m-t-10">
                        <CheckBox active={isNsfw} setActive={setNsfw} text="NSFW" className={"m-r-10"} label={"Contains nudity?"} />
                        <CheckBox active={isHidden} setActive={setHidden} text="Anonymous" label={"Hide profile?"} />
                    </div>
                    </AdditionalContainer>
                    <div className="flex-end m-t-10">
                        <Button text="Next" onClick={() => setCurrentTab(1)} style={{width:"50%"}}/>
                    </div>
                </InputContainer>

                :
                <AmountContainer>
                    <AddAmount amount={amount} setAmount={setAmount} isPost/>
                    <div className="flex m-t-10">
                        <Button icon={ArrowLeft} onClick={() => setCurrentTab(0)} variant={"outline"} />
                        <Button text={"Post"} onClick={handleUpload} className="w-100 m-l-10" />
                    </div>
                </AmountContainer>
            }
        </Container>
    )
}

const StyledPhotoText = styled.p`
    color: ${props => props.color || "black"};
    cursor: pointer;
    margin: 4px 0;
    margin-left: 10px;
    &:hover{
        opacity: 0.7;
    }
`

const StyledUploadedPhoto = styled.img`
    width: 100%;
    object-fit: cover;
`

const SubHeader = styled.p`
    color: ${Colors.secundaryDarkest};
    font-size: 1rem;
    margin-bottom: 15px;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const StyledTextCount = styled.p`
    color:${Colors.secundary};
    font-size: 0.9rem;
`
const AdditionalContainer = styled.div`

`

const AmountContainer = styled.div`

`