import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore"
import { db } from '../firebase'
import { useEffect, useState, createRef, useRef } from "react"
import styled from "styled-components"
import PostsManager from "../Managers/PostsManager"
import Aux from "../Managers/AuxManager"
import { useUserState } from "../Context/UserContext"
import { useLocation, useParams } from "react-router-dom"
import MessagesManager from "../Managers/MessagesManager"
import Container from "../Components/Container"
import Header from "../Components/Header"
import Loader from "../Components/Loader"
import Avatar from "../Components/User/Avatar"
import InputTextResize from "../Components/InputTextResize"
import SendIcon from "mdi-react/SendIcon"
import RightArrow from "mdi-react/ArrowRightCircleIcon"
import LeftArrow from "mdi-react/ArrowLeftCircleIcon"
import TextareaAutosize from "react-autosize-textarea/lib"
import { SimpleOnHover } from "../Components/HelperComponents"
import ShowList from "../Components/ShowList"
import DataManager from "../Managers/DataManager"
import reactInfiniteScroller from "react-infinite-scroller"
import CheckIfLogged from "../Checks/CheckIfLogged"
import AlertMessage from "../Components/AlertMessage"
import EmptyContainer from "../Components/EmptyContainer"
import Colors from "../Utilities/Colors"

export default function ViewChat(props) {

    const [chatId, setChatId] = useState("")
    const [chat, setChat] = useState({})
    const [targetUser, setTargetUser] = useState({})
    const [text, setText] = useState("")
    const [messages, setMessages] = useState([])
    const [noChatFound, setNoChatFound] = useState(false)
    const [lastVisible, setLastVisible] = useState(null)
    const [isFinished, setIsFinished] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const { currentUser, setCurrentViewingMessage } = useUserState()
    const { state } = useLocation();
    const { id } = useParams();
    const chatContainer = useRef(null);
    var messagesListener = null;

    useEffect( () => {
        
        
        var chatUser = ""
        const getMessageInfo = async (id) => {
            setChatId(id)
            const chatInfo = await MessagesManager.getMessageInfo(id)
            if (chatInfo) {
                setChat(chatInfo.data())
                //createScrollListener()
                const otherUser = chatInfo.data().users?.find(user => user !== currentUser.uid)
                chatUser = otherUser;
                canGetUser(chatUser)
            } else {
                console.log("no chat found")
                setNoChatFound(true);
            }
        }

        const getUserInfo = async(id) => {
            const dataUser = await DataManager.getUserInfo(id)
            if (dataUser) {
                setTargetUser(dataUser.data())
            } else {
                console.log("no user found")
                setNoChatFound(true);
            }
        }

        const canGetUser = (id) => {
            if (state?.user) {
                setTargetUser(state.user)
            } else if (id) {
                getUserInfo(id)
            } else {
                console.log("no user found")
                setNoChatFound(true);
            }
        }

        
        if (state?.message) {
            setChat(state.message)
            const otherUser = state.message.users?.find(user => user !== currentUser.uid)
            chatUser = otherUser;
            canGetUser(chatUser)
        } else {
            if (id) {
                getMessageInfo(id)
            } else {
                console.log("invalid id")
                setNoChatFound(true);
            }
        }
        

    }, [])

    var alreadyStarted = false;
    useEffect(() => {
        if (chatId) {
            const chatRef = collection(db, "chats", chatId, "messages")
            const q = query(chatRef, orderBy("timestamp", "desc"), limit(10))
            messagesListener = onSnapshot(q, { includeMetadataChanges: false }, snap => {
                if (!snap.empty) {
                    let myMessages = messages;
                    snap.docs.forEach(doc => {
                        if (doc.data().timestamp) {
                            const newMessage = { ...doc.data(), id: doc.id };
                            const exists = myMessages.some(x => x.id === newMessage.id)
                            if (!exists) {
                                if (alreadyStarted)
                                    myMessages.push(newMessage);
                                else
                                    myMessages.unshift(newMessage);
                                setMessages(myMessages)
                            }
                        }
                    })
                    if (!alreadyStarted) {
                        alreadyStarted = true;
                        setLastVisible(snap.docs[snap.docs.length - 1])
                        scrollToBottom()
                        setCurrentViewingMessage(chatId)
                    }

                    /* if(!lastVisible){ // to know where to start to get more
                        setLastVisible(snap.docs[snap.docs.length - 1])
                    } */
                }

            })
        }
        return () => { if (messagesListener) messagesListener(); setCurrentViewingMessage(null) }
    }, [chatId])

    useEffect(() => {
        document.getElementById("scrollableChat")?.addEventListener('scroll', handleScrollEvent)
        return () => document.getElementById("scrollableChat")?.removeEventListener('scroll', handleScrollEvent)
    }, [loading, chatId, lastVisible, isFinished])

    const handleScrollEvent = (event) => {
        if (event.target.scrollTop === 0) {
            if (!isFinished && !loading) {
                getMoreChatMessages()
            }
        }
    }

    const getMoreChatMessages = async () => {
        if (loading || !chatId || !lastVisible) return;

        var currentScrollPos = 0
        if (chatContainer.current)
            currentScrollPos = chatContainer.current.scrollHeight - chatContainer.current.scrollTop

        try {
            console.log("is getting more messages")
            setLoading(true)
            console.log(lastVisible?.data())
            const data = await MessagesManager.getMessagesFromChat(chatId, lastVisible)
            if (!data?.empty) {
                let newMessages = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                if (newMessages.length < Aux.LIMIT_MESSAGES) {
                    setIsFinished(true);
                }
                newMessages = newMessages.reverse()
                setMessages([...newMessages, ...messages,])

                setLastVisible(data.docs[data.docs.length - 1])
                if (chatContainer.current)
                    chatContainer.current.scrollTop = chatContainer.current.scrollHeight - currentScrollPos;
            }
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setLoading(false)
        }
    }

    const scrollToBottom = () => {
        if (chatContainer.current) {
            console.log("entrou")
            const scroll =
                chatContainer.current.scrollHeight -
                chatContainer.current.clientHeight;
            chatContainer.current.scrollTo(0, scroll);

        }
    };

    const sendChatMessage = async () => {
        if (!chatId || !text) return;
        try {
            await MessagesManager.sendMessageToChat(chatId, text, currentUser.uid).then(() => {
                console.log("chat sent")
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const sendOtherChatMessage = async () => {
        if (!chat || !chatId || !text) return;
        try {
            const otherUser = chat.users?.find(user => user !== currentUser.uid)
            await MessagesManager.sendMessageToChat(chatId, text, otherUser).then(() => {
                console.log("chat sent")
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const getMessageInfo = async () => {
        try {
            const data = await MessagesManager.getMessageInfo(chatId)
            if (data.exists) {
                setChat({ ...data.data(), id: data.id })
            }
        } catch (error) {
            Aux.handleError(error)
        }
    }


    const updateReadChatMessage = async (message) => {
        try {
            await MessagesManager.updateReadMessageStatus(message, true, currentUser.uid)
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const RenderChatMessage = ({ item }) => {

        const isSender = item.from === currentUser.uid ? true : false

        if (item.from === "system")
            return (<StyledRenderSystemText>
                {item.text}
            </StyledRenderSystemText>)

        return <StyledRenderMessage isSender={isSender}>
            <StyledRenderMessageText isSender={isSender}>
                {item.text}
            </StyledRenderMessageText>
        </StyledRenderMessage>
    }

    return (
        <>
            <Container>
                <Header text="Message" user={targetUser}/>
                {currentUser.uid ?
                    <>
                        <ChatContainer ref={chatContainer} id="scrollableChat">
                            {
                                noChatFound ? <EmptyContainer text={"No chat found."} /> :
                                    messages.length <= 0 ? <EmptyContainer text={"No messages yet."} /> :
                                        messages.map((item, index) => (
                                            <RenderChatMessage item={item} key={index} />
                                        ))
                            }

                        </ChatContainer>
                        <TypingContainer>
                            <Avatar user={currentUser} height={25} />
                            <div className="flex w-100">
                                <StyledTextareaAutosize
                                    value={text}
                                    placeholder={"write your message..."}
                                    onChange={(e) => setText(e.target.value)}
                                    maxRows={4}
                                    rows={1} />
                                <SimpleOnHover><LeftArrow onClick={sendOtherChatMessage} /></SimpleOnHover>
                                <SimpleOnHover><RightArrow onClick={sendChatMessage} /></SimpleOnHover>
                            </div>
                        </TypingContainer>
                    </>
                    :
                    <CheckIfLogged />}
            </Container>
        </>
    )
}

const StyledRenderMessage = styled.div`
    display: flex;
    padding: 2px 10px;
    margin: 3px 10px;
    max-width: 70%;
    border-radius: 5px;
    align-self: ${props => props.isSender ? "end" : "start"};
    background-color: ${props => props.isSender ? Colors.primary : Colors.secundaryLighter};
`

const StyledRenderMessageText = styled.p`
    //color: #111;
    color: ${props => props.isSender ? "white" : Colors.textDarkest};
`
const StyledRenderSystemText = styled.p`
    align-self: center;
    color: ${Colors.textDarker};
`
const ChatContainer = styled.div`
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
`

const TypingContainer = styled.div`
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
    font-size: 15px;
    color: #555;
    line-height: 1.2;
    display: block;
    width: 100%;
    //height: 32px;
    padding: 0 10px;
    //border-radius: 3px;
    //border: 2px solid #ddd;
    resize: none;
    box-sizing: border-box;
    border: none;
    outline: none;
    //background-color: #f7f7f7;

    /* &:hover {
        border: 2px solid rgba(99,99,99,.5);
    }

    &:focus{
        outline: none;
        border: 2px solid #636363;
    } */
`