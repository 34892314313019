import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom"
import Container from "../Components/Container";
import { useUserState } from "../Context/UserContext";
import PostsManager from "../Managers/PostsManager";
import Aux from "../Managers/AuxManager";
import styled from "styled-components"
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "../Components/Loader";
import CommentCard from "./CommentCard";
import SimpleInput from "../Components/SimpleInput";
import Button from "../Components/Button";
import LikeOutlineIcon from "mdi-react/HeartOutlineIcon"
import LikeFilledIcon from "mdi-react/HeartIcon"
import Avatar from "../Components/User/Avatar";
import UsernameText from "../Components/User/UsernameText";
import PostImage from "./PostImage";
import YoutubeEmbed from "../Components/Embed/YoutubeEmbed";
import { RenderLikeButton, RenderText } from "./PostCard";
import ShowList from "../Components/ShowList";
import AddAmount from "../Utilities/AddAmount";
import InputTextResize from "../Components/InputTextResize";
import EmptyContainer from "../Components/EmptyContainer";

export default function ViewPost(props) {

    const [post, setPost] = useState(null)
    const [liked, setLiked] = useState(false)
    const [comments, setComments] = useState([])
    const [loadingPost, setLoadingPost] = useState(false)
    const [loadingCreateComment, setLoadingCreateComment] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [commentError, setCommentError] = useState("")
    const [commentText, setCommentText] = useState("")
    const [commentAmount, setCommentAmount] = useState(3)
    const { state } = useLocation();
    const { id } = useParams();
    const { currentUser } = useUserState()
    const [triggerLikeIcon, setTriggerLikeIcon] = useState(false);

    const [openCommentSection, setOpenCommentSection] = useState(false);
    useEffect(() => {
        if (state?.post) {
            setPost(state.post)
            console.log("state")
            console.log(state.isLiked)
            if(state.isLiked){

                setLiked(true)
            }
        } else if (id) {
            getPost(id)
            checkIfLiked(id)
        }
    }, [])

    const getPost = async (id) => {
        if (loadingPost) return
        try {
            setLoadingPost(true)
            const thisPost = await PostsManager.getPost(id);
            if (thisPost) {
                console.log(thisPost.data())
                const newPost = { ...thisPost.data(), id: thisPost.id }
                setPost(newPost)
            }
            setLoadingPost(false);
        } catch (error) {
            Aux.handleError(error)
            setLoadingPost(false)
        }
    }

    const checkIfLiked = async (id) => {
        if (!currentUser.uid || !id) return;
        try {
            await PostsManager.checkIfUserLikedPost(id, currentUser.uid).then((data) => {
                if (data.exists()) {
                    setLiked(true);
                }
            })
        } catch (error) {
            Aux.handleError(error);
        }
    }

    const validate = () => {
        if (commentText.length > 300) return "Comment is too long. max 300 characters"
    }

    const createComment = async () => {
        if (loadingCreateComment) return;

        const error = validate()
        if (error) {
            setCommentError(error)
            return;
        }

        try {
            setLoadingCreateComment(true)
            const comment = {
                uid: currentUser.uid,
                timestamp: Date.now(),
                text: commentText,
                amount: commentAmount,
                postid: post.id,
            }
            //setComments([...comments, comment])
            await PostsManager.createComment(post, comment, currentUser.uid)
                .then(() => {
                    setComments([...comments, comment])
                })
            setLoadingCreateComment(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingCreateComment(false)
        }

    }
    const getTime = (timestamp) => {
        try {
            var date = new Date(timestamp?.seconds * 1000);
            return moment(date).fromNow();
            //this.setState({currentTime: time}) 

        } catch (error) {
            Aux.handleError(error)
        }
    }

    return (
        <Container minimal noBackground>
            {loadingPost ?
                <EmptyContainer isLoading/>
                : !post ?
                    <EmptyContainer text={"No post found."}/>
                    :
                    <>
                        <PostContainer>
                            <PostHeader>
                                <div className="flex-start align-center">
                                    <Avatar user={{ avatar: post.avatar, username: post.username }} uselink />
                                    <UsernameText style={{ marginLeft: 10 }} user={{ username: post.username }} />
                                </div>
                                <h3>{post.amount}$</h3>
                            </PostHeader>


                            <PostBody>
                                <RenderText post={post}/>
                                {/* <span>{post.text}</span> */}
                                {post.image ? <PostImage {...post} />
                                    : post.youtubeId ? <YoutubeEmbed embedId={post.youtubeId} />
                                        : null}
                            </PostBody>


                            <PostFooter>
                               
                                    <RenderLikeButton checkIfLiked={state?.isLiked || true} post={post} isLiked={state?.isLiked || false}/>
                                    <p>{post.likeCount || 0}</p>
                                    <p>Comments {""} {post.commentCount || 0}</p>

                            </PostFooter>
                        </PostContainer>
                        {!currentUser.uid ? 
                            null
                        : openCommentSection ?
                            <ClosedCommentContainer onClick={() => setOpenCommentSection(true)}>
                                <Avatar user={currentUser}/>
                                <p>click to comment</p>
                            </ClosedCommentContainer>
                            
                        :
                        <CreateCommentContainer>
                            <AddAmount amount={commentAmount} setAmount={setCommentAmount} isComment={true}/>
                            <InputTextResize  style={{ width: "100%", margin:"10px 0" }} placeholder={"Insert text here"} 
                            value={commentText} onChange={(text) => setCommentText(text)} isBorder={true}/>
                            <div className="flex-between ">
                                <Avatar user={currentUser} height={35}/>
                                <Button text="Comment" onClick={createComment} size="medium"></Button>
                            </div>
                            
                        </CreateCommentContainer>}

                        <CommentsContainer>
                            <ShowList 
                                Render={CommentCard}
                                type="postComments"
                                useWindow={true}
                                uid={id || post.id}
                                addItem={comments}
                                //endText={"There's no more comments"}
                            />
                        </CommentsContainer>
                    </>}
        </Container>
    )
}

const PostContainer = styled.div`

`

const PostHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`
const PostBody = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
`

const PostFooter = styled.div`
    display: flex;
    align-items: center;
    & p{
        margin-left: 10px;
    }
`

const CreateCommentContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const CommentsContainer = styled.div`
    display: flex;
    
`

const ClosedCommentContainer = styled.div`

`
const StyledInfiniteScroll = styled(InfiniteScroll)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`