import { useState } from "react"
import styled from "styled-components"
import Colors from "../Utilities/Colors"


export default function PostImage(props){

    const [loaded, setLoaded] = useState(false)
    return (
        <div className="flex-center">
            {props.hide && 
                <StyledHide>
                    <p>Explicit content. <span>{" "} Clich here to view</span></p>
                </StyledHide>}
            <StyledImage
                hide={props.hide}
                src={props.image}
                onLoad={() => setLoaded(true)}
                loaded={loaded}
                alt="Post Image"
            />
            {!loaded && !props.hide && <StyledHide/>}
        </div>
    )
}

const StyledImage = styled.img`
    display: ${props => !props.hide && props.loaded ? "block" : "none"};
    object-fit: contain;
    width: 100%;
    max-height: 500px;
`

const StyledHide = styled.div`
    width: 100%;
    height: 350px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;

    & p{

    }
    & span{
        color:${Colors.error}
    }

    &span:hover{
        color:${Colors.errorDarker}
    }
`