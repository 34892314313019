import styled from "styled-components"
import { useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import Aux from "../Managers/AuxManager";
import axios from "axios"
import DataManager from "../Managers/DataManager";
import { useUserState } from "../Context/UserContext";
import Container from "../Components/Container";
import AlertMessage from "../Components/AlertMessage";
import Loader from "../Components/Loader";
import Button from "../Components/Button";
import Header from "../Components/Header";

export default function Invitation(props) {


    const [loadingAddCode, setLoadingAddCode] = useState(false)
    const [promoCode, setPromoCode] = useState("")
    const [isSuccessPromo, setIsSuccessPromo] = useState(false)

    const [referUser, setReferUser] = useState(null)
    const [loadingRefer, setLoadingRefer] = useState(false)
    const [isSuccessRefer, setIsSuccessRefer] = useState(false)

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("")
    const { state, pathname, search } = useLocation();
    const { currentUser, userState } = useUserState()


    useEffect(() => {
        const url = new URLSearchParams(search);
        const code = url.get("code")
        const referral = url.get("uid")
        if(code) getCodeInfo(code)
        else if(referral) getUserInfo(referral)

        const pendingLink = localStorage.getItem("pendingLink")
        if(pendingLink) localStorage.removeItem("pendingLink")
    }, [])

    const getUserInfo = async (uid) => {
        if (loading || !uid) return
        try {
            setLoading(true)
            await DataManager.getUserInfo(uid).then(data => {
                if (data.exists) {
                    setReferUser(data.data())
                }
            })
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError("An error occurred while getting user information.")
            setLoading(false)
        }
    }

    const getCodeInfo = async (mycode) => {
        if (loading || !mycode) return;

        try {
            setLoading(true)
            await DataManager.checkIfPromoCodeIsValid(mycode).then(data => {
                if (data.reward) {
                    setPromoCode(data)
                    console.log(data)
                }
            })
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setLoading(false)
            setError("An error occurred while getting promo code information.")
        }
    }

    const addCode = async () => {
        if (loadingAddCode || !promoCode || !currentUser.uid) return
        try {
            setLoadingAddCode(true)
            const result = await DataManager.checkIfAlreadyHasPromoCode(currentUser.uid, promoCode.code)
            if (!result.exists) { //didnt use the code already 
                await DataManager.addPromoCode(currentUser.uid, promoCode.code, promoCode.reward)
                await DataManager.sendSystemMessage("Congratulations! You've used the code: " + promoCode.code, promoCode.reward)
                setIsSuccessPromo(true);
            }
            setLoadingAddCode(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingAddCode(false)
            setError(Aux.UnexpectedError)
        }
    }

    const addReferUser = async () => {
        if (loadingRefer || !currentUser.uid || !referUser.uid) return;
        if (currentUser.system?.referralUser) { setError("You've already got a referral user."); return; }
        if (currentUser.uid === referUser.uid) { setError("You can't use your own referral link."); return; }

        try {
            setLoadingRefer(true)
            await DataManager.createReferralLink(currentUser.uid, referUser.uid).then(() => {
                setIsSuccessRefer(true)
            })
            setLoadingRefer(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingRefer(false)
            setError(Aux.UnexpectedError)
        }
    }

    const notLoggedInCreatePending = () => {

        //CREATE PENDING REDIRECT LINK AFTER LOGIN TO HERE ??????? 
        console.log("created pending at: " + pathname)
        localStorage.setItem("pendingLink", pathname)
        /* if(invitationPromo){
            setPendingCode(invitationPromo)
        }
        if(invitationRefferal){
            setPendingReferral(invitationRefferal)
        } */
    }

    return (
        <Container>
            <Header text="Invitation" />
            <MainContainer>
                <AlertMessage variant="error" text={error} />
                <AlertMessage variant="success" text={isSuccessPromo ? "You've successfully used your promo code." : isSuccessRefer ? "You've successfully added your referral user." : ""} show={isSuccessPromo || isSuccessRefer} />


                {loading ?
                    <div>
                        <Loader />
                    </div>
                    : promoCode?.code ?
                        <div>
                            got code
                        </div>
                        : referUser?.uid ?
                            <div>
                                got user
                            </div>
                            : <div>
                                got nothing
                            </div>}
                {promoCode?.code || referUser?.uid ?
                    <>
                        {currentUser?.uid ?

                            <div>
                                can accept
                            </div>
                            : <div>
                                need to login
                            </div>}
                    </>
                    : null}


            </MainContainer>
        </Container>
    )
}

const MainContainer = styled.div`

`