import React, { useContext, useEffect, useState } from 'react'
import { db } from '../firebase'
import { getDoc, setDoc, addDoc, collection, serverTimestamp, doc, where, query, getDocs, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore'
import { useAuthState } from '../Context/AuthContext'
import Aux from '../Managers/AuxManager'
import axios from 'axios'
import moment from "moment"
import DataManager from '../Managers/DataManager'
import { useDataState } from './DataContext'

const LIMIT_MESSAGES = 30;
const LIMIT_NOTIFICATIONS = 5;
export const UserContext = React.createContext()

export const UserContextProvider = (props) => {

	const [currentUser, setCurrentUser] = useState({})
	const [currentStatistics, setCurrentStatistics] = useState({})
	const [userLoading, setUserLoading] = useState(false);
	const [userState, setUserState] = useState("");
	const [userId, setUserId] = useState("");

	const [notifications, setNotifications] = useState([])
	const [lastNotification, setLastNotification] = useState(null)
	const [notificationState, setNotificationState] = useState("init")
	const [canGetMoreNotifications, setCanGetMoreNotifications] = useState(false)

	const [messages, setMessages] = useState([])
	const [lastMessage, setLastMessage] = useState({})
	const [messageState, setMessageState] = useState("init")
	const [canGetMoreMessages, setCanGetMoreMessages] = useState(false)
	const [currentViewingMessage, setCurrentViewingMessage] = useState("")

	const [hasNewDailyReward, setNewDailyReward] = useState(false)
	const { authUser } = useAuthState();
	const { getCurrentTimestamp } = useDataState();

	const [hasNewNotification, setNewNotification] = useState(false)
	const [hasNewMessage, setNewMessage] = useState(false)

	var currentUid = "";
	useEffect(() => {
		//TODO make unsubscribe to return outside 
		if (authUser?.uid) {

			if (currentUid !== authUser.uid) {
				currentUid = authUser.uid
				setCurrentUser({});
			}
			setUserState("loading")
			const usersRef = doc(db, "users", authUser.uid)
			const unsubscribe = onSnapshot(usersRef, snap => {
				if (snap.exists()) {
					const user = { ...snap.data(), uid: snap.id, }
					setCurrentUser(user)
					setUserState("success")
					CheckIfDailyReward(user)
				} else {
					setUserState("noUser")	//TODOOOOOOO
				}

			}, (err) => {
				setUserState("error")
				console.log(" user context error!")
			});

			return () => { unsubscribe(); }
		}
	}, [authUser, userId])


	var statisticsListener = null;
	useEffect(() => {

		if (currentUser?.uid) {
			const statisticsRef = doc(collection(db, "users", currentUser.uid, "statistics"), "statistics")
			//const q = query(notificationRef, orderBy("timestamp", "desc"), limit(5))
			statisticsListener = onSnapshot(statisticsRef, { includeMetadataChanges: false }, snap => {
				if (snap.exists) {
					setCurrentStatistics(snap.data())
				}
			})
		}
		return () => { if (statisticsListener) statisticsListener() }
	}, [currentUser.uid])


	const notificationsRef = React.useRef(notifications); //need to use ref because state doesnt update inside useeffect
	const setMyNotifications = (data) => {
		notificationsRef.current = data;
	  	setNotifications(data);
	};
	var isStartNotification = true
	useEffect(() => {

		if (currentUser?.uid) {
			const notificationRef = (collection(db, "users", currentUser.uid, "notifications"))
			const q = query(notificationRef, orderBy("timestamp", "desc"), limit(LIMIT_NOTIFICATIONS))
			var notificationListener = onSnapshot(q, { includeMetadataChanges: false }, snap => {
				if (!snap.empty) {
					var newNotifications = notificationsRef.current

					snap.docs.forEach(doc => {
						if (doc.data().timestamp) {
							const newNot = { ...doc.data(), id: doc.id };
							newNotifications = newNotifications.filter(x => x.id !== newNot.id)
							newNotifications.push(newNot);
						}
					})

					if(isStartNotification){
						setLastNotification(snap.docs[snap.docs.length - 1])
						console.log("last notification")
						if (snap.docs.length === LIMIT_NOTIFICATIONS) {
							setCanGetMoreNotifications(true)
						} 
						isStartNotification = false
					}
					
					if(DataManager.getLastNotification() !== snap.docs[0].id){
						setNewNotification(true);
						DataManager.setNewLastNotification(snap.docs[0].id) 
					}
					newNotifications = newNotifications.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
					setMyNotifications(newNotifications)
				}
			})
		}
		return () => { if (notificationListener) notificationListener() }
	}, [currentUser.uid])

	const messagesRef = React.useRef(messages); //need to use ref because state doesnt update inside useeffect
	const setMyMessages = (data) => {
		messagesRef.current = data;
	  	setMessages(data);
	};

	var isStartMessage = true;
	useEffect(() => {
		if (currentUser?.uid) {
			//setMessageState("loading")
			const chatRef = (collection(db, "chats"))
			const q = query(chatRef, where("users", "array-contains", currentUser.uid), orderBy("lastMessage.timestamp", "desc"), limit(LIMIT_MESSAGES))
			var messagesListener = onSnapshot(q, { includeMetadataChanges: false }, snap => {
				if (!snap.empty) {
					var newMessages = messagesRef.current;
					snap.docs.forEach(doc => {
						if (doc.data().lastMessage?.timestamp) {
							const newMessage = { ...doc.data(), id: doc.id };
							/* const exists = myMessages.some(item =>  item.lastMessage.timestamp.seconds === newMessage.lastMessage.timestamp.seconds) */
							newMessages = newMessages.filter(x => x.id !== newMessage.id)

							newMessages.push(newMessage);
							/* setMessages(myMessages)
							setMessageState("success")
							setNewMessage(true);
							if (newMessage.lastMessage?.from !== currentUser.uid && !newMessage.lastMessage?.read) {
								setNewMessage(true);
							} */
						}
					})
					if(isStartMessage){
						setLastMessage(snap.docs[snap.docs.length - 1])
						if (snap.docs.length === LIMIT_MESSAGES) {
							setCanGetMoreMessages(true)
						} 
						isStartMessage = false;
					}
					/* if(snap.docs.length < LIMIT_MESSAGES){
						setMessageState("finished")
					} */
					if (snap.docs[0].data().lastMessage?.from !== currentUser.uid && !snap.docs[0].data().lastMessage?.read) {
						setNewMessage(true);
					}
					newMessages = newMessages.sort((a, b) => b.lastMessage?.timestamp.valueOf() - a.lastMessage?.timestamp.valueOf())
					setMyMessages(newMessages)
					/* if (snap.docs.length < LIMIT) {
						setMessageState("finished")
					}
					else if (snap.docs.length === LIMIT) {
						setCanGetMoreMessages(true)
					} 
					setLastMessage(snap.docs[snap.docs.length - 1])*/
				} /* else {
					setMessageState("finished")
				} */
			})
		}
		return () => { if (messagesListener) messagesListener() }
	}, [currentUser.uid])

	const CheckIfDailyReward = async (user) => {
		const currentTime = await getCurrentTimestamp()
		if (currentTime > 0) {
			const isDaily = await DataManager.CheckIfDailyReward(user, currentTime)
			if (isDaily) {
				setNewDailyReward(true)
			}
		}

	}

	async function getMoreNotifications() {

		
		if (!canGetMoreNotifications || notificationState === "loading" || notificationState === "finished") return;
		try {
			setNotificationState("loading")

			const notificationRef = collection(db, "users", currentUser.uid, "notifications")
			const q = query(notificationRef, orderBy("timestamp", "desc"), limit(LIMIT_NOTIFICATIONS), startAfter(lastNotification))
			const nots = await getDocs(q);
			if (!nots.empty) {
				const newNots = nots.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
				setMyNotifications([...notifications, ...newNots])
				setLastNotification(nots.docs[nots.docs.length - 1])
				setNotificationState("success")
				if (newNots.length < LIMIT_NOTIFICATIONS) {
					setNotificationState("finished")
					setCanGetMoreNotifications(false)
				}else{
					setCanGetMoreNotifications(true)
				}
				/* if(newNots.length === LIMIT_NOTIFICATIONS) {
					setCanGetMoreNotifications(true)
				} */
			} else {
				setCanGetMoreNotifications(false)
				setNotificationState("finished")
			}
		} catch (error) {
			Aux.handleError(error)
			setNotificationState("error")
		}
	}

	async function getMoreMessages() {

		if(messagesRef.current.lenght <= LIMIT_MESSAGES){
			setMessageState("finished")
			return;
		}
		if (messageState === "loading" || messageState === "finished" || !canGetMoreMessages) return;
		try {
			setMessageState("loading")
			setCanGetMoreMessages(false)
			const chatRef = (collection(db, "chats"))
			const q = query(chatRef, where("users", "array-contains", currentUser.uid), orderBy("lastMessage.timestamp", "desc"), limit(LIMIT_MESSAGES), startAfter(lastMessage))
			const msgs = await getDocs(q);

			if (!msgs.empty) {
				const newMessages = msgs.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
				let oldMessages = [...messages, ...newMessages]
				//const uniqueMessages = oldMessages.filter((val, id, array) => array.indexOf(val) == id);
				console.log("old messages")
				console.log(oldMessages)
				setMyMessages(oldMessages)
				setLastNotification(msgs.docs[msgs.docs.length - 1])
				setMessageState("success")
				/* if (newMessages.length < LIMIT_MESSAGES) {
					setMessageState("finished")
				}else  */
				if(newMessages.length === LIMIT_MESSAGES) {
					setCanGetMoreMessages(true)
				}
			} /* else {
				setMessageState("finished")
			} */
		} catch (error) {
			Aux.handleError(error)
			setMessageState("error")
		}

	}

	const setNewAvatar = (avatar) => {
		let oldUser = currentUser
		oldUser.avatar = avatar
		setCurrentUser(oldUser)
	}

	var originalUser = {}
	const createDummy = (user) => {
		console.log("creating dummy...")
		setCurrentUser(user)
		setMessages([])
		setNotifications([])

	}

	const revertToOriginalUser = () => {
		console.log("converting to...")
		console.log(originalUser)
		setCurrentUser(originalUser)
	}

	return <UserContext.Provider
		value={
			{
				currentUser,

				setNewAvatar,
				userState,
				setUserId,

				getMoreNotifications,
				notificationState,
				notifications,
				hasNewNotification,
				setNewNotification,
				canGetMoreNotifications,

				messages,
				getMoreMessages,
				messageState,
				setCurrentViewingMessage,
				canGetMoreMessages,
				hasNewMessage,

				currentStatistics,
				
				createDummy,
				revertToOriginalUser,

			}} {...props}
	>
		{props.children}
	</UserContext.Provider>
}

export const useUserState = () => {
	const auth = useContext(UserContext)
	return { ...auth, }
}