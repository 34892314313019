import { db, storage } from '../firebase'
import {
    collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc,
    doc, writeBatch, serverTimestamp, increment, orderBy, limit, query, where, startAfter, startAt, setDoc
} from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes, } from 'firebase/storage'
import imageCompression from 'browser-image-compression';
import Aux from '../Managers/AuxManager';
const postsCollectionRef = collection(db, "posts");




class PostsManager {

    //#region Batches
    BalanceBatch = (amount, type, id, message = "") => {
        return {
            type: type,
            amount: amount,
            timestamp: serverTimestamp(),
            id:id,
            message:message,
        }
    }

    UserPostsMoneyBatch = (amount) => {
        return {
            amountSpent: increment(amount),
            amount: increment(-amount),
            postsCount: increment(1),
        }
    }

    UserSpentAmountBatch = (amount) => {
        return {
            amountSpent: increment(amount),
            amount: increment(-amount),
            postsCount: increment(1),
        }
    }
    HashtagBatch = (hashtag, amount) => {
        return {
            hashtag: hashtag,
            amount: increment(amount),
            postsCount: increment(1),
            lastTimestamp: serverTimestamp(),
        }
    }

    UpdatePostBatch = (amount, post) => {

        var postBatch = {}
        const value = amount <= 0 ? 3 : amount <= 5 ? amount * 4 : amount * 2


        if (amount >= 0) {
            postBatch = { ...postBatch, amount: increment(amount), commentsSpent: increment(amount) }
        }
        if (post.ranking + value >= Aux.LIMIT_TRENDING) {
            postBatch = { ...postBatch, trendingRanking: increment(value), }
        }
        postBatch = {
            ...postBatch,
            ranking: increment(value),
            commentCount: increment(1),
        }
        return postBatch;
    }

    PostLikedBatch = (post) => {
        var postBatch = {}
        const likeValue = 1
        if (post.liked) {
            if (post.ranking - likeValue >= Aux.LIMIT_TRENDING) {
                postBatch = { ...postBatch, trendingRanking: increment(-likeValue) }
            }
            postBatch = { ...postBatch, ranking: increment(-likeValue), likeCount: increment(-likeValue) }
        } else {
            if (post.ranking + likeValue >= Aux.LIMIT_TRENDING) {
                postBatch = { ...postBatch, trendingRanking: increment(likeValue) }
            }
            postBatch = { ...postBatch, ranking: increment(likeValue), likeCount: increment(likeValue) }
        }
        return postBatch
    }

    LikeBatch = (post, uid) => {
        return {
            uid: uid,
            postid: post.id,
            timestamp: serverTimestamp()
        }
    }

    notificationLikeBatch = (uid, post,) => {
        return {
            postid: post.id,
            from: uid,
            timestamp: serverTimestamp(),
            type: "like",
            text: post.text,
            image: post.image,
            likesCount: post.liked ? increment(-1) : increment(1)
        }
    }

    notificationCommentBatch = (fromUid, comment, post) => {
        var commentBatch = {
            from: fromUid,
            timestamp: serverTimestamp(),
            message: comment.text || "",
            type: "comment",
            postid: post.id,
        }
        if (comment.amount > 0) {
            commentBatch = { ...commentBatch, amount: comment.amount, state: "pending" }
        }
        return commentBatch;
    }

    

    //#endregion


    getNewPostsID = () => {
        return doc(collection(db, "posts")).id;
    }

    uploadPhoto = async (photoToUpload) => {
        if (!photoToUpload) return

        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, "posts/ to do id");
            const uploadTask = uploadBytesResumable(storageRef, photoToUpload)
            uploadTask.on(
                "state_changed", (snapshot) => {
                    /* const prog = Math.round((snapshot.bytesTransferred/snapshot.totalBytes) * 100)
                    //setProgress(prog)
                    console.log(prog) */
                }, (err => {
                    console.error(err)
                    reject(err)
                }),
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref)
                    resolve(url);
                }
            )
        })
    }

    uploadPhotoCompressed = async (postId, photoToUpload) => {
        if (!photoToUpload || !postId) return;
        const storageRef = ref(storage, `posts/${postId}`);
        const file = await imageCompression(photoToUpload, { maxSizeMB: 1, maxWidthOrHeight: 1920 })
        const snapshot = await uploadBytes(storageRef, file)
        const url = await getDownloadURL(snapshot.ref)
        return url
    }

    //#region Posts

    createPost = (postId, newPost) => {
        if(!postId || !newPost) return;
        const batch = writeBatch(db);
        const postDoc = doc(db, "posts", postId)
        const userDoc = doc(db, "users", newPost.uid)
        //const balanceRef = collection(db, `users/${newPost.uid}/balance`);
        const balanceRef = doc(collection(db, "users", newPost.uid, "balance"));
        if (newPost.hashtag) {
            const hashtagDocument = doc(db, "hashtags", newPost.hashtag)
            batch.set(hashtagDocument, this.HashtagBatch(newPost.hashtag, newPost.amount), { merge: true })
        }
        batch.set(postDoc, { ...newPost, timestamp: serverTimestamp() })
        batch.set(balanceRef, this.BalanceBatch(newPost.amount, Aux.TYPE_CREATE_POST, postId))
        batch.update(userDoc, this.UserPostsMoneyBatch(newPost.amount))

        return batch.commit();
    }

    updatePost = (id, updatedPost) => {
        if(!id || !updatedPost) return;
        const postDoc = doc(db, "posts", id)
        return updateDoc(postDoc, updatedPost)
    }

    deletePost = (id) => {
        if(!id) return;
        const postDoc = doc(db, "posts", id)
        return deleteDoc(postDoc)
    }

    getAllPosts = (type, lastVisible, uid, hashtag = null, hasLimit,) => {
        if(!type) return;
        const LIMIT = hasLimit || Aux.LIMIT;
        const postsRef = collection(db, "posts")
        let q = query(postsRef, orderBy("timestamp"), limit(LIMIT))

        switch (type) {
            case "latest":
                q = query(postsRef, orderBy("timestamp", "desc"), limit(LIMIT))
                break;
            case "top":
                q = query(postsRef, orderBy("amount", "desc"), limit(LIMIT))
                break;
            case "trending":
                if (hashtag) {
                    q = query(postsRef, orderBy("ranking"), limit(LIMIT))
                } else {
                    q = query(postsRef, orderBy("timeSince", "desc"), orderBy("trendingRanking", "desc"), limit(LIMIT))
                }
                break;
            case "userPosts":

                q = query(postsRef, where("uid", "==", uid), orderBy("timestamp", "desc"), limit(LIMIT))
                break;
            case "userLikes":
                const likesRef = collection(db, "likes")
                q = query(likesRef, where("uid", "==", uid), orderBy("timestamp", "desc"), limit(LIMIT))
                break;
            case "userComments":
                const usercommentsRef = collection(db, "comments")
                q = query(usercommentsRef, where("uid", "==", uid), orderBy("timestamp", "desc"), limit(LIMIT))
                break;
            case "userMessages":
                const chatRef = (collection(db, "chats"))
		        q = query(chatRef, where("users", "array-contains", uid), orderBy("lastMessage.timestamp", "desc"), limit(15))   
            break;

            case "postComments":
                const postcommentsRef = collection(db, "comments")
                q = query(postcommentsRef, where("postid", "==", uid), orderBy("amount", "desc"), orderBy("timestamp", "desc"), limit(LIMIT))
                break;
            case "topUsers":
                const usersRef = collection(db, "users")
                q = query(usersRef, orderBy("amountSpent", "desc"), limit(LIMIT))
                break;
            case "topHashtags":
                const hashtagsRef = collection(db, "hashtags")
                q = query(hashtagsRef, orderBy("amount", "desc"), limit(LIMIT))
                break;
            
            case "dummyUsers":
                const usersColl = collection(db, "users")
                q = query(usersColl, where("details.role", "==", "dummy"), orderBy("details.timestamp", "desc"), limit(LIMIT))
                break;
        }


        if (hashtag) {
            q = query(q, where("hashtag", "==", hashtag))
        }

        //q = query(postsRef, orderBy("amount", "desc"), limit(3))

        if (lastVisible) {
            q = query(q, startAfter(lastVisible))
        }

        return getDocs(q);
    }

    getPost = (id) => {
        if(!id) return;
        const postDoc = doc(db, "posts", id)
        return getDoc(postDoc)
    }

    createComment = async (post, comment, uid) => {
        if (!post || !comment || !uid) return;
        const postRef = doc(db, "posts", post.id)
        const commentsRef = doc(collection(db, "comments"))
        const userRef = doc(db, "users", uid)
        const balanceRef = doc(collection(userRef, "balance"))
        const targetUser = doc(db, "users", post.uid)
        const targetUserNotifications = doc(collection(targetUser, "notifications"))
        const targetUserStatistics = doc(targetUser, "statistics", "statistics")

        const batch = writeBatch(db);
        batch.set(commentsRef, { ...comment, timestamp: serverTimestamp() })
        if (comment.amount > 0) {
            batch.set(balanceRef, this.BalanceBatch(comment.amount, Aux.TYPE_CREATE_COMMENT, post.id))
            batch.update(userRef, this.UserMoneyBatch(comment.amount))
            batch.set(targetUserStatistics, { commentCount: increment(1), commentTotalSpent: increment(comment.amount) })
        } else {
            batch.set(targetUserStatistics, { commentCount: increment(1) })
        }
        batch.update(postRef, this.UpdatePostBatch(comment.amount, post))
        batch.set(targetUserNotifications, this.notificationCommentBatch(uid, comment, post))
        return batch.commit();
    }

    //#endregion Posts

    //#region Likes

    checkIfUserLikedPost = (postId, uid) => {
        if(!postId || !uid) return;
        const userRef = doc(db, "likes", postId + uid )
        return getDoc(userRef)
    }

    toogleLikePost = (post, uid, liked) => {
        if(!post || !uid) return;
        //const userDoc = doc(db, 'users', uid);
        const postDoc = doc(db, "posts", post.id);
        const likesDoc = doc(db, "likes", post.id + uid);
        const targetUserDoc = doc(db, "users", post.uid)
        const targetUserDocStatistics = doc(targetUserDoc, "statistics", "statistics")
        const targetUserDocNotification = doc(targetUserDoc, "notifications", post.id)
        const batch = writeBatch(db);


        batch.update(postDoc, this.PostLikedBatch(post))
        if (liked) {
            batch.set(likesDoc, this.LikeBatch(post, uid))
            batch.set(targetUserDocStatistics, { likesCount: increment(1), likesToEarn: increment(1) }, { merge: true })
        } else {
             batch.delete(likesDoc)
            batch.set(targetUserDocStatistics, { likesCount: increment(-1), likesToEarn: increment(-1) }, { merge: true })
        }
        batch.set(targetUserDocNotification, this.notificationLikeBatch(uid, post), { merge: true })
        return batch.commit()
    }
    //#endregion Likes


    
}

export default new PostsManager
