import { useState } from 'react'
import Colors from '../Utilities/Colors'
import SuccessCircle from 'mdi-react/CheckCircleOutlineIcon'
import styled, { css } from 'styled-components'
import {Link} from "react-router-dom"
import Loader from './Loader'


export default function Button({variant, text, color, borderColor, size, to, icon, className, onClick, type, style, isDisabled, isLoading,isSuccess, showShadow, }) {

    let backgroundColor = Colors.primary;
    let textColor = Colors.white;

    switch (variant) {
        case "secundary":
            backgroundColor = Colors.secundary;
            textColor = Colors.white;
            break;
        case "outline":
            backgroundColor = Colors.white;
            textColor = color ? color : "#999";
            break;
        case "outline-primary":
            backgroundColor = Colors.white;
            textColor = color ? color : "#999";
            break;
    }
    if (isSuccess) {
        backgroundColor = Colors.success;
    }

    const Icon = icon;



    return (
            <StyledButton
                as={to ? Link : undefined}
                to={to}
                onClick={onClick}
                disabled={isLoading || isDisabled || isSuccess}
                className={className}
                /* style={{ backgroundColor: backgroundColor, color: textColor , ...props.style }}  */
                style={style}
                type={type}
                size={size}
                //darkerColor={darkerColor}
                shadow={showShadow}
                variant={variant}
                color={color}
                issuccess={isSuccess}
                //isloading={isLoading}
                isdisabled={isDisabled}
            >
                {isSuccess ?
                    <>
                        <SuccessCircle style={{marginRight:5}}/>
                        <span style={{ color: textColor,}}> Successful</span>
                    </>
                    :
                    <>
                        {isLoading ?
                                <Loader color={"white"}/>
                            : (
                                <>
                                    {Icon && <Icon style={{ marginRight: text ? 5 : 0 }} />}
                                    <span style={{ color: "inherit" }}>
                                        {text}
                                    </span>
                                </>
                            )}
                    </>
                }
            </StyledButton>
    )
}

const StyledButton = styled.button`

        background-color: ${Colors.primary};
        position: relative;
        padding: 1rem 2rem;
        text-decoration: none;
        border-radius: 3px;
        color: #fff;
        font-weight: 500;
        font-size:1rem;
        transition: all ease-in-out 0.1s;
        cursor: pointer;
        border:none;
        display: flex;
        
        text-decoration: none;
        align-items: center;
        justify-content: center;


        &:hover {
            background-color: ${Colors.primaryDarker} ;
        } 

        ${props => props.shadow && css`
            box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        `}

        

        ${props => props.variant === "outline" && css`
            color: ${props => props.color || "#777"};
            background-color: #fff;
            border: 2px #ccc solid;
            border-color: ${props => props.color || "#ccc"};
            &:hover{
                background-color: #f5f5f5;
                border: 2px solid #333;
                //border-color: "#333";
            }
        `}

        ${props => props.variant === "secundary" && css`

            background-color: ${Colors.secundaryLightest};
            color: ${Colors.secundaryDarker};
            &:hover{
                background-color: ${Colors.secundaryLighter};
                //border: 2px #333 solid;
            }
        `}

        ${props => props.variant === "tabInactive" && css`

            background-color: ${Colors.secundaryLightest};
            color: ${Colors.secundaryDarkest};
            &:hover{
                background-color: ${Colors.secundaryLighter};
            }
        `}

        ${props => props.variant === "tabActive" && css`

            background-color: ${Colors.primary};
            color: white;
            &:hover{
                background-color: ${Colors.primaryDarker};
            }
        `}

        ${props => props.issuccess && css`

            background-color: ${Colors.success};

        `}
        
        ${props => props.isdisabled && css`
            pointer-events: none;
            background-color: ${Colors.secundaryLighter};
            color: ${Colors.secundaryDarker};
        `}

        ${props => props.size === 'small' && css`
            padding: .25rem .5rem;
            font-size: .875rem;
            border-radius: .2rem;
        `}

        ${props => props.size === 'medium' && css`
            padding: .5rem 1rem;
            font-size: 1rem;
            border-radius: .3rem;
        `}

        ${props => props.size === 'large' && css`
            padding: .5rem 1rem;
            font-size: 1.25rem;
            border-radius: .3rem;
        `}

        


    `
