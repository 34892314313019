import { useState, useEffect } from 'react'
import Container from "../Components/Container";
import Aux from '../Managers/AuxManager';
import { useUserState } from '../Context/UserContext'
import { useAuthState } from '../Context/AuthContext'
import InputText from '../Components/InputTextMaterial';
import Button from '../Components/Button';
import AlertMessage from '../Components/AlertMessage';
import Colors from '../Utilities/Colors';
import Header from '../Components/Header';
import { useNavigate } from 'react-router-dom'

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const { forgotPassword } = useAuthState()


    const onSubmit = async () => {
        if (loading) return;


        try {
            setLoading(true);
            const isValidEmail = Aux.validateEmail(email)
            if (isValidEmail) {
                setError(isValidEmail)
                setLoading(false)
                return;
            }

            await forgotPassword(email).then(() => {
                setSuccess(true)
            })
            
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError("An unexpected error has occurred.")
            setLoading(false);
        }

    }

    const onChangeEmail = (text) => {
        setEmail(text);
        setError("");
    }

    return (
        <>
            <Container>
                <Header text="Forgot Password?" goBack/>
                <AlertMessage variant="error" text={error} />
                <AlertMessage variant="success" text={"We've sucessfully sent you a recovery email."} show={success} />
                <span className="sub-header">Enter your account's email address below and we will send you a password reset link.</span>
                
                <InputText type="text" value={email} title="Email" onChange={onChangeEmail} />
                <Button className="w-100" text="Send Email" onClick={onSubmit} isSuccess={success} isLoading={loading}  />
                
            </Container>
        </>
    )
}