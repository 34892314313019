
const Aux = {
    handleError: function(error){
        console.error(error)
    },
    handleLog: function(log){
        console.log(log)
    },
    removeSpecialCharactersFromText: function(text){
        return text.replace(/[^a-zA-Z0-9-_.*$]/g, '')
    },
    removeSpecialCharactersForHashtag: function(text){
        return text.replace(/[^a-zA-Z0-9_]/g, '')
    },
    validateUsername: function(username){
        if (!username) return "Username is required."
		if (username.length < 5) return "Username must have at least 5 characters."
		if (username.length > 30) return "Username is too long. max 30 characters."
		if (/[^a-zA-Z0-9._-]/gi.test(username)) return "Username can only have '.', '-' and '_'"
		if (/[\s]/g.test(username)) return "Username can't have spaces"
        return "";
    },
    validatePassword: function(password){
        if (!password) return "Password is required."
		if (password.length < 6) return "Password too short. min 6 characters"
		if (/[\s]/g.test(password)) return "Password can't have spaces"
        return "";
    },
    validateEmail: function(email){
        if (!email) return "Email is required."
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) return "Invalid email address"
        return;
    },
    validateName: function(name){
		if (name.length > 60) return "Name is too long. max 60 characters."
        return "";
    },
    formatTextNewLine: function(text){
        return text.replace(/[\r\n|\r|\n]+/g,'\n');
    },
    getUsername: function(username, isLoading = false){
        if(username){
            return username
        }else if(isLoading){
            return "...loading"
        }else{
            return "..."
        }
    },
    checkIfMobile: function(){
        const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        return windowWidth < 800
    },
    checkIfUrl: function(url) {
        //var urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        const output = url.match(pattern)
        return output

    },
    UnexpectedError:"An unexpected error has occurred.",
    LIMIT: 20,
    LIMIT_MESSAGES: 15,
    LIMIT_TRENDING:20,
    
    TYPE_CREATE_POST: "post_created",
    TYPE_CREATE_COMMENT: "comment_created",
    TYPE_SEND_REQUEST_MESSAGE: "sent_request_message",
    TYPE_DAILY_REWARD: "daily_reward",

    TYPE_MESSAGE_RECEIVED: "message_accepted",
    TYPE_SYSTEM_RECEIVED: "system_accepted",
    TYPE_LINK_ACCEPTED: "link_accepted",
    TYPE_LINK_USED: "link_got_used",
    TYPE_COMMENT_REWARD: "comment_reward",
    TYPE_LIKES_REWARD: "likes_reward",
    TYPE_CREATE_GROUP: "created_group",
    TYPE_BUY_BALANCE: "in_app_purchase",
    
    REWARD_LINK_USED: 10, //this is in dollars 

}

export default Aux