
import Container from "../Components/Container";
import Header from "../Components/Header";
import InputText from "../Components/InputTextMaterial";
import SimpleInput from "../Components/SimpleInput";
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import YoutubeIcon from 'mdi-react/YoutubeIcon'
import SnapchatIcon from 'mdi-react/SnapchatIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Aux from "../Managers/AuxManager";
import DataManager from "../Managers/DataManager";
import { useUserState } from "../Context/UserContext"
import Button from "../Components/Button";
//import { TwitterIcon } from "react-share";
import CheckIfLogged from "../Checks/CheckIfLogged";
import Avatar from "../Components/User/Avatar";
import AlertMessage from "../Components/AlertMessage";
import Colors from "../Utilities/Colors";

export default function EditProfile(props) {


    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [bio, setBio] = useState("")
    const [website, setWebsite] = useState("")

    const [avatar, setAvatar] = useState("")
    const [deleteAvatar, setDeleteAvatar] = useState(false)
    const [selectedPhoto, setSelectedPhoto] = useState(null)
    const [uploadedPhoto, setUploadedPhoto] = useState(null)

    const [instagram, setInstagram] = useState("")
    const [twitter, setTwitter] = useState("")
    const [snapchat, setSnapchat] = useState("")
    const [youtube, setYoutube] = useState("")
    const [facebook, setFacebook] = useState("")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    const { currentUser } = useUserState()
    const navigate = useNavigate()

    const inputRef = useRef(null);


    useEffect(() => {
        if (!isSuccess && !loading) {
            if (currentUser.username) setUsername(currentUser.username)
            if (currentUser.name) setName(currentUser.name)
            if (currentUser.bio) setBio(currentUser.bio)
            if (currentUser.website) setWebsite(currentUser.website)
            if (currentUser.avatar) setAvatar(currentUser.avatar)
            if (currentUser.social?.instagram) setInstagram(currentUser.social.instagram)
            if (currentUser.social?.facebook) setFacebook(currentUser.social.facebook)
            if (currentUser.social?.twitter) setTwitter(currentUser.social.twitter)
            if (currentUser.social?.youtube) setYoutube(currentUser.social.youtube)
            if (currentUser.social?.snapchat) setSnapchat(currentUser.social.snapchat)
        }
    }, [currentUser])

    const validate = () => {
        if (username && currentUser.username !== username) {
            const errUsername = Aux.validateUsername(username)
            if (errUsername) return errUsername;
        }
        if (name && currentUser.name !== name) {
            const errName = Aux.validateName(name)
            if (errName) return errName;
        }
        return null;
    }

    const onSubmitEdit = async () => {
        if (loading || !currentUser) return

        const errValidate = validate()
        if (errValidate) {
            Aux.handleError(errValidate)
            setError(errValidate)
            return;
        }

        try {
            setLoading(true)
            let newUser = { social: {} }
            let updatePosts = false
            let updateUser = false;
            if (currentUser.username !== username) {

                const freeUsername = await DataManager.checkIfUsernameExists(username); //check if username exists
                if (!freeUsername) {
                    setError("Username already exists.")
                    setLoading(false)
                    return;
                }
                updatePosts = true
                newUser.username = username.toLowerCase();
                updateUser = true;
            }

            if (currentUser.name !== name) { newUser.name = name; updateUser = true; }
            if (currentUser.details.bio !== bio) { newUser.details.bio = bio; updateUser = true; }
            if (website && currentUser.social?.website !== website) { newUser.social.website = website; updateUser = true; }
            if (twitter && currentUser.social?.twitter !== twitter) { newUser.social.twitter = twitter; updateUser = true; }
            if (instagram && currentUser.social?.instagram !== instagram) { newUser.social.instagram = instagram; updateUser = true; }
            if (snapchat && currentUser.social?.snapchat !== snapchat) { newUser.social.snapchat = snapchat; updateUser = true; }
            if (youtube && currentUser.social?.youtube !== youtube) { newUser.social.youtube = youtube; updateUser = true; }
            if (facebook && currentUser.social?.facebook !== facebook) { newUser.social.facebook = facebook; updateUser = true; }
            if (uploadedPhoto) {
                newUser.avatar = uploadedPhoto
                updateUser = true;
            } else if (deleteAvatar) {
                newUser.avatar = "delete"
                updateUser = true;
            }
            if (updateUser) {
                await DataManager.updateUser(currentUser.uid, newUser, updatePosts).then(() => {
                    setIsSuccess(true)
                    //setNewAvatar(selectedPhoto)
                })
            }
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError(Aux.UnexpectedError)
            setLoading(false)
        }
    }

    const handlePhotoClick = () => {
        if (inputRef) inputRef.current.click();
    }

    const handleChoosePhoto = (event) => {
        const uploadedFile = event?.target.files[0];
        let photo = URL.createObjectURL(event.target.files[0])
        if (!uploadedFile) return;

        setSelectedPhoto(photo)
        setUploadedPhoto(uploadedFile)
        setDeleteAvatar(false)
    };



    return (
        <>
            <Container>
                <Header text={props.isStart ? "Add Information" : "Edit Profile"} />
                <AlertMessage variant="error" text={error} />
                {currentUser.uid ?
                    <FormContainer>
                        <div className="flex-center align-center column">
                            {/* selectedPhoto ? <StyledImage src={selectedPhoto}/> :  */<Avatar src={selectedPhoto} hide={deleteAvatar} user={currentUser} />}
                            <input type="file" accept=".png, .jpg, .jpeg" hidden ref={inputRef} onChange={handleChoosePhoto} />
                            <div className="flex-center m-t-10 align-center">
                                <Button text={selectedPhoto ? "Change photo" : "Choose photo"} onClick={handlePhotoClick} size="small"/>
                                {selectedPhoto && <DeletePhotoText onClick={() => { setSelectedPhoto(false); setUploadedPhoto(null); setDeleteAvatar(true) }}>Delete Photo</DeletePhotoText>}
                            </div>
                        </div>
                        <EditSectionWrapper>
                            <SimpleInput title={"Username"} placeholder={"Username"} value={username} onChange={(e) => { setUsername(e) }} />
                            <SmallLabel>It's your unique ID. 50 characters max.</SmallLabel>
                        </EditSectionWrapper>
                        <EditSectionWrapper>
                            <SimpleInput title={"Name"} placeholder={"Name"} value={name} onChange={(e) => { setName(e) }} />
                            <SmallLabel>Makes it easier for others to recognise you.</SmallLabel>
                        </EditSectionWrapper>
                        <EditSectionWrapper>
                            <SimpleInput textArea={true} title={"Bio"} placeholder={"Bio"} value={bio} onChange={(e) => { setBio(e) }} />
                            <SmallLabel>Add something interesting about you.</SmallLabel>
                        </EditSectionWrapper>
                        <EditSectionWrapper>
                            <SimpleInput title={"Website"} placeholder={"Website"} value={website} onChange={(e) => { setWebsite(e) }} />
                            <SmallLabel>If you have any link to share.</SmallLabel>
                        </EditSectionWrapper>

                        <SubHeaderText>Social Media</SubHeaderText>
                        <SimpleInput prefix={"Facebook"} prefixIcon={FacebookIcon} placeholder={"facebook"} value={facebook} onChange={(e) => setFacebook(e)} style={{ marginTop: 10 }} />
                        <SimpleInput prefix={"Instagram"} prefixIcon={InstagramIcon} placeholder={"instagram"} value={instagram} onChange={(e) => setInstagram(e)} style={{ marginTop: 10 }} />
                        <SimpleInput prefix={"Youtube"} prefixIcon={YoutubeIcon} placeholder={"youtube"} value={youtube} onChange={(e) => setYoutube(e)} style={{ marginTop: 10 }} />
                        <SimpleInput prefix={"Twitter"} prefixIcon={TwitterIcon} placeholder={"twitter"} value={twitter} onChange={(e) => setTwitter(e)} style={{ marginTop: 10 }} />
                        <SimpleInput prefix={"Snapchat"} prefixIcon={SnapchatIcon} placeholder={"snapchat"} value={snapchat} onChange={(e) => setSnapchat(e)} style={{ marginTop: 10 }} />
                        
                        <div className="flex-between" style={{marginTop:20}}>
                            <Button className="m-r-10" text="Cancel" variant={"secundary"} onClick={() => navigate(-1)} />
                            <Button className="w-100 m-l-10" text="Edit Profile" isLoading={loading} onClick={onSubmitEdit} isSuccess={isSuccess} />
                        </div>
                       
                    </FormContainer>
                    : <CheckIfLogged />}
            </Container>
        </>
    )
}

const FormContainer = styled.div`


`

const EditSectionWrapper = styled.div`
    margin-top: 10px;

`
const SmallLabel = styled.label`
    font-size: 0.75rem;
    color: #666;

`
const DeletePhotoText = styled.p`
    color: ${Colors.error};
    cursor: pointer;
    margin-left: 10px;
    &:hover{
        color: ${Colors.errorDarker};
    }
`

const SubHeaderText = styled.p`
    color:#999;
    font-size: 1.2rem;
    margin: 10px 0px;
`

const StyledImage = styled.img`
    border-radius: 50%;
    object-fit: cover;
    display: "inline-block";
    width: 50px;
    height: 50px;
`