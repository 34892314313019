import React, { useEffect, useState } from 'react'
import PostsManager from '../Managers/PostsManager';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../Components/Loader';
import Aux from '../Managers/AuxManager';
import PostCard from './PostCard';
import styled from 'styled-components';
import ShowList from '../Components/ShowList';
import Button from '../Components/Button';
import { useParams } from 'react-router-dom';
import { useDataState } from '../Context/DataContext';

export default function ShowPosts(props) {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [lastVisible, setLastVisible] = useState(null)
    const [currentType, setCurrentType] = useState("latest")
    const [currentHashtag, setCurrentHashtag] = useState("")
    const [currentHashtagType, setCurrentHashtagType] = useState("trending")
    const [error, setError] = useState("")
    const { type, tag, tagtype } = useParams();
    const {currentMenu, setCurrentMenu} = useDataState()

    /*  useEffect(() => {
        setCurrentMenu(currentType)
    }, [currentType])   */

    useEffect(() => {
        if (props.type || type){
            setCurrentType(props.type || type)
            //setCurrentMenu(currentType)
        }
    }, [props.type, type])

    useEffect(() => {

        if (tag !== currentHashtag) {
            setCurrentHashtag(tag)
            setCurrentMenu(tag)
        }
    }, [tag])

    useEffect(() => {
        if(tagtype && tagtype !== currentHashtagType){
            setCurrentHashtagType(tagtype)
        }
    }, [tagtype])

    const getPosts = async (isStart) => {

        if (loading) return;
        console.log("is getting posts......")
        try {
            setLoading(true);
            const data = await PostsManager.getAllPosts(currentType, lastVisible)
            if (!data.empty) {
                const newPosts = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

                if (newPosts.length < 3) {
                    setIsFinished(true);
                }

                setPosts([...posts, ...newPosts])
                setLastVisible(data.docs[data.docs.length - 1])
                setLoading(false)
                //console.log(newPosts)
            } else {
                setIsFinished(true);
                setLoading(false)
            }
        } catch (error) {
            setError(error)
            Aux.handleError(error)
            setLoading(false);
        }
    }

    return (
        <PostsContainer>
            {currentHashtag ?
                <HashtagsContainer>
                    <h3>#{currentHashtag}</h3>
                    <div className='flex'>
                        <Button text="Trending" size="medium" variant={currentHashtagType === "trending" ? "tabActive" : "tabInactive"} 
                        className={"m-r-5"} to={`/hashtag/${currentHashtag}/trending`} />
                        <Button text="Latest" size="medium" variant={currentHashtagType === "latest" ? "tabActive" : "tabInactive"} 
                        className={"m-h-5"} to={`/hashtag/${currentHashtag}/latest`}  />
                        <Button text="Top" size="medium" variant={currentHashtagType === "top" ? "tabActive" : "tabInactive"} 
                        className={"m-h-5"} to={`/hashtag/${currentHashtag}/top`} />
                    </div>
                </HashtagsContainer>
                :
                <TabsContainer>
                    <Button text="Trending" size="medium" variant={currentType === "trending" ? "tabActive" : "tabInactive"} 
                    className={"m-r-5"} to="/trending" />
                    <Button text="Latest" size="medium" variant={currentType === "latest" ? "tabActive" : "tabInactive"} 
                    className={"m-h-5"} to="/latest" />
                    <Button text="Top" size="medium" variant={currentType === "top" ? "tabActive" : "tabInactive"} 
                    className={"m-h-5"} to="/top" />
                </TabsContainer>
            }

            {currentHashtag ?
                <>
                    <ShowList
                        key="hashtag-trending-list"
                        type={"trending"}
                        hashtag={currentHashtag}
                        Render={PostCard}
                        parent={() => document.getElementById("main-sidebar")}
                        endText={"There's no more posts."}
                        emptyText={"No posts found."}
                        hide={currentHashtagType !== "trending"}
                    />
                    <ShowList
                        key="hashtag-latest-list"
                        type={"latest"}
                        hashtag={currentHashtag}
                        Render={PostCard}
                        parent={() => document.getElementById("main-sidebar")}
                        endText={"There's no more posts."}
                        emptyText={"No posts found."}
                        hide={currentHashtagType !== "latest"}
                    />
                    <ShowList
                        key="hashtag-top-list"
                        type={"top"}
                        hashtag={currentHashtag}
                        Render={PostCard}
                        parent={() => document.getElementById("main-sidebar")}
                        endText={"There's no more posts."}
                        emptyText={"No posts found."}
                        hide={currentHashtagType !== "top"}
                    />
                </> : null}
            <ShowList
                key="top-list"
                type={"top"}
                //hashtag={currentHashtag}
                Render={PostCard}
                parent={() => document.getElementById("main-sidebar")}
                endText={"There's no more posts."}
                emptyText={"No posts found."}
                hide={currentType !== "top" || currentHashtag}
            />
            <ShowList
                key="latest-list"
                type={"latest"}
                Render={PostCard}
                //hashtag={"superhashtags"}
                parent={() => document.getElementById("main-sidebar")}
                endText={"There's no more posts."}
                emptyText={"No posts found."}
                hide={currentType !== "latest" || currentHashtag}
            />
            <ShowList
                key="trending-list"
                type={"trending"}
                //hashtag={currentHashtag}
                Render={PostCard}
                parent={() => document.getElementById("main-sidebar")}
                endText={"There's no more posts."}
                emptyText={"No posts found."}

                hide={currentType !== "trending" || currentHashtag}
            />

        </PostsContainer>
    )
}

const PostsContainer = styled.div`
    width: 100%;
`

const HashtagsContainer = styled.div`

`

const TabsContainer = styled.div`
    display: none;
    margin-top: 10px;

    @media (max-width: 800px) {
        display: flex;
    }
`

const Tab = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    background-color: ${props => props.active ? "#f5f5f5" : "#f9f9f9"};
`

const TabText = styled.p`

`

const StyledInfiniteScroll = styled(InfiniteScroll)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`