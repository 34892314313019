import styled, { css } from "styled-components"
import Avatar from "../Components/User/Avatar"
import Colors from "../Utilities/Colors"
import { useUserState } from "../Context/UserContext"
import UsernameText from "../Components/User/UsernameText"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import PostsManager from "../Managers/PostsManager"
import Aux from "../Managers/AuxManager"
import Loader from "../Components/Loader"
import PlusIcon from "mdi-react/PlusIcon"
import { IconButton } from "../Components/HelperComponents"
import { useDataState } from "../Context/DataContext"
import AchievementsModal from "../Modal/AchievementsModal"

export default function LeftMenu(props) {

    const [hashtags, setHashtags] = useState([])
    const [loadingHashtags, setLoadingHashtags] = useState(false)
    const {currentUser} = useUserState()
    const {currentMenu, setCurrentMenu} = useDataState();
    const [achievementModalOpen, setAchievementModalOpen] = useState(false)

    /* useEffect(async() => {
        
        getTopHashtags()
    },[])

    const getTopHashtags = async() => {
        if(loadingHashtags) return;

        try {
            setLoadingHashtags(true)
            await PostsManager.getAllPosts("topHashtags").then((data) => {
                 if(!data.empty){
                     const hashs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                     setHashtags(hashs)
                 }
             })
            setLoadingHashtags(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingHashtags(false)
        }
    } */

    return (
        <MenuContainer>
            {/* <ProfileMenu currentUser={currentUser} /> */}
            <AchievementsModal active={achievementModalOpen} onClose={() => setAchievementModalOpen(false)}/>
            {currentUser?.uid && 
            <MenuWrapper className="flex-between ">
                <div className="flex-between">
                    <div className="flex align-center">
                    <Avatar user={currentUser} height={30}/>
 
                        {/* <NameText>{currentUser.name}</NameText> */}
                        <UsernameText user={currentUser} style={{marginLeft:5}}/>
                    </div>
                    <div className="flex align-center">
                        <p className="m-r-5">{currentUser.amount}$</p>
                        <IconButton as={Link} to="/account/balance" backgroundColor={Colors.primary} size={25} >
                            <PlusIcon color="white"/>
                        </IconButton>
                    </div>
                </div>
                <SingleMenu onClick={() => setAchievementModalOpen(true)} text="Achievement" />
            </MenuWrapper>}
            <MenuWrapper>
                <SingleMenu to="/trending" text="Trending" isActive={currentMenu === "trending"} key={"menu-trending"} />
                <SingleMenu to="/latest" text="Latest" isActive={currentMenu === "latest"} key={"menu-latest"} />
                <SingleMenu to="/top" text="Top" isActive={currentMenu === "top"} key={"menu-top"} />
            </MenuWrapper>
            {hashtags.length > 0 && 
            <MenuWrapper>
                {hashtags.map((hash, index) => {
                    return <SingleMenu key={hash.id} to={`/hashtag/${hash.hashtag}`} text={"#"+ hash.hashtag} 
                    isActive={currentMenu === hash.hashtag} />
                })}
            </MenuWrapper>}
        </MenuContainer>
    )

}

export const SingleMenu = ({to, text, Icon, isActive, onClick}) => {
    return (
        <SingleMenuLink as={!to ? "div" : undefined} to={to} onClick={onClick} >
            {Icon && <StyledIcon as={Icon} isactive={isActive}/>}
            <SingleMenuText isactive={isActive}>{text || "Menu"} </SingleMenuText>
        </SingleMenuLink>
    )
}

const SingleMenuLink = styled(Link)`
    text-decoration: none;
    //background-color: white;
    cursor: pointer;
    
    &:hover{
        background-color: ${Colors.secundaryLightest};
        border-radius: 5px;
    }

    /* ${props => props.isactive && css`

        background-color: ${Colors.primary};
        color: white;
        &:hover{
            background-color: ${Colors.primaryDarker};
        }
    `} */
`

const StyledIcon = styled.div`

`

const SingleMenuText = styled.p`
    //margin-left: 10px;
    margin-left: 5px;
    color: ${props => props.isactive ? Colors.primary : Colors.textDarkest};
    font-weight: ${props => props.isactive ? "bold" : "400"};
`

const MenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px ;
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${Colors.secundaryLightest};
    margin-top: 20px;
`

const MenuContainer = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    //border-radius: 5px;
    //width: 265px;
    //overflow-x: hidden;
    //margin: 0px 10px;

`

const NameText = styled.p`

`
