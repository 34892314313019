import { useState, useEffect } from 'react'
import Container from "../Components/Container";
import Aux from '../Managers/AuxManager';
import { useUserState } from '../Context/UserContext'
import { useAuthState } from '../Context/AuthContext'
import InputText from '../Components/InputTextMaterial';
import Button from '../Components/Button';
import AlertMessage from '../Components/AlertMessage';
import Colors from '../Utilities/Colors';
import Header from '../Components/Header';
import { useNavigate } from 'react-router-dom'

export default function ChangeEmail() {
    const [loading, setLoading] = useState(false)
    const [currentPassword, setCurrentPassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const { changeEmail, reauthenticateUser } = useAuthState()

    const validate = () => {
		const errEmail = Aux.validateEmail(email)
		if(errEmail) return errEmail;

		if (!currentPassword) return "All fields are required."

		return "";
	}

    const onSubmit = async () => {

        setError(validate())
        if (error, loading) return;


        try {
            setLoading(true);
            
            reauthenticateUser(currentPassword)
            .then(() => {
                changeEmail(email).then(() => {
                    setSuccess(true);
                })
            })
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError(Aux.UnexpectedError)
            setLoading(false);
        }

    }

    const onChangeCurrentPassword = (text) => {
        setCurrentPassword(text);
        setError("");
    }
    const onChangeEmail = (text) => {
        setEmail(text);
        setError("");
    }

    return (
        <>
            <Container>
                <Header text="Change Email" goBack/>
                <AlertMessage variant="error" text={error} />
                <AlertMessage variant="success" text={"You've sucessfully changed your email"} show={success} />
                <span className="sub-header">To change your email address please fill out the fields below.</span>
                
                <InputText type="text" value={currentPassword} title="Your Password" onChange={onChangeCurrentPassword} />
                <InputText type="email" value={email} title="New Email" onChange={onChangeEmail} />
                <Button className="w-100" text="Change Email" onClick={onSubmit} isSuccess={success} isLoading={loading} />
                
            </Container>
        </>
    )
}