import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDataState } from "../Context/DataContext";

export default function ScrollToTop(props){
    const {pathname} = useLocation()
    const [prevLocation, setPrevLocation] = useState("")
    const {setCurrentMenu} = useDataState()
    useEffect(() => {
        if(pathname !== prevLocation){
            setPrevLocation(pathname)
            const path = pathname.split('/');
            if(path?.length > 0){
                if(path[1] !== "hashtag")
                setCurrentMenu(path[1])
            }
            
            //document.getElementById("main-sidebar").scrollTo(0,0)
        }
    },[pathname]) 

    return props.children
}