import { useState, useEffect } from 'react';
import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import './Global.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContextProvider } from './Context/AuthContext';
import Navigation from './Navigation/Navigation';
import Sidebar from 'react-sidebar';
import { UserContextProvider } from './Context/UserContext';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { DataContext, DataContextProvider } from './Context/DataContext';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function App() {

	return (
		<Elements stripe={stripePromise}>
			<PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_API_KEY }}>
				<DataContextProvider>
					<AuthContextProvider >
						<UserContextProvider>

							<Navigation />

						</UserContextProvider>
					</AuthContextProvider>
				</DataContextProvider>
			</PayPalScriptProvider>
		</Elements>
	);
}

export default App;
