
import { useEffect, useState } from "react";
import styled from "styled-components"
import Avatar from "../Components/User/Avatar";
import Button from "../Components/Button";
import Loader from "../Components/Loader";
import UsernameText from "../Components/User/UsernameText";
import Aux from "../Managers/AuxManager";
import SendRequestModal from "../Modal/SendRequestModal";
import Colors from "../Utilities/Colors";
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import YoutubeIcon from 'mdi-react/YoutubeIcon'
import SnapchatIcon from 'mdi-react/SnapchatIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import EmptyContainer from "../Components/EmptyContainer";
import { useUserState } from "../Context/UserContext";
import { useNavigate} from "react-router-dom"

export default function ProfileHeader(props) {

    const [sendMessageActivated, setSendMessageActivated] = useState(false)
    const {currentUser} = useUserState()
    const navigate = useNavigate()
    const SocialLink = ({ username, icon, link }) => {
        let Icon = icon

        return <StyledSocialLink href={link} target="_blank" rel="noopener noreferrer" className="m-r-10">
            <Icon />
            <p style={{marginLeft:5}}>{username}</p>
        </StyledSocialLink>
    }

    const SpentSection = ({ text, amount }) => {
        return <SectionSpentContainer>
            <p>{text}</p>
            <h2 >{amount}</h2>
        </SectionSpentContainer>
    }

    const Socials = () => {
        return <div className="flex-around wrap">
            {props.user?.social?.instagram && <SocialLink username={props.user.social.instagram} icon={InstagramIcon} link={`www.instagram.com/${props.user.social.instagram}`} />}
            {props.user?.social?.facebook && <SocialLink username={props.user.social.facebook} icon={FacebookIcon} link={`www.facebook.com/${props.user.social.facebook}`} />}
            {props.user?.social?.twitter && <SocialLink username={props.user.social.twitter} icon={TwitterIcon} link={`www.twitter.com/${props.user.social.twitter}`} />}
            {props.user?.social?.youtube && <SocialLink username={props.user.social.youtube} icon={YoutubeIcon} link={`www.youtube.com/${props.user.social.youtube}`} />}
            {props.user?.social?.snapchat && <SocialLink username={props.user.social.snapchat} icon={SnapchatIcon} link={`www.snapchat.com/${props.user.social.snapchat}`} />}
        </div>
    }

    return (

        <StyledContainer>
            <SendRequestModal user={props.user} active={sendMessageActivated} onClose={() => setSendMessageActivated(false)} />
            {props.loading ?
                <EmptyContainer isLoading/>
                    
                : !props.user ?
                    <EmptyContainer text="No user found."/>
                    :
                    <>

                        <div className="flex m-10 ">
                            <Avatar user={props.user} />
                            <div className="flex-start column m-l-10">
                                <div className="flex column">
                                    {props.user.username && <UsernameText user={props.user} isBlack/>}
                                    {props.user.name && <StyledText>{props.user.name}</StyledText>}
                                    {props.user.bio && <StyledText>{props.user.bio}</StyledText>}
                                    {props.user.website && <StyledLink
                                        href={"http://" + this.props.user.website}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {props.user.website}
                                    </StyledLink>}
                                </div>
                                <Socials/>
                            </div>
                        </div>

                        <div className="m-t-10">
                            {currentUser?.uid === props.user.uid  ? 
                            <Button text="Edit Profile" size={"medium"} onClick={() => navigate("/account/edit-profile")}/>
                            :
                            <Button text="Send Request" size={"medium"} onClick={() => setSendMessageActivated(true)} />}
                        </div>
                    </>
            }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

`

const SectionSpentContainer = styled.div`
    display: flex;
    border-radius: 10px;
`

const StyledText = styled.p`

`
const StyledLink = styled.a`
    margin-left: 10px;
`
const StyledSocialLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    & span{
        margin-left: 5px;
        color: "#555" 
    }
`