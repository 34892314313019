import styled from "styled-components"
import Avatar from "../Components/User/Avatar"
import Colors from "../Utilities/Colors"
import { useUserState } from "../Context/UserContext"
import UsernameText from "../Components/User/UsernameText"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import PostsManager from "../Managers/PostsManager"
import Aux from "../Managers/AuxManager"
import Loader from "../Components/Loader"
import { SingleMenu } from "./LeftMenu"

export default function RightMenu(props) {

    const [topUsers, setTopUsers] = useState([])
    const [loadingTopUsers, setLoadingTopUsers] = useState(false)
    const { currentUser } = useUserState()

    /* useEffect(async () => {
           
        getTopUsers()
    }, [])

    const getTopUsers = async (isMounted) => {
        if (loadingTopUsers) return;

        try {
            setLoadingTopUsers(true)
            PostsManager.getAllPosts("topUsers").then((data) => {
                if (!data.empty) {
                    const users = data.docs.map((doc) => ({ username: doc.data().username, amountSpent: doc.data().amountSpent, avatar: doc.data().avatar , id: doc.id }))
                    setTopUsers(users)
                }
            })
            setLoadingTopUsers(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingTopUsers(false)
        }
    } */

    const SingleUser = ({ user }) => {
        return (
            <SingleUserContainer>
                <Avatar user={user} height={35} />
                <div className="flex overflow-hidden align-center">
                    <UsernameText user={user} style={{marginLeft:10}} />
                    <StyledAmountText>{user.amountSpent}$</StyledAmountText>
                </div>
            </SingleUserContainer>
        )
    }


    const TopUsersMenu = () => {
        if (topUsers.length > 0)
            return (
                <MenuWrapper>

                    {topUsers.map((user, index) => {
                        //return <SingleMenu key={user.id} to={`/user/${user.id}`} text={user.username}/>
                        return <SingleUser key={user.id} user={user} />
                    })}

                </MenuWrapper>
            )
        else return null
    }

    return (
        <MenuContainer>
            <TopUsersMenu />
        </MenuContainer>
    )

}




const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
`
const SingleUserContainer = styled.div`
    display: flex;
    align-items: center;
`

const StyledAmountText = styled.p`
    font-weight: bold;
    color: black;
    margin-left: 10px;
`

const MenuContainer = styled.div`

    
    //border-radius: 5px;
    //width: 265px;
    //overflow-x: hidden;
    //margin: 0px 10px;

`