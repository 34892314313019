import { useEffect, useState } from 'react'
import { auth } from '../firebase'
import { useNavigate, Link } from 'react-router-dom'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { useAuthState } from '../Context/AuthContext'
import Header from '../Components/Header'
import AlertMessage from '../Components/AlertMessage'
import Container from '../Components/Container'
import InputText from '../Components/InputTextMaterial'
import Button from '../Components/Button'
import Colors from '../Utilities/Colors'
import Separator from '../Components/Separator'
import FacebookIcon from 'mdi-react/FacebookIcon'
import GoogleIcon from 'mdi-react/GoogleIcon'
import Aux from '../Managers/AuxManager'
import { useUserState } from '../Context/UserContext'
import DataManager from '../Managers/DataManager'

export default function Register() {

	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState('')
	const [waitingForUser, setWaitingForUser] = useState(false);
	const navigate = useNavigate();
	const { registerUser } = useAuthState();
	const { createUser, setUserId, currentUser, userState } = useUserState();
	//const {setTimeActive} = useAuthValue()


	useEffect(() => {
		if (waitingForUser) {
			if (userState == "success") {
				const pendingLink = localStorage.getItem("pendingLink")
                if(pendingLink){
                    navigate(pendingLink)
                }else if (currentUser?.email) {
					navigate("/") //found an user
				} else {
					navigate("/add-username") //doesnt have an user yet
				}
				setLoading(false)
				setWaitingForUser(false);
			} else if (userState == "error") {
				setError("An unexpected error has occurred")
				setLoading(false)
				setWaitingForUser(false);
			}
		}
	}, [currentUser, userState, waitingForUser])

	const validate = () => {

		const errUsername = Aux.validateUsername(username)
		if (errUsername) return errUsername;

		const errEmail = Aux.validateEmail(email)
		if (errEmail) return errEmail;

		const errPassword = Aux.validatePassword(password)
		if (errPassword) return errPassword;

		if (!confirmPassword) return "All fields are required."
		if (password !== confirmPassword) return 'Passwords does not match'

		return "";
	}

	const onSubmit = async (e) => {

		e.preventDefault()
		if (loading) return

		const validateError = validate()
		if (validateError) {
			setError(validateError)
			return;
		}
		try {
			setLoading(true)

			const freeUsername = await DataManager.checkIfUsernameExists(username) //check if username exists
			if (!freeUsername) {
				setError("Username already exists.")
				setLoading(false)
				return;
			}
			const cred = await registerUser(email, password) // create authenticated user
			if (cred) {
				await DataManager.createUser(cred.user.uid, username, email, cred.providerId) // create user in the database
					.then(() => {
						setWaitingForUser(true); //if created user wait to connect to database
					})
			}
		} catch (error) {
			switch (error.code) {
				case 'auth/email-already-in-use':
					setError("Email already in use.")
					break;
				case 'auth/weak-password':
					setError("Weak password.")
					break;
				case 'auth/network-request-failed':
					setError("No internet connection.")
					break;
				default:
					setError("An unexpected error has occurred.")
					break;
			}
			Aux.handleError(error)
			setLoading(false)
		}


	}
	const onChangeUsername = (username) => {
		setUsername(username)
		setError("")
	}
	const onChangeEmail = (email) => {
		setEmail(email)
		setError("")
	}
	const onChangePassword = (password) => {
		setPassword(password)
		setError("")
	}
	const onChangeConfirmPassword = (password) => {
		setConfirmPassword(password)
		setError("")
	}
	const goToLogin = () => {
		navigate("/login")
	}

	return (
		<Container>
			<Header text="Register" />
			<span className="sub-header">create an account and get 10$ free</span>
			<AlertMessage variant="error" text={error} />
			<form onSubmit={onSubmit} name='registration_form'>
				<InputText type="text" value={username} title="Username" onChange={onChangeUsername} />
				<InputText type="email" value={email} title="Email" onChange={onChangeEmail} />
				<InputText type="password" value={password} title="Password" onChange={onChangePassword} />
				<InputText type="password" value={confirmPassword} title="Confirm Password" onChange={onChangeConfirmPassword} />
				<Button className="w-100" text="Register" type="submit" isLoading={loading} style={{ backgroundColor: Colors.register }} />
			</form>
			<Separator text="or" style={{ marginTop: 30, marginBottom: 10 }} />
			<div className="flex-center" >
				<Button icon={FacebookIcon} variant="outline" type="submit" style={{ width: "30%", marginRight: 10 }} 
				color={Colors.facebook} />
				<Button icon={GoogleIcon} variant="outline" type="submit" style={{ width: "30%", marginLeft: 10 }} 
				color={Colors.google} />
			</div>
			<div className="flex-start align-center" style={{ marginTop: 30 }}>
				<p>
					Already have an account?
                    </p>
				<Button size="small" text="Login" style={{ marginLeft: 10 }} onClick={goToLogin} />
			</div>
		</Container>
	)
}
