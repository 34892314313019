import React, { createRef, forwardRef, useEffect, useState } from 'react';
import { useUserState } from '../Context/UserContext'
import BellIcon from "mdi-react/BellIcon"
import BellOutlineIcon from "mdi-react/BellOutlineIcon"
import MessagesOutlineIcon from "mdi-react/MessageOutlineIcon"
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon"
import MessagesFilledIcon from "mdi-react/MessageIcon"
import styled from "styled-components"
import ShowNotifications from '../Notifications/ShowNotifications';
import Popup from 'reactjs-popup';
import Avatar from '../Components/User/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import Colors from '../Utilities/Colors';
import MenuIcon from "mdi-react/MenuIcon"
import ArrowDown from "mdi-react/ChevronDownIcon"
import DataManager from '../Managers/DataManager';
import Aux from '../Managers/AuxManager';
import DailyRewardsModal from '../Modal/DailyRewardsModal';
import { useDataState } from "../Context/DataContext"
import DialogModal from '../Modal/DialogModal';
import Button from '../Components/Button';
import Loader from '../Components/Loader';
import MessageIcon from '../Assets/MessageIcon';
import NotificationIcon from '../Assets/NotificationsIcon';
import RichlessIcon from '../Assets/RichlessIcon';
import SearchModal from '../Modal/SearchModal';
import SearchIcon from "mdi-react/SearchIcon"
import CongratulationsModal from '../Modal/CongratulationsModal';
/* import { useLocation } from 'react-router-dom' */

export default function NavBar(props) {

    const { currentUser, messageState, userState } = useUserState();
    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [openDailyModal, setOpenDailyModal] = useState(false)
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
    const [openSearchModal, setOpenSearchModal] = useState(false)
    const navigate = useNavigate()
    const { hasNewNotification, hasNewMessage, hasNewDailyReward, /* currentMenu, setCurrentMenu  */} = useUserState();

 
    /* useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener("resize", resizeHandler)
    },[]) */
    

    const ProfileMenuItem = (text) => {
        return <MenuItemLink>
            <p>{text || ""}</p>
        </MenuItemLink>
    }


    const Logout = () => {

    }

    const ProfileMenu = () => {
        return <ProfilePopup
            trigger={<div><MyProfileIcon/></div>}
            position="bottom right"
            closeOnDocumentClick
            arrow={true}
        >
            <MenuContainer>
                <MenuItemLink to="/account/profile"><p>Profile</p></MenuItemLink>
                <MenuItemLink to="/account/edit"><p>Edit Profile</p></MenuItemLink>
                <MenuItemLink to="/account/invite-friends"><p>Invite Friends</p></MenuItemLink>
                <MenuItemLink to="/account/balance"><p>Add Balance</p></MenuItemLink>
                <MenuItemLink as={"div"} onClick={() => setOpenDailyModal(true)}><p>{hasNewDailyReward ? "HAS DAILY REWARD" : "Daily Rewards"}</p></MenuItemLink>
                <MenuItemLink to="/settings"><p>Settings</p></MenuItemLink>
                {currentUser?.role === "admin" && <MenuItemLink to="/admin"><p>Admin Dev</p></MenuItemLink>}
                <MenuItemLink as={"div"} onClick={() => {setOpenLogoutDialog(true); console.log("On Logout")}}><p>Logout</p> </MenuItemLink>
            </MenuContainer>
        </ProfilePopup>
    }

    const MyProfileIcon = () => {
        return <div className='flex m-r-10'>
        <StyledAvatar height={30} user={currentUser} />
            {/*<ArrowDown/> */}
        </div>
    }

    const NavIcon = ({Icon, hasBadge, onClick}) => (
        <StyledIconContainer style={{marginRight:10}} onClick={onClick}>
            {Icon && <Icon />}
            {hasBadge && <StyledSmallNotificationDot />}
        </StyledIconContainer>
    )


    const MyNotificationIcon = () => {

        return <StyledIconContainer style={{marginRight:10}}>
            <StyledBellIcon />
            {hasNewNotification && <StyledSmallNotificationDot />}
        </StyledIconContainer>
    }

    const MyMessageIcon = () => {
        return <StyledIconContainer style={{marginRight:10}}>
            <StyledMessageIcon onClick={() => navigate("/messages")} />
            {hasNewMessage && <StyledSmallNotificationDot />}
        </StyledIconContainer>
    }

    return (

        <MainNavBar>
            {/* <DailyRewardsModal active={openDailyModal} onClose={() => setOpenDailyModal(false)} /> */}
            <CongratulationsModal active={openDailyModal} onClose={() => setOpenDailyModal(false)} amount={10}/>
            <DialogModal onConfirm={Logout} active={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)} />
            {/* <SearchModal active={openSearchModal} onClose={() => setOpenSearchModal(false)}/> */}
            <TitleContainer>
                <StyledMenuIcon onClick={props.toogleSideBar} />
                <div className='flex align-center'>
                    <RichlessIcon style={{width:35, marginLeft:5}}/>
                    <AppTitle to="/">Richless</AppTitle>
                </div>
            </TitleContainer>
            <div className='flex-center align-center'>
                {userState === "loading" ?
                    <Loader style={{marginRight:10}}/>
                    : 
                    currentUser?.uid ?
                        <>
                            {/* <StyledCreateIcon onClick={() => navigate("/create")} /> */}
                            <SearchModal trigger={<div><NavIcon Icon={SearchIcon} /></div>}/>
                            <NavIcon Icon={MessagesOutlineIcon} hasBadge={hasNewMessage} onClick={() => navigate("/messages")}/>

                            <ShowNotifications trigger={<div><NavIcon Icon={BellOutlineIcon} hasBadge={hasNewNotification}/></div>} isMobile={isMobile} />
                            <ProfileMenu />
                            <Button text="Create" onClick={() => navigate("/create")} size="medium" style={{ marginRight: 20 }} />
                            
                        </>
                        : <>
                            {/* <Link to="/login">Login </Link> */}
                            <Button text="Register" variant="outline" onClick={() => navigate("/register")} size="medium" style={{ marginRight: 10 }} />
                            <Button text="Login" onClick={() => navigate("/login")} size="medium" style={{ marginRight: 10 }} />
                        </>}
            </div>
        </MainNavBar>
    )
}

const MainNavBar = styled.nav`
    position: sticky;
    top:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 100%;
    height: 60px;
    background-color: white;
    box-sizing: border-box;
    z-index: 99;
    border-bottom: 1px solid #e9e9e9;
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledMenuIcon = styled(MenuIcon)`
    @media (min-width: 800px) {
        display: none;
    }

    @media (max-width:800px){
        display: flex;
        margin-left:10px;
    }
`


const AppTitle = styled(Link)`
    text-decoration: none;
    color:black;
    cursor: pointer;
    margin-left:5px;
    font-size: 1.35rem;
`
const MenuItemLink = styled(Link)`
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    height: 30px;
    border-bottom: 1px solid #ccc;
    text-decoration: none;
    &:hover{
        background-color: #f9f9f9;
    }
`

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 200px;
    box-shadow: 0 0 3px rgba(0,0,0,.16);
`

const StyledIconContainer = styled.div`
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    height: 25px;
    width: 25px;
`

const StyledSmallNotificationDot = styled.div`
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${Colors.error};
    position: absolute;
    top: 0;
    right: 0;

`

const StyledBellIcon = styled(BellOutlineIcon)`
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
`
const StyledMessageIcon = styled(MessagesOutlineIcon)`
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
`
const StyledCreateIcon = styled(PlusCircleOutlineIcon)`
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
`

const StyledAvatar = styled(Avatar)`
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
`

const ProfilePopup = styled(Popup)`
    &-overlay { 
        background-color: transparent;
    }  
    &-content {    
        max-width: 300;
        border: none;
        padding: 0px;
    }
`