import styled from "styled-components"
import Button from "../Components/Button"
import Colors from "../Utilities/Colors";
import Popup from 'reactjs-popup';
import SimpleInput from "../Components/SimpleInput";
import { useState } from "react";
import SearchIcon from "mdi-react/SearchIcon"
import Avatar from "../Components/User/Avatar";
import UsernameText from "../Components/User/UsernameText";
import { Link, useNavigate } from "react-router-dom";
import DataManager from "../Managers/DataManager";
import Aux from "../Managers/AuxManager";
import Loader from "../Components/Loader";
import ShowList from "../Components/ShowList";
import EmptyContainer from "../Components/EmptyContainer";

export default function SearchModal(props) {

    const [inputText, setInputText] = useState("")
    const [users, setUsers] = useState([])
    const [hashtags, setHashtags] = useState([])
    const [started, setStarted] = useState(false)
    const [loadingUser, setLoadingUser] = useState(false)
    const [loadingHashtag, setLoadingHashtag] = useState(false)

    const navigate = useNavigate();

    const searchUsers = async (text) => {
        if(loadingUser) return;
        try {
            setLoadingUser(true)
            const data = await DataManager.searchUser(text, 5)

            if (!data?.empty) {
                const newData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setUsers([...newData])
                
            } 
            setLoadingUser(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false)
        }
    }

    const searchHashtag = async (text) => {
        if(loadingHashtag) return;
        try {
            setLoadingHashtag(true)
            const data = await DataManager.searchHashtag(text, 5)

            if (!data?.empty) {
                const newData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setHashtags([...newData])
                //console.log(newData)
            } 
            setLoadingHashtag(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingHashtag(false)
        }
    }

    
    const onSearch = () => {
        if(inputText){
            setStarted(true)
            setHashtags([])
            setUsers([])
            searchHashtag(inputText)
            searchUsers(inputText)
        }
    }

    const Trigger = () => {
        if(props.trigger) return props.trigger
        else return null
    } 

    const RenderUser = ({item}) => (
        <SingleLink to={`/u/${item.id}`}>
            <Avatar user={item} height={30}/>
            <UsernameText user={item} style={{marginLeft:10}}/>
        </SingleLink>
    )

    const RenderHashtag = ({item}) => (
        <SingleLink to={`/hashtag/${item.hashtag}`}>
            <HashtagText>#{item.hashtag}</HashtagText>
        </SingleLink> 
    )
    
    const SingleTitle = ({title, onViewAll}) => (
        <div className="flex-between align-center">
            <SingleTitleText>{title}</SingleTitleText>
            <SingleViewAllText onClick={onViewAll}>View all</SingleViewAllText>
        </div>
    )

    return (
        <StyledPopup
            trigger={<div><Trigger/></div>}
            //open={active}
            onClose={props.onClose}
            position={"bottom right"}
            closeOnDocumentClick >
            {close =>
                <MainContainer>
                    <InputContainer>
                        <SimpleInput value={inputText} onChange={setInputText} placeholder={"Search..."} style={{width:"100%"}} onKeyPress={e => {if(e.key === "Enter") onSearch()}}/>
                        <IconSearch onClick={onSearch}/>
                    </InputContainer>
                    <SearchContainer>
                        {   
                            !started ?
                            null
                            :
                            loadingUser || loadingHashtag?
                            <EmptyContainer isLoading/>
                            : 
                            <div className="flex column">
                            <SingleTitle title={"Users"} onViewAll={() => navigate("/search", {isUsers: true, input: inputText})} to={`/search?user=${inputText || ""}`}/>
                            <ShowList
                                list={users}
                                hasMore={false}
                                Render={RenderUser}
                                emptyText={"No users found."}
                            />
                            <SingleTitle title={"Hashtags"} onViewAll={() => navigate("/search", {isUsers: true, input: inputText})} to={`/search?hashtag=${inputText || ""}`}/>
                            {/* <SingleTitleText>Hashtags</SingleTitleText> */}
                            <ShowList
                            list={hashtags}
                            hasMore={false}
                            Render={RenderHashtag}
                            emptyText={"No hashtags found."}
                            />
                        </div>
                        }
                    </SearchContainer>
                </MainContainer>
            }
        </StyledPopup>
    )
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 
        background-color: transparent;
    }  
    // use your custom style for ".popup-content"  
    &-content {    
        /* display: flex;
        max-width: 250px;
        flex-direction: column;
        padding: 40px;
        align-items: center;
        background-color: white;
        border-radius: 5px; */
        background-color: white;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 300px;
        //min-height: 100px;
        max-height: ${props => props.isMobile ? "80%" : "400px"};
        border-radius: 10px;
        //border: 2px #ccc solid;
        box-shadow: 0 0 3px rgba(0,0,0,.16);
    }
`;

const MainContainer = styled.div`

`
const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SingleLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px 10px;
    &:hover {
        background-color: ${Colors.secundaryLightest};
        border-radius: 5px;
    }
`
const IconSearch = styled(SearchIcon)`
    margin-left: 10px;
    cursor:pointer;
    padding:2px;
    &:hover{
        background-color: #ccc;
        border-radius: 50%;
    }
`


const SingleTitleText = styled.p`
    color: #bbb;
    //font-size: 0.8rem;
`
const SingleViewAllText = styled.div`
    text-decoration: none;
    color: ${Colors.link};
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
`
const HashtagText = styled.p`

`

const SearchContainer = styled.div`

`