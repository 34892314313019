import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import styled from "styled-components"
import Container from "../Components/Container"
import Header from "../Components/Header"
import Loader from "../Components/Loader"
import { useUserState } from "../Context/UserContext"
import MessagesManager from "../Managers/MessagesManager"
import Aux from "../Managers/AuxManager"
import MessageCard from "./MessageCard"
import CheckIfLogged from "../Checks/CheckIfLogged"
import ShowList from "../Components/ShowList"

export default function ShowMessages(props) {

    const [loading, setLoading] = useState(false)
    const [allMessages, setAllMessages] = useState([])
    const [isFinished, setIsFinished] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const { currentUser, messages, getMoreMessages, messageState, canGetMoreMessages} = useUserState()

    const getMessages = async () => {

        //console.log(notifications)
        //if(messages.lenght <= 0) return;
        //if(messageState === "loading") return
        if (!canGetMoreMessages) return 
        await getMoreMessages();

        /* if (messageState === "finished")
            setIsFinished(true);
        else if (messageState === "loading")
            setLoading(true);
        else if (messageState === "error")
            Aux.handleError("notification error") */
    }

    /* useEffect(() => {
        if (messages.length > 0) {
            setAllMessages(messages)
            //console.log(allMessages)
        }

    }, [messages]) */

    /* const getMoreMessages = async () => {
        if (loading || !currentUser?.uid) return
        console.log("getting messages...")
        try {
            setLoading(true)
            const data = await MessagesManager.getMoreMessages(currentUser.uid, lastVisible)
            if (!data.empty) {
                const newMessages = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

                if (newMessages.length < Aux.LIMIT_MESSAGES) {
                    setIsFinished(true);
                }

                setAllMessages([...allMessages, ...newMessages])
                setLastVisible(data.docs[data.docs.length - 1])
                setLoading(false)
            } else {
                setIsFinished(true);
                setLoading(false)
            }
        } catch (error) {
            Aux.handleError(error)
            setLoading(false)
        }
    } */

    return (
        <Container>
            <Header text="Messages" />
            {currentUser.uid ?
                    <ShowList
                        list={messages}
                        loadMore={getMessages}
                        hasMore={canGetMoreMessages}
                        useWindow={false}
                        emptyText={"No messages yet."}
                        endText={"There's no more messages."}
                        Render={MessageCard}
                    />
                :
                <CheckIfLogged />}
        </Container>
    )
}


