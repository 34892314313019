import styled from "styled-components"
import Loader from "./Loader"

export default function EmptyContainer(props) {
    return (
        <EmptyMain style={props.style}>
            {props.isLoading ? <Loader />
                :
                props.text ?
                    <EmptyText>{props.text}</EmptyText>
                    :
                    props.children}
        </EmptyMain>
    )
}

const EmptyMain = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`

const EmptyText = styled.p`

`