import { useState, useEffect } from 'react'
import Container from "../Components/Container";
import Aux from '../Managers/AuxManager';
import { useUserState } from '../Context/UserContext'
import { useAuthState } from '../Context/AuthContext'
import InputText from '../Components/InputTextMaterial';
import Button from '../Components/Button';
import AlertMessage from '../Components/AlertMessage';
import Colors from '../Utilities/Colors';
import Header from '../Components/Header';
import { useNavigate } from 'react-router-dom'
import DataManager from '../Managers/DataManager';

export default function AddUsername() {
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState("")
    const [error, setError] = useState("")
    const [waitingForUser, setWaitingForUser] = useState(false);
    const { currentUser, userState } = useUserState()
    const { authUser } = useAuthState()
    const navigate = useNavigate();
    
    useEffect(() => {
        if(waitingForUser){
            if(userState == "success"){
                const pendingLink = localStorage.getItem("pendingLink")
                if(pendingLink){
                    navigate(pendingLink)
                }else if(currentUser){
                    navigate("/") //if found user go home
                }
                setLoading(false)
                setWaitingForUser(false);
            }else if(userState == "error"){
                setError("An unexpected error has occurred")
                setLoading(false)
                setWaitingForUser(false);
            }
        }
    }, [currentUser, userState, waitingForUser])

    //6c1516ae6d00d625d13f

    const onSubmit = async () => {
        if (loading) return;

        try {
             const isValidUsername = Aux.validateUsername(username) 
            if (isValidUsername) {
                setError(isValidUsername)
                setLoading(false)
                return;
            }

            const freeUsername = await DataManager.checkIfUsernameExists(username) //check if username exists
            if (!freeUsername) {
                setError("Username already exists.")
                setLoading(false)
                return;
            }

            await DataManager.createUser(authUser.uid, username, authUser.email, authUser.providerId) // create user in the database
                .then(function () {
                    setWaitingForUser(true); //if created user wait for connecting to database
                })
        } catch (error) {
            setError("An unexpected error has occurred.")
            Aux.handleError(error)
            setLoading(false)
        }

    }

    const onChangeUsername = (text) => {
        let username = Aux.removeSpecialCharactersFromText(text)
        setUsername(username);
    }

    return (
        <>
            <Container>
                <Header text="Add Username" />
                <span className="sub-header">To complete registration add a valid username below.</span>
                <AlertMessage variant="error" text={error} />

                <InputText type="text" value={username} title="Username" onChange={onChangeUsername} />
                <Button className="w-100" text="Complete Registration" onClick={onSubmit} isLoading={loading} />
                <div className='flex-center align-center'>
                    <p>or</p>
                    <Button text={"Cancel"} onClick={() => navigate("/")}/>
                </div>
                {/* <p>Cancel</p> */}
            </Container>
        </>
    )
}