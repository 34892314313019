import Popup from 'reactjs-popup';
import styled from 'styled-components';
import Header from '../Components/Header';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { IconButton } from '../Components/HelperComponents';
import { useEffect, useState } from 'react';
import { useUserState } from '../Context/UserContext';
import CheckIfBalance from '../Checks/CheckIfBalance';
import Aux from '../Managers/AuxManager';
import MessagesManager from '../Managers/MessagesManager';
import Button from '../Components/Button';
import AddAmount from '../Utilities/AddAmount';
import AlertMessage from '../Components/AlertMessage';
import Avatar from '../Components/User/Avatar';
import UsernameText from '../Components/User/UsernameText';
import InputTextResize from '../Components/InputTextResize';
import Colors from '../Utilities/Colors';

export default function SendRequestModal({ user, active, onClose }) {

    const [text, setText] = useState("")
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(0)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isMessage, setIsMessage] = useState(false)
    const [error, setError] = useState("")
    const { currentUser } = useUserState()

    const validate = () => {
        if (text && (!amount || amount <= 0)) return "You have to add amount to send message. Mininum 1$."
        else if (!user) return "No user selected"
        else if (!currentUser) return "You're not logged in"
        return ""
    }
    const sendRequest = async () => {
        if (loading || isSuccess) return
        const isError = validate()
        if (isError) {
            setError(isError)
            return;
        }
        try {
            setLoading(true)
            const data = await MessagesManager.checkIfAlreadySentRequest(currentUser.uid, user.uid)
            if (data.empty) {
                await MessagesManager.createRequestNotification(currentUser.uid, user.uid, text, amount).then(() => {
                    setIsSuccess(true);
                })
            } else {
                setError("You've already sent a request to this user")
            }
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError("An unexpected error has occurred")
            setLoading(false)
        }
    }



    return (
        <StyledPopup
            //trigger={<button className="button"> Open Modal </button>}
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close =>
                <Container>
                    <Header text="Send Request" onClose={() => close()} />
                    <div className='flex-center align-center column m-b-10'>
                        <Avatar user={user} />
                        <p>Do you want to chat with?</p>
                        <UsernameText user={user} isBlack />
                        <Button text="Send Request" onClick={sendRequest} isLoading={loading} size="medium" isSuccess={isSuccess} style={{ margin: "auto", marginTop:10 }} />
                    </div>

                    <AlertMessage variant="error" text={error} />
                    {isMessage ? <>
                        <AddAmount amount={amount} max={currentUser?.amount} setAmount={setAmount} />
                        <InputTextResize value={text} onChange={setText} placeholder={"write a message..."} />
                        <Button text="Send Message" onClick={sendRequest} isLoading={loading} isSuccess={isSuccess} style={{ margin: "auto", width:"70%" }} />
                    </>
                        :
                        <div className='flex-center align-center'>
                            <p>Or</p>
                            <OpenMessageContainer onClick={() => setIsMessage(true)}>
                                <p>Write a message...</p>
                            </OpenMessageContainer>
                        </div>
                    }

                </Container>
            }
        </StyledPopup>);
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 
        
    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 300px;
        border-radius: 10px;
        background-color: white;
    }
`;

const Container = styled.div`
    margin: 10px 20px;
`
const OpenMessageContainer = styled.div`
    padding: 10px 20px;
    background-color: ${Colors.secundaryLightest};
    cursor: pointer;
    p {
        color: ${Colors.secundary};
    }
`