import Popup from 'reactjs-popup';
import styled from 'styled-components';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { IconButton } from '../Components/HelperComponents';
import { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Button from '../Components/Button';
import CongratulationsImage from '../Assets/CongratulationsImage';

export default function CongratulationsModal(props) {


    useEffect(() => {

    }, [])

    
    return (
        <StyledPopup
            //trigger={<button className="button"> Open Modal </button>}
            open={props.active}
            onClose={props.onClose}
            modal
            closeOnDocumentClick >
            {close => 
                <Container>
                    {/* <Header text={"Congratulations!"} onClose={() => close}/> */}
                    <CongratulationsText>{props.title || "Congratulations!"}</CongratulationsText>
                    <CongratulationsImage style={{width:200, marginTop:15, marginBottom:15}}/>
                    <DetailsText>{props.text || "You've just earned " }</DetailsText>
                    {props.amount && <AmountText>{props.amount }$</AmountText>}
                    {/* <IconButton><CloseIcon onClick={close} /></IconButton> */}
                    <Button text={"Close"} size="medium" onClick={() => close()} style={{width:"40%", marginTop:15}}/>
                </Container>
            }
        </StyledPopup>);
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        min-width: 300px;
        border-radius: 10px;
        background-color: white;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
`;

const CongratulationsText = styled.h1`
    //margin: auto;
`

const DetailsText = styled.h2`

`
const AmountText = styled.h1`

`


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
`


