import { useEffect, useState} from 'react'
import styled from "styled-components"
import Loader from './Loader'
import InfiniteScroll from 'react-infinite-scroller';
import PostsManager from '../Managers/PostsManager';
import Aux from '../Managers/AuxManager';
import DataManager from '../Managers/DataManager';

export default function ShowList({type, uid, hashtag, limit, isLoading = false, list = null, loadMore = null, hasMore = null, Render, parent, endText, emptyText, useWindow, isReverse, style, renderProps, addItem, hide}) {

    const [data, setData] = useState([])
    const [currentType, setCurrentType] = useState(type)
    const [loading, setLoading] = useState(isLoading)
    const [currentHashtag, setCurrentHashtag] = useState(hashtag)
    const [isFinished, setIsFinished] = useState(false)
    const [lastVisible, setLastVisible] = useState(null)
    const [error, setError] = useState("")



    useEffect(() => {
        if(type !== currentType){
            setCurrentType(type)
            /* getData(true, type) */
            console.log("new type:" + type)
        }
    }, [type]) 

    /* useEffect(() => {
        if(hashtag !== currentHashtag){
            setCurrentHashtag(hashtag)
            getData(true, currentType, hashtag)
        }
    }, [hashtag])  */
 
    useEffect(() => {
        if(addItem)
            setData([...addItem, ...data,])
    }, [addItem])

    const getData = async ( isStart, mytype,) => {
        /* var newType = currentType;
        var newLastVisible = lastVisible;
        var oldData = data;
        var newLoading = loading;
        var newHashtag = currentHashtag;
        if(isStart){
            oldData = []
            newType = mytype
            newLoading = false
            newLastVisible = null;
            newHashtag = hashtag;
            setData([])
            setLoading(false)
            setIsFinished(false)
            setLastVisible(null)
        } */
        if (loading) return;
        try {
            setLoading(true);
            //console.log("is getting posts from: " + currentType + "hashtag :" + hashtag)
            const myData = await PostsManager.getAllPosts(currentType , lastVisible, uid, hashtag, limit)
            if (!myData.empty) {
                var newData = null

                switch(currentType){
                    case "dummyUsers": 
                        newData = myData.docs.map((doc) => ({ ...doc.data(), id: doc.id , uid: doc.id }))
                        break;
                    case "latest":
                    case "top":
                    case "trending":
                        newData = myData.docs.map((doc) => ({ ...doc.data(), id: doc.id , uid: doc.id }))
                        break;
                    default:
                        newData = myData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                        break;
                }
                
               /*  if(currentType === "dummyUsers"){
                    newData = myData.docs.map((doc) => ({ ...doc.data(), id: doc.id , uid: doc.id }))
                }else{
                    newData = myData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                } */
                
                    
                if (newData.length < (Aux.LIMIT)) {
                    setIsFinished(true);
                }

                setData([...data, ...newData])
                setLastVisible(myData.docs[myData.docs.length - 1])
                setLoading(false)
                //console.log(newPosts)
            }else{
                setIsFinished(true);
                setLoading(false)
            }
        } catch (error) {
            setError(error)
            Aux.handleError(error)
            setLoading(false);
        }
    }

    if(hide)
        return null
    return(
            <StyledInfiniteScroll
                    
                    loadMore={loadMore ? loadMore : getData}
                    hasMore={hasMore !== null ? hasMore : !isFinished}
                    useWindow={useWindow || false}
                    getScrollParent={parent || null}
                    isReverse={isReverse || false}
                    loader={<div className='m-auto m-t-10' key={"data-loader"}><Loader /></div>}
                    style={style}
                    $ishide={hide}
                >
                    
                    {(list || data).length <= 0 && emptyText && !loading? <EmptyText>{emptyText}</EmptyText> :
                    (list || data).map((item, index) => {
                        return <Render item={item} {...renderProps} key={index + item.id} />
                    })}
                    {(hasMore !== null ? !hasMore : isFinished) && (list || data).length > 0 && endText && <EmptyText>{endText}</EmptyText>}
            </StyledInfiniteScroll>          
    )
}

const StyledInfiniteScroll = styled(InfiniteScroll)`
    display: ${props => props.$ishide ? "none" : "flex"};
    //justify-content: center;
    //align-items: center;
    flex-direction: column;
`

const EmptyText = styled.p`
    display: flex;
    align-self: center;
    margin-top: 20px;
`