import React from "react";

export default function CreditCardMinimal({style}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 637.12 459.98"
      style={style}
    >
      <path
        fill="#3e8c9e"
        d="M552.83 308.56L118 440.85a24.58 24.58 0 01-30.68-16.39l-69.1-227.24a24.58 24.58 0 0116.38-30.69L469.44 34.25a24.58 24.58 0 0130.69 16.38l69.09 227.24a24.52 24.52 0 01-16.39 30.69z"
        transform="translate(-17.15 -33.18)"
      ></path>
      <path
        fill="#1b4a59"
        d="M22.39 140.54H526.21V206.64H22.39z"
        transform="rotate(-16.92 154.233 214.669)"
      ></path>
      <path
        fill="#37808c"
        d="M80.67 237.28H499.57V245.28H80.67z"
        transform="rotate(-16.43 166.632 284.059)"
      ></path>
      <path
        fill="#37808c"
        d="M87.91 261.83H506.80999999999995V269.83H87.91z"
        transform="rotate(-16.43 173.87 308.615)"
      ></path>
      <path
        fill="#4b9cb2"
        d="M629.86 489.26l-454.5 3.9a24.58 24.58 0 01-24.8-24.4l-2-237.5a24.58 24.58 0 0124.4-24.8l454.5-3.9a24.58 24.58 0 0124.8 24.4l2 237.5a24.65 24.65 0 01-24.4 24.8z"
        transform="translate(-17.15 -33.18)"
      ></path>
      <path
        d="M172.86 206.46a24.58 24.58 0 00-24.4 24.8l1.5 178.1s122.4-54.7 229.6-102.7 232.7-104.1 232.7-104.1z"
        transform="translate(-17.15 -33.18)"
        style={{ isolation: "isolate" }}
        fill="#fff"
        opacity="0.1"
      ></path>
      <path
        fill="#e5cd65"
        d="M280.83 339.06h-74.4a11 11 0 01-11-11v-44.2a11 11 0 0111-11h74.4a11 11 0 0111 11v44.2a11.1 11.1 0 01-11 11z"
        transform="translate(-17.15 -33.18)"
      ></path>
      <path
        fill="#37808c"
        d="M287.37 394.78h-82.75a9.19 9.19 0 100 18.38h82.75a9.19 9.19 0 100-18.38M416.1 404a9.19 9.19 0 00-9.2-9.19h-82.75a9.19 9.19 0 100 18.38h82.75a9.19 9.19 0 009.2-9.19M213.82 440.75h-9.2a9.2 9.2 0 000 18.39h9.2a9.2 9.2 0 000-18.39M269 440.75h-18.4a9.2 9.2 0 100 18.39H269a9.2 9.2 0 000-18.39M315 440.75h-9.2a9.2 9.2 0 000 18.39h9.2a9.2 9.2 0 000-18.39M370.12 440.75h-18.38a9.2 9.2 0 100 18.39h18.38a9.2 9.2 0 100-18.39M400.37 443.41a9.35 9.35 0 000 13.06 9.42 9.42 0 006.53 2.67 9.22 9.22 0 10-6.53-15.73"
        transform="translate(-17.15 -33.18)"
      ></path>
      <rect
        width="125.47"
        height="48"
        x="469.65"
        y="215.68"
        fill="#37808c"
        rx="10.22"
      ></rect>
    </svg>
  );
}