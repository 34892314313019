import { db, storage } from '../firebase'
import {
    collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc,
    doc, writeBatch, serverTimestamp, increment, orderBy, limit, query,
    where, startAfter, startAt, setDoc, arrayUnion, endAt
} from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import imageCompression from 'browser-image-compression';
import Aux from '../Managers/AuxManager'
import PostsManager from './PostsManager'
import axios from 'axios';
import moment from 'moment';


export default new class DataManager {


    notificationUsedReferral = (uid,) => {
        return {
            timestamp: serverTimestamp(),
            state: "pending",
            type: "used_referral",
            uid: uid,
            amount: Aux.REWARD_LINK_USED
        }
    }

    notificationGetReferralReward = (uid,) => {
        return {
            timestamp: serverTimestamp(),
            state: "pending",
            type: "get_referral_reward",
            uid: uid,
            amount: Aux.REWARD_LINK_USED
        }
    }

    contactMessage = (uid, email, title, message) => {
        return {
            contact: email || "",
            uid: uid || "",
            timestamp: serverTimestamp(),
            title: title || "",
            message: message || "",
        }
    }

    likesOrCommentsReward = (amount, isLike) => {
        return {
            type: isLike? Aux.TYPE_LIKES_REWARD : Aux.TYPE_COMMENT_REWARD,
            amount: amount,
            timestamp: serverTimestamp(),

        }
    }

    //#region Earn
        LikesOrCommentsClaimReward = (uid, amount, isLike) => {
            const userDoc = doc(db, "users", uid)
            const balanceDoc = doc(collection(userDoc, "balance"))
            const userStatistics = doc(userDoc, "statistics", "statistics")
            const batch = writeBatch(db)

            if(isLike){
                batch.update(userStatistics, {likesToEarn: increment(-amount), likesEarned: increment(amount)})
            }else{
                batch.update(userStatistics, {commentsToEarn: increment(-amount), commentsEarned: increment(amount)})
            }
            batch.set(balanceDoc, this.likesOrCommentsReward(amount, isLike))

            return batch.commit()
        }
    //#endregion Earn

    //#region System

    sendSystemMessage = (uid, text, amount) => {
        const userDoc = doc(db, "users", uid)
        const notificationRef = doc(collection(userDoc, "notifications"))
        return setDoc(notificationRef, {
            timestamp: serverTimestamp(),
            text: text || "",
            state: "pending",
            type: "system_message",
            amount: amount || 0,

        })
    }

    sendContactMessage = (uid, email, title, message) => {
        const contactRef = doc(collection(db, "ContactMessages"))
        return setDoc(contactRef, this.contactMessage(uid, email, title, message))
    }

    claimDailyReward = (uid, day, reward, isLastDay) => {
        const userDoc = doc(db, "users", uid)
        const balanceDoc = doc(collection(userDoc, "balance"))
        const userStatistics = doc(userDoc, "statistics", "statistics")
        const batch = writeBatch(db)
        batch.update(userDoc, { 
            amount: increment(reward), 
            system: {
                lastDayReward: isLastDay ? 1 : day, 
                lastTimestamp: serverTimestamp()
            } 
        })
        batch.update(userStatistics, { totalDailyReward: increment(reward), totalDailyDays: increment(1) })
        const blnc = PostsManager.BalanceBatch(reward, Aux.TYPE_DAILY_REWARD, "daily")
        blnc.day = day
        batch.set(balanceDoc, blnc)
        return batch.commit()
    }

    getTimeFromServer = async () => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL_TIME)
            if(result.status === 200){
                if(result.data?.date)
                    return moment(result.data?.date).unix()
            }
            return null
        } catch (error) {
            Aux.handleError(error)
        }
    }

    getDaysSince = (currentTimestamp) => {
        if(!currentTimestamp || currentTimestamp <= 0) return 
        try {
            //var lastDate = 1627394625 // 1651418236
            const lastDate = 1651418236
            var myDay = (currentTimestamp - lastDate ) / 86400
            var daysSince = Math.round(myDay)
            return daysSince
        } catch (error) {
            Aux.handleError(error)
            return null
        }
            
    }

    getNewUsersID = () => {
        return doc(collection(db, "users")).id;
    }

    getUnixTimeFromRandomServer = async () => {
        try {
            //const result = await axios.get("https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now")
            /* const result = await axios({
                method:"GET",
                url:"https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now",
                headers: {"content-type" : "text/plain; charset=utf-8", "Access-Control-Allow-Origin": "*"}
            }) */
            await fetch(`https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now`)
            .then((response) => console.log(response));
            /* if(result.status === 200){
                let newDate = getTime(result.data?.date)
                if(newDate)
                    return moment(newDate).unix()
            }
            return null */
            //console.log(result)
        } catch (error) {
            Aux.handleError(error)
        }
    }

    CheckIfDailyReward = async (user, currentTime) => {
        if (!user.uid) return;
        if (user.system?.lastDayReward === 0 || !user.system?.lastDayReward) {
            return true;
        }
        if (currentTime > 0 && user.system?.lastTimestamp) {
            let previousDate = moment(user.system.lastTimestamp.toDate()).unix() + 24 * 60 * 60
            if (currentTime > previousDate) {
                return true
            }
        }
    }
    //#endregion System

    //#region Refer

    createReferralLink = (uid, targetUid) => {
        const userRef = doc(db, "users", uid)
        const userNotifications = collection(userRef, "notifications")
        const targetUserRef = doc(db, "users", targetUid)
        const targetUserNotification = doc(collection(targetUserRef, "notifications"))
        const targetUserStatistics = doc(targetUserRef, "statistics", "statistics")
        const batch = writeBatch(db)

        batch.set(targetUserStatistics, { referralCount: increment(1), referralTotalAmount: increment(Aux.REWARD_LINK_USED) }, { merge: true })
        batch.set(targetUserNotification, this.notificationGetReferralReward(uid))
        batch.set(userNotifications, this.notificationGetReferralReward(targetUid))
        batch.set(userRef, { referralUser: targetUid }, { merge: true })
        return batch.commit()
    }


    //#endregion Refer

    //#region Promo

    checkIfAlreadyHasPromoCode = (uid, code) => {
        const userDoc = doc(db, "users", uid)
        const promoCodeRef = collection(userDoc, "statistics")
        const q = query(promoCodeRef, where("codes", "array-contains", code))
        return getDoc(q);
    }

    addPromoCode = async (uid, code, reward) => {
        const userDoc = doc(db, "users", uid)
        const promoCodeRef = doc(userDoc, "statistics", "promocodes")

        return setDoc(promoCodeRef, { codes: arrayUnion(code) }, { merge: true })
    }
    
    checkIfPromoCodeIsValid = async(code) => {
        const promosRef = collection(db, "system")
        const q = query(promosRef, where("code", "==", code))
        const codes = await getDocs(q)
        if(codes.empty) return false
        else return codes.docs[0].data()
    } 
    //#endregion Promo

    // #region Users

    getUserInfo = (uid) => {
        if(!uid) return;
        const userRef = doc(db, "users", uid)
        return getDoc(userRef)
    }

    getUserInfoByUsername = (username) => {
        const userRef = collection(db, "users")
        const q = query(userRef, where("username", "==", username))
        return getDocs(q);
    }

    checkIfUsernameExists = async (username) => {
        try {
            const q = query(collection(db, "users"), where("username", "==", username))
            const usernames = await getDocs(q)
            return (usernames.empty ? true : false)
        } catch (error) {
            throw error
        }
    }

    createUser = async (uid, username, email, provider) => {
        if (!uid || !username || !email) return;
        const usersRef = doc(db, "users", uid)
        const linkId = await this.createUserShortLink(uid)
        return setDoc(usersRef, {
            username: username,
            email: email,
            details: {
                timestamp: serverTimestamp(),
                provider: provider || "email",
                role: "user",
                linkId: linkId || "",
            },
            amount: 0,
        })
    }

    createDummyUser = async (uid, user) => {
        if (!uid || !user.username || !user.email) return;
        const usersRef = doc(db, "users", uid)
        if(user.avatar){
           const url = await this.uploadUserAvatar(uid, user.avatar)
           user.avatar = url;
        }
        return setDoc(usersRef, {
            ...user,
            details: {
                timestamp: serverTimestamp(),
                provider: "none",
                role: "dummy",
                linkId: "",
            },
            amount: 999,
        })
    }

    uploadUserAvatar = async (uid, photoToUpload) => {
        if (!photoToUpload || !uid) return;
        const storageRef = ref(storage, `users/${uid}`);
        const file = await imageCompression(photoToUpload, { maxSizeMB: 1, maxWidthOrHeight: 500 })
        const snapshot = await uploadBytes(storageRef, file)
        const url = await getDownloadURL(snapshot.ref)
        return url
    }

    deleteUserAvatar = async (uid) => {
        if (!uid) return;
        const storageRef = ref(storage, `users/${uid}`);
        return deleteObject(storageRef)
    }

    updateUser = async (uid, newUser, updatePosts) => {
        if(!uid || !newUser || !updatePosts) return
        const usersRef = doc(db, "users", uid)
        const batch = writeBatch(db)
        const postsRef = collection(db, "posts")
        try {
            if (newUser.avatar === "delete") {
                await this.deleteUserAvatar(uid)
                newUser.avatar = "";
            } else if(newUser.avatar){
               const url = await this.uploadUserAvatar(uid, newUser.avatar)
               newUser.avatar = url;
            }
            if (updatePosts) {
                const q = query(postsRef, where("uid", "==", uid))
                const posts = await getDocs(q)
                posts.forEach(doc => {
                    batch.update(doc.ref, { username: newUser.username })
                })
            }
            batch.update(usersRef, newUser)
            return batch.commit()
        } catch (error) {
            throw error
        }


    }

    createUserShortLink = async (uid) => {
        if(!uid) return;
        try {
            const shortData = {
                "dynamicLinkInfo": {
                    "link": "https://richless.app/invitation?uid=" + uid,
                    "domainUriPrefix": 'https://richless.app/invite',
                    "androidInfo": {
                        "androidPackageName": "com.godapp.richless.app",
                    },
                    "socialMetaTagInfo": {
                        "socialTitle": "Install Richless and get 10$ for free.",
                    }
                },
                "suffix": {
                    "option": "SHORT"
                }
            }
            const result = await axios({
                method: "post",
                url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + process.env.REACT_APP_FIREBASE_WEB_API_KEY,
                headers: { 'Content-Type': 'application/json', },
                data: shortData
            })
            console.log(result)
            if (result?.data && result?.data.shortLink) {
                var linkId = result.data.shortLink.split("/").pop()
                return linkId
            }
            return false
        } catch (error) {
            Aux.handleError(error)
        }

    }
    // #endregion Users

    // #region Notifications

    setNewLastNotification = (newLastNot) => {
        if(!newLastNot) return
        localStorage.setItem("lastNotification", newLastNot)
    }

    getLastNotification = () => {
        return localStorage.getItem("lastNotification");
    }

    claimNotificationPending = (uid, notification) => {
        if(!uid || !notification) return
        const userDoc = doc(db, "users", uid)
        const balanceDoc = doc(collection(userDoc, "balance"))
        const notificationDoc = doc(userDoc, "notifications", notification.id)
        const batch = writeBatch(db)

        batch.update(notificationDoc, {state: "accepted"})
        switch(notification.type){
                case "get_referral_reward":
                    batch.set(balanceDoc, 
                        PostsManager.BalanceBatch(notification.amount, 
                            Aux.TYPE_LINK_ACCEPTED, notification.from))
                    break;
                case "used_referral":
                    batch.set(balanceDoc, 
                        PostsManager.BalanceBatch(notification.amount, 
                            Aux.TYPE_LINK_USED, notification.from))
                    break;
                case "system_message":
                    batch.set(balanceDoc, 
                        PostsManager.BalanceBatch(notification.amount, 
                            Aux.TYPE_SYSTEM_RECEIVED, "system", notification.message))
                    break;
                case "comment":
                    batch.set(balanceDoc, 
                        PostsManager.BalanceBatch(notification.amount, 
                            Aux.TYPE_COMMENT_REWARD, notification.from, notification.message))
                    break;
                case "request_message":
                    batch.set(balanceDoc, 
                        PostsManager.BalanceBatch(notification.amount, 
                            Aux.TYPE_SEND_REQUEST_MESSAGE, notification.from, notification.message))
                    break;
        }


        return batch.commit()
    }
    // #endregion Notifications

    // #region Search

    searchHashtag = (text, searchLimit = 20) => {
        if(!text) return
        const hashtagsRef = collection(db, "hashtags")
        const q = query(hashtagsRef, orderBy("hashtag"), startAt(text), endAt(text + "\uf8ff"), limit(searchLimit))
        return getDocs(q)
    }

    searchUser = (text, searchLimit = 20) => {
        if(!text) return
        const userRef = collection(db, "users")
        const q = query(userRef, orderBy("username"), startAt(text), endAt(text + "\uf8ff"), limit(searchLimit))
        return getDocs(q)
    }

    // #endregion Search

}