import styled,{keyframes} from 'styled-components'

export default function Loader({color, style}){

    return(
        <RotateIcon color={color} style={style}/>
    )
}

const rotate = keyframes`
    from {
        transform: rotate(0def);
    }
    to {
        transform: rotate(360deg);
    }
`


const StyledIcon = styled.div`
    align-self: center;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    width: 1rem;
    height: 1rem;
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.color || "black"};
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: 1;
`

const RotateIcon = styled(StyledIcon)`
  display: inline-block;
  animation: ${rotate} 500ms linear infinite;
`;