import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage'


const firebaseConfig = {

    apiKey: "AIzaSyBQTzoDRUAFdqBMCAtGvl5EsCDgDueNTec",
    authDomain: "richless-app.firebaseapp.com",
    projectId: "richless-app",
    storageBucket: "richless-app.appspot.com",
    messagingSenderId: "267910082162",
    appId: "1:267910082162:web:ebaddf5930ad9a41a85ceb",
    measurementId: "G-P21RRKSBTK"

};


/* var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}; */

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)
const storage = getStorage(app)

console.log("firebase Initialized");
export { auth, db, analytics, storage,  }