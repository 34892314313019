import { useState, useEffect } from 'react'
import Container from "../Components/Container";
import Aux from '../Managers/AuxManager';
import { useUserState } from '../Context/UserContext'
import { useAuthState } from '../Context/AuthContext'
import InputText from '../Components/InputTextMaterial';
import Button from '../Components/Button';
import AlertMessage from '../Components/AlertMessage';
import Colors from '../Utilities/Colors';
import Header from '../Components/Header';
import { useNavigate } from 'react-router-dom'

export default function ChangePassword() {
    const [loading, setLoading] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const { changePassword, reauthenticateUser } = useAuthState()

    const validate = () => {


		const errPassword = Aux.validatePassword(newPassword)
		if(errPassword) return errPassword;

		if (!confirmPassword || !oldPassword) return "All fields are required."
		if (newPassword !== confirmPassword) return 'Passwords does not match'

		return "";
	}

    const onSubmit = async () => {

        setError(validate())
        if (error, loading) return;

        try {
            setLoading(true);
            
            reauthenticateUser(oldPassword)
            .then(() => {
                changePassword(newPassword).then(() => {
                    setSuccess(true);
                })
            })


            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setError("An unexpected error has occurred.")
            setLoading(false);
        }

    }

    const onChangeOldPassword = (text) => {
        setOldPassword(text);
        setError("");
    }
    const onChangeNewPassword = (text) => {
        setNewPassword(text);
        setError("");
    }
    const onChangeConfirmPassword = (text) => {
        setConfirmPassword(text);
        setError("");
    }

    return (
        <>
            <Container>
                <Header text="Change Password" goBack/>
                <AlertMessage variant="error" text={error} />
                <AlertMessage variant="success" text={"You've sucessfully changed your password."} show={success} />
                <span className="sub-header">To change your password please fill out the fields below.</span>
                
                <InputText type="text" value={oldPassword} title="Old Password" onChange={onChangeOldPassword} />
                <InputText type="text" value={newPassword} title="New Password" onChange={onChangeNewPassword} />
                <InputText type="text" value={confirmPassword} title="Confirm Password" onChange={onChangeConfirmPassword} />
                <Button className="w-100" text="Change Password" onClick={onSubmit} isSuccess={success} isLoading={loading} />
                
            </Container>
        </>
    )
}