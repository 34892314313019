import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../Context/AuthContext'
import Container from '../Components/Container'
import Header from '../Components/Header'
import AlertMessage from '../Components/AlertMessage'
import InputText from '../Components/InputTextMaterial'
import Button from '../Components/Button'
import Separator from '../Components/Separator'
import FacebookIcon from 'mdi-react/FacebookIcon'
import GoogleIcon from 'mdi-react/GoogleIcon'
import Colors from '../Utilities/Colors'
import Aux from '../Managers/AuxManager'
import { useUserState } from '../Context/UserContext'
import styled from "styled-components"
export default function Login (){

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [waitingForUser, setWaitingForUser] = useState(false)
    const [loadingGoogle, setLoadingGoogle] = useState(false)
    const [loadingFacebook, setLoadingFacebook] = useState(false)
    const [provider, setProvider] = useState("email");
    //const { setTimeActive } = useAuthValue()
    const navigate = useNavigate();
    const { loginEmail, loginGoogle} = useAuthState();
    const {currentUser, userState} = useUserState();
    
    useEffect(() => {
        if(waitingForUser){
            if(userState == "success"){
                const pendingLink = localStorage.getItem("pendingLink")
                if(pendingLink){
                    navigate(pendingLink)
                }else if(currentUser?.email){
                    navigate("/") //found an user
                }else{
                    navigate("/add-username") //dont have an user yet
                }
                /* setLoading(false)
                setWaitingForUser(false); */
            }else if(userState == "error"){
                setError("An unexpected error has occurred")
                setLoading(false)
                setWaitingForUser(false);
            }
        }

    }, [waitingForUser, currentUser, userState])

    let validate = () => {
        if(!email) {
            setError("Email is required."); 
            return false;
        }
        if(!password){
            setError("Password is required."); 
            return false;
        }

        return true;
    }
    const onSubmit = async (e) =>{
        e.preventDefault()
        if(loading || loadingFacebook || loadingGoogle) return;

        try {
            if(validate()){
                setLoading(true);
                const cred = await loginEmail(email, password)
                if(cred){
                    //console.log(cred.providerId)
                    setWaitingForUser(true)
                }
            }
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                case 'auth/user-not-found':
                case 'auth/invalid-email':
                    setError("Invalid Email or password" )
                    break;
                case 'auth/network-request-failed':
                    setError("No internet Connection" )
                    break;
                default:
                    setError("An unexpected error has occurred")
                    break;
            }
            Aux.handleError(error);
        }

    }

    const SubmitSocialLogin = async (isGoogle) =>{
        if(loading || loadingFacebook || loadingGoogle) return;

        try{
            const result = await loginGoogle()
            console.log(result)
            setProvider("google")
            setWaitingForUser(true);
        }catch(error){
            setError(error)
            Aux.handleError(error)
        }
    }

    return (
        <Container>
                <Header text="Login"/>
                <SubHeader className="sub-header">start your jorney</SubHeader>
                <AlertMessage variant="error" text={error} />
                <form onSubmit={onSubmit} name='login_form'>
                    <InputText type="email" value={email} title="Email" onChange={(email) => setEmail(email)}/>
                    <InputText type="password" value={password} title="Password" onChange={(password) => setPassword(password)}/>

                    <Button className="w-100" text="Login" isLoading={loading} type="submit" />
                    {/* <button type='submit'>Login</button> */}
                </form>
                <Separator text="or" style={{marginTop:30, marginBottom:10}}/>
                <div className="flex-center" >
                    <Button icon={FacebookIcon}  variant="outline" type="submit" style={{width:"30%", marginRight:10}} color={Colors.facebook}/>
                    <Button icon={GoogleIcon}  variant="outline" type="submit" 
                    style={{width:"30%", marginLeft:10}} color={Colors.google} onClick={() => SubmitSocialLogin(true)}/>
                </div>
                <div className="flex-start align-center" style={{marginTop:30}}>
                    <p>
                        Don't have and account?
                        
                        {/* <Link to='/register'>Create one here</Link> */}
                    </p>
                    <Button size="small" text="Register" style={{backgroundColor:Colors.register, marginLeft:10}} 
                    onClick={() => navigate("/register")} />
                </div>
                
        </Container>
    )
}

const SubHeader = styled.p`

`