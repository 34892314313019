import {db, storage} from '../firebase'
import { collection,getDocs, getDoc, addDoc, updateDoc, deleteDoc, 
    doc, writeBatch, serverTimestamp, increment, orderBy, limit, query, where, startAfter, startAt, setDoc } from 'firebase/firestore'
import {ref, uploadBytesResumable, getDownloadURL, uploadBytes, } from 'firebase/storage'
import PostsManager from './PostsManager'
import Aux from './AuxManager'


export default new class MessagesManager{


    //#region Batches

    notificationRequestBatch = (uid, text, amount) => {
        return {
            from: uid,
            request_from: uid, //easier for query
            timestamp: serverTimestamp(),
            text: text || "",
            amount: amount || 0,
            type:"request",
            state:"pending"
        }
    }

    notificationAcceptedRequestBatch = (uid) => {
        return {
            from: uid,
            timestamp: serverTimestamp(),
            type: "request_accepted"
        }
    }

    notificationRequestMessageBatch = (uid, message, amount) => {
        return {
            timestamp: serverTimestamp(),
            text: message || "",
            state:"pending",
            type:"request_message",
            from: uid,
            request_from:uid, //easier for query
            amount: amount || 0,
        }
    }

    createChatBatch = (users, targetUid, text= "", amount = 0) => {
        return {
            from: targetUid,
            amount: amount || 0,
            timestamp: serverTimestamp(),
            lastMessage: {
                text: text ? text : "Chat created",
                timestamp: serverTimestamp(),
                read: false,
                from: targetUid
            },
            users: [targetUid, ...users]
        }
    }

    addChatMessageBatch = (fromUid, text, amount = 0) => {
        return {
            timestamp: serverTimestamp(),
            text: text || "...",
            from: fromUid,
            amount: amount || 0,

        }
    }

    //#endregion Batches

    // #region Messages
    /* getMoreMessages = (uid, lastVisible) => {
        if(!uid) return;
        const chatRef = (collection(db, "chats"))
		let q = query(chatRef, where("users", "array-contains", uid), orderBy("lastMessage.timestamp", "desc"), limit(15))
    
        if(lastVisible)
            q = query(q, startAfter(lastVisible))
        return getDocs(q)
    } */

    getMessagesFromChat = (id, lastVisible) => {
        if(!id) return;
        const chatRef = collection(db, "chats", id, "messages")
        let q = query(chatRef, orderBy("timestamp", "desc"), limit(Aux.LIMIT_MESSAGES))

        if(lastVisible)
            q = query(q, startAfter(lastVisible))

        return getDocs(q)

    }

    sendMessageToChat = (chatId, message, fromId) => {
        if(!chatId || !message || !fromId) return
        const chatRef = doc(db, "chats", chatId) 
        const messageRef = doc(collection(chatRef, "messages")) 
        const batch = writeBatch(db);

        batch.set(messageRef, {
            from: fromId,
            timestamp: serverTimestamp(),
            text: message
        })
        batch.set(chatRef, {
            lastMessage:{
                text:message,
                timestamp:serverTimestamp(),
                from: fromId,
                read:false,
            }
        }, {merge:true})
        return batch.commit();
    }

    updateReadMessageStatus = (message, status, fromUid) => {
        if(!message || !status || !fromUid) return;
        const chatRef = doc(db, "chats", message.id)
        if(message.from != fromUid){
            return setDoc(chatRef, {
                lastMessage:{
                    read:status
                }
            }, {merge:true})
        } 
        return false;
    }

    getMessageInfo = (messageId) => {
        if(!messageId) return;
        const chatRef = doc(db, "chats", messageId)
        return getDoc(chatRef);
    }
    //#endregion Messages

     //#region Requests

     refuseRequest = (notificationId, uid) => {
        if(!notificationId || !uid) return;
        const userRef = doc(db, "users", uid)
        const notificationDoc = doc(userRef, "notifications", notificationId)
        return updateDoc(notificationDoc, { state: "refused" })

    }

    acceptRequestAndCreateChat = (uid, notificationId, targetUid, text, amount) => {
        if(!uid || !notificationId || !targetUid) return;
        console.log("entrou")
        const userRef = doc(db, "users", uid)
        const notificationDoc = doc(userRef, "notifications", notificationId)
        const addChatDoc = doc(collection(db, "chats"))
        const targetUserDoc = doc(db, "users", targetUid)
        const targetUserDocNotification = doc(collection(targetUserDoc, "notifications"))
        const chatMessagesDoc = doc(collection(addChatDoc, "messages"))

        const batch = writeBatch(db)
        batch.update(notificationDoc, { state: "accepted" })
        batch.set(targetUserDocNotification, this.notificationAcceptedRequestBatch(uid))
        batch.set(addChatDoc, this.createChatBatch([uid], targetUid, text, amount))
        batch.set(chatMessagesDoc, this.addChatMessageBatch("system", "Chat created"))
        if (amount) {
            batch.set(chatMessagesDoc, this.addChatMessageBatch(targetUid, text, amount))
        }
        return batch.commit()
    }

    createRequestNotification = (uid, targetUid, message, amount) => {
        if(!uid || !targetUid) return;
        const userDoc = doc(db, "users", uid)
        const userDocStatistics = doc(userDoc, "statistics", "statistics")
        const balanceRef = doc(collection(db, "users", uid, "balance"));
        const targetUserDoc = doc(db, "users", targetUid)
        const targetUserDocStatistics = doc(targetUserDoc, "statistics", "statistics")
        const targetUserDocNotification = doc(collection(targetUserDoc, "notifications"))
        const batch = writeBatch(db);

        /* TODODODODODODO const query = await targetUserDocActivity.where("requestSentBy", "==", this.uid).get();
        if (!query.empty) {
            return 2;
        } */

        if(amount > 0){
            batch.set(targetUserDocNotification, this.notificationRequestBatch(uid, message, amount))
            batch.set(balanceRef, PostsManager.BalanceBatch(amount, Aux.TYPE_SEND_REQUEST_MESSAGE))
            batch.update(userDoc, PostsManager.UserMoneyBatch(amount))
        }else{
            batch.set(targetUserDocNotification, this.notificationRequestBatch(uid, "", 0))
        }
        return batch.commit()
    }

    checkIfAlreadySentRequest = (uid, targetUid) => {
        if(!uid || !targetUid) return;
        const targetUserDoc = doc(db, "users", targetUid)
        const targetUserDocNotification = collection(targetUserDoc, "notifications")
        const q = query(targetUserDocNotification, where("request_from", "==", uid))
        return getDocs(q)
    }
    // #endregion Requests

    // #region Users

    getUserInfo = (uid) => {
        if(!uid) return;
        const userRef = doc(db, "users", uid)
        return getDoc(userRef)
    }

    // #endregion
}