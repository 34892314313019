import { useState } from "react";
import Button from "../Components/Button";
import Container from "../Components/Container";
import Aux from "../Managers/AuxManager";
import { db } from "../firebase";
import { GeoPoint } from "firebase/firestore";


export default function Geolocation(){

    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)

    //const geofire = new GeoFirestore(db);

    const GetLocation = () => {
        try {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude)
                setLongitude(longitude)
            })
        } catch (error) {
            Aux.handleError(error)
        }
    }

    const UploadGeo = () => {
        try {
            if(!longitude || !latitude) return
            /* geofire.collection(db, 'geo').doc("geoo").set({coords: new GeoPoint(latitude, longitude)}) */
        } catch (error) {
            Aux.handleError(error)
        }
    }

    return (
        <Container minimal>
            <Button text={"Get Location"} onClick={GetLocation}/>
            {latitude && <p> Latitude: {latitude}</p>}
            {longitude && <p> Longitude: {longitude}</p>}
            {longitude && <Button text={"upload"} onClick={UploadGeo}/>}
        </Container>
    )
}
