import React from "react";

function PaypalImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 566.91 137.35"
    >
      <path
        fill="#009ee3"
        fillRule="evenodd"
        d="M49.47 32.33h38.74c20.8 0 28.63 10.53 27.42 26C113.63 83.87 98.19 98 77.71 98H67.37c-2.81 0-4.7 1.86-5.46 6.9l-4.39 29.3c-.29 1.9-1.29 3-2.79 3.15H30.39c-2.29 0-3.1-1.75-2.5-5.54l14.84-93.93c.58-3.76 2.64-5.55 6.74-5.55z"
      ></path>
      <path
        fill="#113984"
        fillRule="evenodd"
        d="M217.66 30.59c13.07 0 25.13 7.09 23.48 24.76-2 21-13.25 32.62-31 32.67h-15.51c-2.23 0-3.31 1.82-3.89 5.55l-3 19.07c-.45 2.88-1.93 4.3-4.11 4.3H169.2c-2.3 0-3.1-1.47-2.59-4.76l11.91-76.43c.59-3.76 2-5.16 4.57-5.16h34.57zm-23.5 40.92h11.75c7.35-.28 12.23-5.37 12.72-14.55.3-5.67-3.53-9.73-9.62-9.7l-11.01.05-3.79 24.2zm86.21 39.58c1.32-1.2 2.66-1.82 2.47-.34l-.47 3.54c-.24 1.85.49 2.83 2.21 2.83h12.82c2.16 0 3.21-.87 3.74-4.21L309 63.33c.4-2.49-.21-3.71-2.1-3.71h-14.1c-1.27 0-1.89.71-2.22 2.65l-.52 3c-.27 1.59-1 1.87-1.68.27-2.39-5.66-8.49-8.2-17-8-19.73.46-33.06 15.46-34.49 34.71-1.1 14.88 9.56 26.57 23.62 26.57 10.2 0 14.76-3 19.9-7.7zm-10.74-7.63c-8.51 0-14.44-6.79-13.21-15.11s9.19-15.11 17.7-15.11 14.44 6.76 13.21 15.11-9.18 15.11-17.7 15.11zm64.5-44h-13c-2.68 0-3.77 2-2.92 4.46l16.14 47.26-15.83 22.49c-1.33 1.88-.3 3.59 1.57 3.59h14.61a4.46 4.46 0 004.34-2.13l49.64-71.2c1.53-2.19.81-4.49-1.7-4.49h-13.83c-2.37 0-3.32.94-4.68 2.91l-20.7 30-9.25-30.07c-.54-1.82-1.89-2.82-4.38-2.82z"
      ></path>
      <path
        fill="#009ee3"
        fillRule="evenodd"
        d="M440.32 30.59c13.07 0 25.13 7.09 23.48 24.76-2 21-13.25 32.62-31 32.67h-15.5c-2.23 0-3.31 1.82-3.89 5.55l-3 19.07c-.45 2.88-1.93 4.3-4.11 4.3h-14.43c-2.3 0-3.1-1.47-2.59-4.76l11.93-76.45c.59-3.76 2-5.16 4.57-5.16h34.54zm-23.5 40.92h11.75c7.35-.28 12.23-5.37 12.72-14.55.3-5.67-3.53-9.73-9.62-9.7l-11.06.05-3.79 24.2zM503 111.09c1.32-1.2 2.66-1.82 2.47-.34l-.47 3.54c-.24 1.85.49 2.83 2.21 2.83h12.82c2.16 0 3.21-.87 3.74-4.21l7.9-49.58c.4-2.49-.21-3.71-2.1-3.71h-14.05c-1.27 0-1.89.71-2.22 2.65l-.52 3c-.27 1.59-1 1.87-1.68.27-2.39-5.66-8.49-8.2-17-8C474.33 58 461 73 459.57 92.25c-1.1 14.88 9.56 26.57 23.62 26.57 10.2 0 14.76-3 19.9-7.7zm-10.73-7.63c-8.51 0-14.44-6.79-13.21-15.11s9.19-15.11 17.7-15.11S511.23 80 510 88.35s-9.19 15.11-17.7 15.11zm59.13 13.74h-14.8a1.75 1.75 0 01-1.83-1.67 1.83 1.83 0 010-.33l13-82.36a2.54 2.54 0 012.46-2h14.8a1.75 1.75 0 011.83 1.67 1.83 1.83 0 010 .33l-13 82.36a2.55 2.55 0 01-2.46 2z"
      ></path>
      <path
        fill="#113984"
        fillRule="evenodd"
        d="M25.24 0H64c10.94 0 23.9.35 32.56 8 5.79 5.11 8.83 13.24 8.13 22-2.38 29.61-20.09 46.2-43.85 46.2H41.72c-3.26 0-5.41 2.16-6.33 8l-5.34 34c-.35 2.2-1.3 3.5-3 3.66H3.12c-2.65 0-3.59-2-2.9-6.42l17.2-109C18.11 2.08 20.52 0 25.24 0z"
      ></path>
      <path
        fill="#172c70"
        fillRule="evenodd"
        d="M36 80.75l6.77-42.87c.59-3.76 2.65-5.56 6.75-5.56h38.69c6.41 0 11.6 1 15.66 2.85-3.89 26.36-20.94 41-43.26 41H41.52c-2.52.01-4.44 1.29-5.52 4.58z"
      ></path>
    </svg>
  );
}

export default PaypalImage;