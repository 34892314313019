import { Routes, Route, BrowserRouter, useLocation, Outlet, Navigate } from "react-router-dom";
import Login from "../Account/Login";
import Register from "../Account/Register";
import VerifyEmail from "../Account/VerifyEmail";
import MyProfile from "../Profile/MyProfile";
import NavBar from "./NavBar";
import { useAuthState } from '../Context/AuthContext'
import LoadingUser from "../Loading/LoadingUser";
import ShowPosts from "../Posts/ShowPosts";
import CreatePost from "../Posts/CreatePost";
import EditProfile from "../Profile/EditProfile";
import AddUsername from "../Account/AddUsername";
import ForgotPassword from "../Account/ForgotPassword";
import ChangePassword from "../Account/ChangePassword";
import ViewPost from "../Posts/ViewPost";
import ShowSearch from "../Search/ShowSearch";
import Sidebar from "react-sidebar";
import { useState } from "react";
import SideBar from "./SideBar";
import styled from "styled-components"
import ViewUser from "../Profile/ViewUser";
import Invitation from "../Account/Invitation";
import ViewChat from "../Messages/ViewChat";
import ShowMessages from "../Messages/ShowMessages";
import DeleteUser from "../Account/DeleteUser";
import AddBalance from "../Profile/AddBalance";
import InviteFriends from "../Profile/InviteFriends";
import ReferralsAndPromos from "../Profile/ReferralsAndPromos";
import Settings from "../System/Settings";
import Contact from "../System/ContactUs";
import ChangeEmail from "../Account/ChangeEmail";
import PrivacyPolicy from "../System/PrivacyPolicy";
import AdminPage from "../System/AdminPage";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import ScrollToTop from "../Utilities/ScrollToTop";
import Loader from "../Components/Loader";
import Geolocation from "../Geolocation/Geolocation";
import PageNotFound from "../System/PageNotFound";
import PaypalSingleton from "../System/PaypalSingleton";

export default function Navigation(props) {

    const [sidebarOpen, setSideBarOpen] = useState(false)

    const UseNavBar = () => {
        const { pathname } = useLocation();
        return pathname !== '/login' && pathname !== '/register' && <NavBar toogleSideBar={ToogleSidebar} />
    }

    const PrivateRoute = ({ children }) => {
        const { location } = useLocation();
        const { currentUser, loading } = useAuthState();
        //return loading ? <LoadingUser /> : currentUser?.uid ? children : <Navigate to="/register" state={{ from: location }} />;
        return children
    }

    const ToogleSidebar = () => { //for passing to navbar
        setSideBarOpen(!sidebarOpen)
    }

    const RenderView = (props) => {
        const { currentUser, loading } = useAuthState();
        return (
            <>
                {loading ? <LoadingUser /> :
                    props.children
                }
            </>
        )
    }

    const SidebarLayout = () => (
        <Sidebar
            sidebar={<SideBar />}
            open={sidebarOpen}
            docked={false}
            styles={{ sidebar: { top: 60, overflowX: "hidden", backgroundColor: "white" }, content: { backgroundColor: "#fbfbfb" } }}
            //onSetOpen={setSideBarOpen}
            onSetOpen={ToogleSidebar}
            contentId="main-sidebar"
        >
            <UseNavBar />
            <RenderView>
                <MenusContainer>
                    <SideMenuLeft>
                        <LeftMenu />
                    </SideMenuLeft>
                    <MainComponent>
                        <MainWrapper>
                            <Outlet/>

                        </MainWrapper>
                    </MainComponent>
                    <SideMenuRight>
                        <RightMenu />
                    </SideMenuRight>
                </MenusContainer>
            </RenderView>
        </Sidebar>
    )

    return (

        <BrowserRouter>
            <ScrollToTop>

                <Routes>
                    <Route path="/paypal" element={<PaypalSingleton />} />
                    <Route element={<SidebarLayout/>}>
                        <Route index path="/" element={<ShowPosts />} />
                        <Route path="/top" element={<ShowPosts type="top" />} />
                        <Route path="/trending" element={<ShowPosts type="trending" />} />
                        <Route path="/latest" element={<ShowPosts type="latest" />} />

                        <Route path="/hashtag/:tag" element={<ShowPosts />} />
                        <Route path="/hashtag/:tag/:tagtype" element={<ShowPosts />} />

                        <Route path="/register" element={<Register />} />
                        <Route path='/verify-email' element={<PrivateRoute><VerifyEmail /></PrivateRoute>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register/username" element={<AddUsername />} />

                        <Route path="/account/profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
                        <Route path="/account/recover" element={<ForgotPassword />} />
                        <Route path="/account/password" element={<ChangePassword />} />
                        <Route path="/account/email" element={<ChangeEmail />} />
                        <Route path="/account/edit" element={<EditProfile />} />
                        <Route path="/account/delete" element={<DeleteUser />} />
                        <Route path="/account/balance" element={<AddBalance />} />
                        <Route path="/account/invite-friends" element={<InviteFriends />} />
                        <Route path="/account/refer" element={<ReferralsAndPromos />} />

                        <Route path="/user/:id" element={<ViewUser />} />
                        <Route path="/u/:username" element={<ViewUser username />} />
                        <Route path="/post/:id" element={<ViewPost />} />
                        <Route path="/search/:type/:id" element={<ShowSearch />} />
                        <Route path="/search/:type" element={<ShowSearch />} />
                        <Route path="/search" element={<ShowSearch />} />
                        <Route path="/create" element={<CreatePost />} />
                        <Route path="/invitation" element={<Invitation />} />
                        <Route path="/invite/:id" element={<Invitation />} />

                        <Route path="/geolocation" element={<Geolocation />} />

                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />

                        <Route path="/messages/:id" element={<ViewChat />} />
                        <Route path="/messages" element={<ShowMessages />} />

                        <Route path="/*" element={<PageNotFound />} />
                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>

    )
}

const MenusContainer = styled.div`
    display: flex;
`

const MainComponent = styled.div`
    width: 100%;
    //max-width: 600px;
    display: flex;
    justify-content: center;
`
const MainWrapper = styled.div`
    max-width: 600px;
    display: flex;
    justify-content: center;
    width: 100%;
`

const SideMenuLeft = styled.div`
    //width: 300px;
    //width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    max-width: 350px;
    width:30%;
    @media (max-width: 800px) {
        display: none;
    }
`

const SideMenuRight = styled.div`
    max-width: 400px; 
    min-width: 300px;
    overflow: hidden;
    box-sizing: border-box;
    @media (max-width: 1100px) {
        display: none;
    }
`