import React from "react";

function CongratulationsImage({style}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.64 239.27" style={style}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            fill="#ffc209"
            d="M205.69 88.27c-.35-6.23-.79-10-.79-10h-99.4s-.43 3.79-.79 10h-26v16.35c1.37 33.71 18.52 48.31 32.58 54.62 5.94 19.22 16.61 35.55 35.39 40.28v23.41h-30a11.23 11.23 0 00-11.23 11.24v5.1h99.06v-5.1a11.23 11.23 0 00-11.23-11.24H163v-23.26c19.19-4.5 30-21 36.06-40.39 14.08-6.29 31.3-20.88 32.66-54.66V88.27zM94.3 104.62h10a232.27 232.27 0 002.44 34.38c-14.09-12.78-12.44-34.38-12.44-34.38zm109.34 34.47a230.88 230.88 0 002.49-34.47h10.05s1.66 21.7-12.54 34.47z"
          ></path>
          <path
            fill="#e8a80a"
            d="M226 42.53h-6.35v-6.35a1.38 1.38 0 10-2.75 0v6.35h-6.35a1.38 1.38 0 100 2.75h6.35v6.35a1.38 1.38 0 002.75 0v-6.35H226a1.38 1.38 0 000-2.75zM40 31.87h-7v-7a1.53 1.53 0 00-3 0v7.05h-7a1.53 1.53 0 000 3.05h7.05v7a1.53 1.53 0 103 0v-7h7a1.53 1.53 0 000-3.05zM18.88 157.22h-7.13v-7.12a1.54 1.54 0 10-3.08 0v7.12H1.54a1.55 1.55 0 000 3.09h7.13v7.12a1.54 1.54 0 003.08 0v-7.12h7.13a1.55 1.55 0 000-3.09zM285.59 163.32H279v-6.63a1.44 1.44 0 00-2.88 0v6.63h-6.63a1.44 1.44 0 000 2.87h6.63v6.63a1.44 1.44 0 002.88 0v-6.63h6.63a1.44 1.44 0 000-2.87z"
          ></path>
          <path
            fill="#ffc209"
            d="M34.28 92.79h-6.16v-6.16a2.23 2.23 0 00-4.46 0v6.16h-6.17a2.24 2.24 0 000 4.47h6.17v6.16a2.23 2.23 0 004.46 0v-6.16h6.16a2.24 2.24 0 000-4.47zM146.74 25.77h-6.58v-6.58a2.39 2.39 0 00-4.77 0v6.58h-6.58a2.39 2.39 0 100 4.77h6.58v6.58a2.39 2.39 0 104.77 0v-6.58h6.58a2.39 2.39 0 000-4.77zM293.8 87.7h-7.9v-7.9a2.86 2.86 0 10-5.72 0v7.9h-7.9a2.86 2.86 0 100 5.72h7.9v7.9a2.86 2.86 0 005.72 0v-7.9h7.9a2.86 2.86 0 100-5.72zM260.93 204.7h-5.82v-5.82a2.11 2.11 0 00-4.22 0v5.82h-5.82a2.11 2.11 0 000 4.22h5.82v5.82a2.11 2.11 0 104.22 0v-5.82h5.82a2.11 2.11 0 000-4.22zM75.8 204.49h-6.37v-6.37a2.31 2.31 0 00-4.62 0v6.37h-6.38a2.31 2.31 0 000 4.62h6.38v6.38a2.31 2.31 0 004.62 0v-6.38h6.37a2.31 2.31 0 100-4.62z"
          ></path>
          <circle cx="174.41" cy="5.67" r="5.67" fill="#e8a80a"></circle>
          <circle cx="56.13" cy="151.33" r="3.88" fill="#e8a80a"></circle>
          <circle cx="20.42" cy="206.81" r="4.67" fill="#ffc209"></circle>
          <circle cx="81.47" cy="5.67" r="4.99" fill="#ffc209"></circle>
          <circle cx="303.67" cy="134.04" r="4.97" fill="#ffc209"></circle>
          <circle cx="296.36" cy="202.52" r="4.28" fill="#e8a80a"></circle>
          <circle cx="287.02" cy="28.16" r="3.98" fill="#e8a80a"></circle>
        </g>
      </g>
    </svg>
  );
}

export default CongratulationsImage;