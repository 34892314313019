import React from "react"
import Loader from "../Components/Loader"

export default function LoadingUser () {
    return (
        <div className="flex-center align-center m-t-10">
            <span>User is loading, Please wait...</span>
            <Loader style={{marginTop:20}}/>
        </div>
    )
}
