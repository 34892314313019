import styled from "styled-components";
import Colors from "../Utilities/Colors";
import ErrorIcon from "mdi-react/CloseCircleOutlineIcon"
import SuccessIcon from "mdi-react/CheckCircleOutlineIcon"

export default function AlertMessage({text, variant, isShow, show, icon}) {

    let backgroundColor = Colors.primaryLightest;
    let textColor = Colors.primary;
    let borderColor = Colors.primary;
    let Icon = icon || null;
    let message =  "Good job!";
    switch (variant) {
        case "error":
            backgroundColor = Colors.errorLighter;
            textColor = Colors.error;
            borderColor = Colors.error;
            message = "An error has occurred!";
            Icon = icon || ErrorIcon;
            break;
        case "success":
            backgroundColor = Colors.successLighter;
            textColor = Colors.success;
            borderColor = Colors.success;
            message = "Success!";
            Icon = icon || SuccessIcon;
            break;
    }


    return(
        <>
            {(variant==="success" ? show : text ) ?
                <StyledAlert backgroundColor={backgroundColor} borderColor={borderColor} textColor={textColor} >
                    {Icon && <Icon style={{color:textColor, marginRight:10}}/>}
                    <span>{text || message }</span>
                </StyledAlert>
            : null }
        </>
    )

}

const StyledAlert = styled.div`
        display: flex;
        border: 1px solid;
        border-radius: 5px;
        padding: 10px 20px;
        margin:10px 0;
        background-color: ${props => props.backgroundColor || Colors.primaryLightest};
        border-color: ${props => props.borderColor || Colors.primary};

        & span {
            color: ${props => props.textColor || Colors.primary}
        }
    `