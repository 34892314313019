import styled from "styled-components"
import Avatar from "../Components/User/Avatar"
import { Link } from "react-router-dom"
import Button from "../Components/Button"
import { useEffect, useState } from "react"
import moment from "moment"
import Aux from "../Managers/AuxManager"
import MessagesManager from "../Managers/MessagesManager"
import { useUserState } from "../Context/UserContext"
import PostsManager from "../Managers/PostsManager"
import DataManager from "../Managers/DataManager"
export default function NotificationCard({ item }) {

    const [notification] = useState(item)
    const [loading, setLoading] = useState(false)
    const [loadingUser, setLoadingUser] = useState(false)
    const [successClaimReward, setSuccessClaimReward] = useState(false)
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [loadingRefuse, setLoadingRefuse] = useState(false)
    const [username, setUsername] = useState("username")
    const [user, setUser] = useState()
    const [notificationState, setNotificationState] = useState("init")
    const { currentUser } = useUserState();
 
    useEffect(() => {
        setNotificationState(notification.state)
        if(notification.from){
            getUserInfo(notification.from)
        }
    }, [])

    const getUserInfo = async (id) => {
        if (loadingUser || !id) return
        try {
            setLoadingUser(true);
            await DataManager.getUserInfo(id).then((data) => {
                if (data.exists) {
                    setUser({ ...data.data(), uid: id })
                }
            })
            setLoadingUser(false);
        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false);
        }
    }

    const ClaimReward = async () => {
        if (loading) return

        try {
            setLoading(true)

            await DataManager.claimNotificationPending(currentUser.uid, notification).then(() => {
                setSuccessClaimReward(true);
                setNotificationState("accepted")
            })
            setLoading(false)
        } catch (error) {
            Aux.handleError(error)
            setLoading(false)
        }
    }

    const AcceptOrRefuseRequest = async (isAccept) => {
        if (notification.type !== "request" && notification.type !== "request_message") return
        if(loadingAccept || loadingRefuse) return

        try {

            if (isAccept) {
                setLoadingAccept(true)
                
                await MessagesManager.acceptRequestAndCreateChat(currentUser.uid, notification.id, notification.from, notification.text, notification.amount).then(() => {
                    setNotificationState("accepted")
                })
            } else {
                setLoadingRefuse(true)
                await MessagesManager.refuseRequest(notification.id, currentUser.uid).then(() => {
                    setNotificationState("refused")
                })
            }
            setLoadingRefuse(false)
            setLoadingAccept(false)
        } catch (error) {
            Aux.handleError(error)
            setLoadingRefuse(false)
            setLoadingAccept(false)
        }
    }

    const ChooseText = () => {
        switch (notification.type) {
            case "comment":
                return <NormalText><UsernameText to={"/user/" + notification.uid} >{username}</UsernameText> {""} commented on your post</NormalText>
            case "like":
                return <NormalText>
                    <UsernameText /> {""}
                    {notification.likesCount > 1 ?
                        `and ${notification.likesCount} others liked your post.`
                        : "liked your post."}
                </NormalText>
            case "request":
                return <NormalText>
                    {notificationState === "accepted" ? "You've accepted " :
                        notificationState === "refused" ? "You've refused " : null}
                    <UsernameText /> {" "}
                    {notificationState === "pending" ? "sent you a request." : "request."}
                </NormalText>
            case "request_accepted":
                return <NormalText>
                    <UsernameText /> {" "} accepted your request.
                </NormalText>
            case "request_message":
                return <NormalText>
                    {notificationState === "accepted" ? "You've accepted " :
                        notificationState === "refused" ? "You've refused " : null}
                    <UsernameText /> {" "}
                    {notificationState === "pending" ? "sent you a direct message request:" : "direct message request :"}
                    <NotificationText>{notification.text}</NotificationText>
                </NormalText>
            case "get_referral_reward":
                return <NormalText>
                    <UsernameText />
                    {""} used your refer link.
                </NormalText>
            case "used_referral":
                return <NormalText>
                    You've used <UsernameText /> referral link.
                </NormalText>
            case "system_message":
                return <NotificationText>
                    {notification.text}
                </NotificationText>
            default:
                return <p>{notification.text || ""}</p>
        }
    }

    const ChooseButton = () => {

        if (notificationState !== "pending") return null;

        switch (notification.type) {

            case "like":
            case "request_accepted":
                return null

            case "get_referral_reward":
            case "used_referral":
            case "system_message":
            case "comment":
                if(!notification.amount) return null;
                return <Button
                    text={"Claim " + notification.amount + "$"}
                    size={"medium"}
                    isLoading={loading}
                    onClick={ClaimReward}
                    isSuccess={successClaimReward}
                />
            case "request":
            case "request_message":
                return <div className="flex-center">
                    <Button
                        text={"Accept"}
                        isLoading={loadingAccept}
                        onClick={() => AcceptOrRefuseRequest(true)}
                        size={"medium"}
                        style={{ marginRight: 10 }}

                    />
                    <Button
                        text={"Refuse"}
                        isLoading={loadingRefuse}
                        onClick={() => AcceptOrRefuseRequest(false)}
                        size={"medium"}
                        style={{ marginLeft: 10 }}

                    />
                </div>
        }
    }

    const ShowTime = () => {
        if (!notification.timestamp)
            return null
        const time = moment(notification.timestamp.toDate()).fromNow()
        return <DateText>{time}</DateText>
    }

    const UsernameText = () => {
        return <StyledUsernameLink to={"/user/" + notification.from} >{user?.username || "username"}</StyledUsernameLink>
    }

    const NotificationAvatar = () => {
        if (notification.image) {
            return <AvatarNotificationImage src={notification.image} alt="thumbnail" />
        } else if (notification.text) {
            return <AvatarNotificationText>
                {notification.text.substring(0, 5)}
            </AvatarNotificationText>
        } else
            return null

    }

    return ( 
        <CardContainer>
            <CardHeader>
                <Avatar height={35} user={user}/>
            </CardHeader>
            <CardBody>
                <ChooseText />
                <CardFooter>
                    <ChooseButton />
                </CardFooter>
            </CardBody>
        </CardContainer>
    )
}

const StyledUsernameLink = styled(Link)`
    font-size: ${props => props.fontsize ? props.fontsize : "16px"};
    margin: 0;
    overflow-wrap: break-word;
    &:hover{
        opacity: 0.7;
    }
`

const NormalText = styled.p`
    margin: 0;
`
const NotificationText = styled.p`
    font-weight: bold;
`
const DateText = styled.p`
    font-weight: 500;
    font-size: 0.7em;
`
const AvatarNotificationImage = styled.img`
    height: 45px;
    width: 45px;
    border-radius: 5px;
    align-self: center;
`
const AvatarNotificationText = styled.div`
    display: flex;
    height: 45px;
    width: 45px;
    border-radius: 5px;
    align-self: center;
    background-color: #f9f9f9;
    justify-content: center;
    align-items: center;
`

const CardContainer = styled.div`
    display: flex;
    //border: 1px solid #ccc;
    padding: 10px 0px;
`
const CardHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 10px;
`
const CardBody = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const CardFooter = styled.div`
    display: flex;
    margin-top: 10px;
`