import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Avatar from "../Components/User/Avatar"
import {useUserState} from "../Context/UserContext"
import Aux from "../Managers/AuxManager"
import DataManager from "../Managers/DataManager"

export default function MessageCard({item, targetUser}){

    const [message] = useState(item);
    const [user, setUser] = useState()
    const [noUser, setNoUser] = useState(false)
    const {currentUser} = useUserState();
    const [loadingUser, setLoadingUser] = useState(false)

    useEffect(() => {
        
        const otherUser = message.users?.find(user => user !== currentUser.uid)
        
        if(otherUser)
            getUserInfo(otherUser)
        
    }, [])

    const getUserInfo = async (id) => {
        if (loadingUser || !id) return
        try {
 
            setLoadingUser(true);
            await DataManager.getUserInfo(id).then((data) => {
                if (data.exists()) {
                    
                    setUser({ ...data.data(), uid: id })
                }else{
                    //console.log("No user found")
                }
            })
            
            setLoadingUser(false);
        } catch (error) {
            Aux.handleError(error)
            setLoadingUser(false);
        }
    }

    return(
    <StyledLink to={`/messages/${message.id}`} >
        <MessageContainer>
            <Avatar user={user}/>
            <MessageWrapper>
                <UsernameParaghf>{Aux.getUsername(user?.username, loadingUser)}</UsernameParaghf>
                <LastMessageText>{message.lastMessage?.text}</LastMessageText>
            </MessageWrapper>
        </MessageContainer>
    </StyledLink>
    )
}

const StyledLink = styled(Link)`
    display: flex;
    //justify-content: center;
    cursor: pointer;
    text-decoration: none;
`
const MessageContainer = styled.div`
    display: flex;

`

const MessageWrapper = styled.div`
    margin-left: 10px;

`

const UsernameParaghf = styled.p`
    margin-top: 0px;
`

const LastMessageText = styled.p`

    color: ${props => props.isRead ? "#ccc" : "black"};
    font-weight: ${props => props.isRead ? "500" : "bold"};;

`