import {useState} from 'react'
import styled from "styled-components"
import Aux from '../Managers/AuxManager';
import Colors from '../Utilities/Colors';
import { InformationTitle } from './HelperComponents';

export default function SimpleInput({value, placeholder, onChange, defaultValue, isHashtag, type, style, title, textArea, className, prefix, prefixIcon, maxLenght, onKeyPress}) {

    function handleChange(e) {
        //setValue(e.target.value);
        var text = e.target.value;
        if(isHashtag){
            text = text.replace(/\s/g, "")
            text = Aux.removeSpecialCharactersForHashtag(text)
        }
        onChange(text);
    }

    const Icon = prefixIcon
    
    return(
        <>
            {textArea ?
            <>
             {title && <InformationTitle>{title}</InformationTitle>}
            <TextAreaInput as="textarea" className={`${className}`} value={value} placeholder={placeholder} defaultValue={defaultValue} onChange={handleChange} maxLength={maxLenght}/>
            </> :
            <InputContainer style={{...style}} >
                {prefix && 
                    <InputPrefix>
                        {prefixIcon && <Icon style={{marginRight:5}}/>}
                        <span>{prefix}</span>
                     </InputPrefix>
                }
                <div className='flex column w-100'>
                    {title && <InformationTitle>{title}</InformationTitle>}
                    <StyledInput className={`${className}`} type={type || "text"} value={value} defaultValue={defaultValue} 
                    placeholder={placeholder} onChange={handleChange} maxLength={maxLenght} onKeyDown={onKeyPress}/>
                </div>
            </InputContainer>
            }

        </>
    )
}

const StyledInput = styled.input`
    font-size: 15px;
    color: #555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 32px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #ddd;
    box-sizing: border-box;
    background-color: #f7f7f7;

    &:hover {
        border: 2px solid rgba(99,99,99,.5);
    }

    &:focus{
        outline: none;
        border: 2px solid #636363;
    }
`

const TitleText = styled.p`

`
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
`

const InputPrefix = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 10px;
    font-size: 15px;
    margin-right: 5px;
    height: 32px;
    border-radius: 3px;
    //border: 1px solid #ced4da;
    background-color: ${Colors.secundaryLighter};
`

const TextAreaInput = styled(StyledInput)`
    resize: vertical;
    padding-top: 6px;
    height: 70px;
    min-height: 50px;
`