import styled from "styled-components"
import Button from "../Components/Button"
import Colors from "../Utilities/Colors";
import Popup from 'reactjs-popup';

export default function DialogModal({ active, onConfirm, onClose, text }) {

    return (
        <StyledPopup
            open={active}
            onClose={onClose}
            modal
            closeOnDocumentClick >
            {close =>
                <>
                    <StyledText>{text || "Are you sure?"}</StyledText>
                    <div className="flex-center column align-center">
                        <Button
                            text="Confirm"
                            onClick={() => { onConfirm(); close() }}
                        />
                        <StyledCancelText onClick={() => { onClose(); close() }}>Close</StyledCancelText>
                    </div>
                </>
            }
        </StyledPopup>
    )
}

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 

    }  
    // use your custom style for ".popup-content"  
    &-content {    
        display: flex;
        max-width: 250px;
        flex-direction: column;
        padding: 40px;
        align-items: center;
        background-color: white;
        border-radius: 5px;
    }
`;

const StyledCancelText = styled.p`
margin-top: 20px;
    color: ${Colors.secundaryDarker};
    cursor: pointer;
    &:hover{
        color:${Colors.secundaryDarkest}
    }
`

const StyledText = styled.h3`
    text-align: center;
    margin-bottom: 30px;
`

