import Avatar from "../Components/User/Avatar";
import styled from 'styled-components'
import UsernameText from "../Components/User/UsernameText";
import { useEffect, useState } from "react";
import Aux from "../Managers/AuxManager";
import DataManager from "../Managers/DataManager";


export default function CommentCard({item}){

    const [comment] = useState(item)
    const [user, setUser] = useState({})
    
    useEffect(() => {
        if(comment.from){
            getUser();
        }
    }, [comment])

    const getUser = async() => {
        try {
            const data = await DataManager.getUserInfo(comment.from)
            if(data.exists){
                setUser(data.data())
            }
        } catch (error) {
            Aux.handleError(error)
        }
    }

    return(
            <CardContainer>
                <Avatar />
                <CardBody>
                    <UsernameText user={user}></UsernameText>
                    <span>{comment.text}</span>
                </CardBody>
                {comment.amount > 0 && <AmountText>{comment.amount}$</AmountText>}
            </CardContainer>
    )
}

const CardContainer = styled.div`
    display: flex;
    border: 1px solid #ccc;
    //max-width: 300px;
`

const CardBody = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
`

const AmountText = styled.h3`
    margin: 0px;
`