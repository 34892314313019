import styled from "styled-components"

export default function YoutubeEmbed({embedId}) {

    return (
        <VideoContainer>
            <StyledIframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="autoplay;  encrypted-media;"
                allowFullScreen
                title="Embedded youtube"
                
            
            ></StyledIframe>
        </VideoContainer>
    )
}

const VideoContainer = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
`

const StyledIframe = styled.iframe`
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;

`