
import styled from "styled-components"
import { Link } from "react-router-dom"
import Header from "../Components/Header"

import PencilIcon from "mdi-react/PencilIcon"
import LockIcon from "mdi-react/LockIcon"
import EmailIcon from "mdi-react/EmailIcon"
import AccountIcon from "mdi-react/AccountIcon"
import PhoneIcon from "mdi-react/PhoneIcon"
import FileDocumentIcon from "mdi-react/FileDocumentIcon"

import LogoutIcon from "mdi-react/LogoutIcon"
import { SubSubHeader } from "../Components/HelperComponents"
import DialogModal from "../Modal/DialogModal"
import { useState } from "react"
import Container from "../Components/Container"
import ArrowRight from "mdi-react/ChevronRightIcon"
import Colors from "../Utilities/Colors"
import { useAuthState } from "../Context/AuthContext"
//import ChevronRightIcon from "mdi-react/ChevronRightIcon"

export default function Settings(props){

    const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
    const {logout} = useAuthState()

    const MenuItem = ({Icon, text, to, onClick}) => {
        return <MenuItemLink as={to ? undefined : "div"} to={to ? to : undefined} onClick={to ? undefined : onClick}>
            <div className="flex-between align-center">
                <div className="flex align-center">
                    <Icon></Icon>
                    <MenuText>{text}</MenuText>
                </div>
                {to && <ArrowRight/>}
            </div>
        </MenuItemLink>
    }

    /* const MenuWithoutLink = ({Icon, text, OnClick}) => {
        <div className="flex align-center">
            <Icon/>
            <p>{text}</p>
        </div>
    } */
    const Logout = () => {
        logout();
    }


    return (
        <Container>
            <DialogModal onConfirm={Logout} text={"Are you sure you want to logout?"} active={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)}/>
            <Header text="Settings"/>
            <div className="flex column">
                <MenuItem to="/account/edit" text="Edit Profile" Icon={PencilIcon}/>
                <MenuItem to="/account/password" text="Change Password" Icon={LockIcon}/>
                <MenuItem to="/account/email" text="Change Email" Icon={EmailIcon}/>
                <MenuItem to="/account/refer" text="Referrals and Promo Codes" Icon={AccountIcon}/>
                <MenuItem to="/contact" text="Contact Us" Icon={PhoneIcon}/>
                <MenuItem to="/privacy" text="Privacy Policy" Icon={FileDocumentIcon}/>
                <SubSubHeader>Account</SubSubHeader>
                <MenuItem text={"Logout"} onClick={() => setOpenLogoutDialog(true)} Icon={LogoutIcon}/> 
            </div>
        </Container>
    )

}

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const MenuIcon = styled.div`
`
const MenuText = styled.p`
    margin-left: 10px;
`
const MenuItemLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${Colors.textDarkest};
    padding: 5px 10px;
    &:hover{
        background-color: ${Colors.secundaryLightest};
        border-radius: 5px;
    }
`