
import React, { useState } from "react";
import Button from "../Components/Button";
import Container from "../Components/Container";
import Header from "../Components/Header";
import MinusIcon from 'mdi-react/MinusIcon'
import PlusIcon from "mdi-react/PlusIcon"
import NumberFormat from "react-number-format";
import styled from 'styled-components'
import { useUserState } from "../Context/UserContext";

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from "axios";
import AddAmount from "../Utilities/AddAmount";
import Aux from "../Managers/AuxManager";
import CreditCardImage from "../Assets/CreditCardImage";
import PaypalImage from "../Assets/PaypalImage";
import CreditCardMinimal from "../Assets/CreditCardMinimal";
import PayPalMinimal from "../Assets/PayPalMinimal";
import SelectOption from "../Components/SelectOption";
import InputTextMaterial from "../Components/InputTextMaterial";
import { PayPalButtons } from "@paypal/react-paypal-js";
import CheckIfLogged from "../Checks/CheckIfLogged";
import AlertMessage from "../Components/AlertMessage";
import Loader from "../Components/Loader";

export default function AddBalance(props) {

    const [amount, setAmount] = useState(0)
    const [email, setEmail] = useState("")
    const [holderName, setHolderName] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [currentOption, setCurrentOption] = useState("card")
    const [currentTab, setCurrentTab] = useState(0)
    const [stripeIsReady, setStripeIsReady] = useState(false)
    const { currentUser } = useUserState()

    const [loadingStripe, setLoadingStripe] = useState(false)
    const [error, setError] = useState("")

    const stripe = useStripe();
    const elements = useElements();

    const cardOptions = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
        hidePostalCode: true,
        disabled: loadingStripe,
    };


    const submitStripe = async () => {
        if (!stripe || !elements) return
        if (!amount || amount <= 0) return
        if (loadingStripe) return

        try {
            setLoadingStripe(true)
            const { data: clientSecret } = await axios.post(process.env.REACT_APP_API_URL_PAY_STRIPE, { email: email, amount: amount * 100 })
            //console.log(response.json())
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: email,
                        name: holderName,
                    },
                }
            });
            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                setError(result.error.message)
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    //await Fire.shared.addCoinsWithReceipt(amount, result.paymentIntent)
                    //setAddCard(false)
                    setIsSuccess(true)
                    setHolderName("")

                }
            }
            setLoadingStripe(false)
        } catch (error) {
            Aux.handleError(error)
            setError(error)
            setLoadingStripe(false)
        }
    }

    //o factor limitante é o facto da base dos scores corresponde ao segmento superior da normalidade 

    const createOrder = (data, actions) => {
        if (!amount || amount < 0) return;

        try {
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: amount,
                        },
                    },
                ],
            });
        } catch (error) {
            Aux.handleError(error)
            setError(Aux.UnexpectedError)
        }

    }

    const onApprove = async (data, actions) => {
        try {
            let order = await actions.order.capture();
            if (order.status === "COMPLETED") {
                //await Fire.shared.addCoinsWithReceipt(this.state.coins, order)
                setIsSuccess(true);

            } else {
                setError(Aux.UnexpectedError)
            }
            return order;
        } catch (error) {
            Aux.handleError(error)
            setError(Aux.UnexpectedError)
        }
    }

    //const disabled = amount > 0 ? isCardComplete ? holderName !== "" ? false : true : true : true

    return (
        <>
            <Container>
                <Header text="Add Balance" />
                <AlertMessage variant="error" text={error} />
                {currentUser.uid ?
                    <>
                        {isSuccess ?
                            <div>
                                <p>Success!!!!!</p>
                            </div>
                            :

                            currentTab === 0 ?
                                <BalanceContainer>
                                    <AddAmount amount={amount} setAmount={setAmount} isBalance />
                                    <div className="flex-end" style={{ marginTop: 20 }}>
                                        <Button text={"Continue"} isDisabled={amount <= 0} onClick={() => setCurrentTab(1)} style={{ width: "50%" }} />
                                    </div>
                                </BalanceContainer>

                                :
                                <PaymentContainer>
                                    <SelectPaymentText>Select Payment</SelectPaymentText>
                                    <div className="flex-center">
                                        <SelectOption active={currentOption === "card"} onSelect={() => setCurrentOption("card")} Render={CreditCardMinimal} style={{ marginRight: 10 }} />
                                        <SelectOption active={currentOption === "paypal"} onSelect={() => setCurrentOption("paypal")} Render={PayPalMinimal} />
                                    </div>

                                    {currentOption === "card" ?
                                        <div>
                                            {stripeIsReady && <InputTextMaterial title={"Name"} value={holderName} onChange={setHolderName} />}
                                            <CardElementWrapper>
                                                <CardElement options={cardOptions} onReady={() => setStripeIsReady(true)} onChange={(cardDetails) => {
                                                    if (cardDetails.complete) {
                                                        setIsSuccess(true)
                                                    } else if (cardDetails.error) {
                                                        setError(cardDetails.error.message)
                                                    } else {
                                                        //setIsSuccess(false)
                                                        //setError(Aux.UnexpectedError)
                                                    }
                                                }} />
                                            </CardElementWrapper>
                                            {!stripeIsReady && <Loader />}
                                        </div>
                                        :
                                        currentOption === "paypal" ?
                                            <div>
                                                <span>Amount: {amount}</span>
                                                <PayPalButtons
                                                    style={{ layout: "horizontal" }}
                                                    createOrder={(data, actions) => createOrder(data, actions)}
                                                    onApprove={(data, actions) => onApprove(data, actions)}
                                                    onCancel={() => Aux.handleError("Canceled")}
                                                    onError={(err) => setError(err)}
                                                ></PayPalButtons>
                                            </div>
                                            : null
                                    }


                                    <div className="flex-between">
                                        <Button text={"Back"} variant={"secundary"} onClick={() => setCurrentTab(0)} />
                                        <Button text={"Confirm Payment"} onClick={() => { }} />
                                    </div>

                                </PaymentContainer>


                        }
                    </>
                    : <CheckIfLogged />}
            </Container>
        </>
    )
}

const CardElementWrapper = styled.div`

   
    padding: 15px 10px;
    border: 2px #ccc solid;
    border-radius: 4px;
    margin-bottom: 20px;
`

const BalanceContainer = styled.div`

`
const PaymentContainer = styled.div`

`

const SelectPaymentText = styled.h3`

`