import styled, { css } from 'styled-components'
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import Avatar from './User/Avatar';
import ArrowLeft from "mdi-react/ChevronLeftIcon"
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function Header({text, onClose, user, hideClose, style, goBack}){

    const navigate = useNavigate()
    return(
        <StyledHeader style={style}>
            <TitleContainer goBack={goBack} onClick={() => goBack ? navigate(-1) : null}>
                {goBack && <ArrowLeft size={30} style={{marginRight:10}}/>}
                <h1>{text}</h1>
            </TitleContainer>
            {onClose && !hideClose ? <StyledCloseIcon onClick={onClose}/> 
            :
            user ? <Avatar user={user} height={35}/>
            : null}
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

const TitleContainer = styled.div`
    display: flex;
    align-items:center;

    ${props => props.goBack && css`
        cursor: pointer;
       
        &:hover{
            opacity:0.7;
        }
    `}
`

const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    margin-left:10px;
    &:hover{
        opacity:0.7;
    }
`
const StyledArrowLeft = styled(ArrowLeft)`
    cursor: pointer;
    margin-right: 10px;
    &:hover{
        opacity:0.7;
    }
`

