import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller';
import Popup from 'reactjs-popup';
import { createRef, useEffect, useState } from 'react';
import Aux from '../Managers/AuxManager';
import { useUserState } from '../Context/UserContext';
import Loader from '../Components/Loader';
import NotificationCard from './NotificationCard';
import Header from '../Components/Header';
import ShowList from '../Components/ShowList';
import DataManager from '../Managers/DataManager';

export default function ShowNotifications(props) {

    const [thisNotifications, setThisNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [lastVisible, setLastVisible] = useState(null)
    const { getMoreNotifications, notificationState, notifications, hasNewNotification, setNewNotification, canGetMoreNotifications } = useUserState();
    const [isMobile, setIsMobile] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    /* 
    useEffect(() => {
        if(!isOpen)
            setIsMobile(props.isMobile)
    }, [props.isMobile]) 
    */

    const getNotifications = async () => {

        //console.log("entrou")
        if(!canGetMoreNotifications) return
        await getMoreNotifications();

        /* if (notificationState === "finished")
            setIsFinished(true);
        else if (notificationState === "loading")
            setLoading(true);
        else if (notificationState === "error")
            Aux.handleError("notification error") */
    }


    const TriggerOpen = () => {

        useEffect(() => {
            setIsMobile(Aux.checkIfMobile())
        }, [])

        return props.trigger;
    }

    const OnOpen = () => {
        setIsOpen(true)
        if(hasNewNotification && notifications[0]){
            /* console.log("set new last notification")
            console.log(notifications[0]) */
            setNewNotification(false)
            DataManager.setNewLastNotification(notifications[0].id)
        }
    }
    //const isMobile = props.isMobile
    return (
        <StyledPopup
            trigger={<div><TriggerOpen/></div>}
            position={"bottom right"}
            closeOnDocumentClick
            onClose={() => setIsOpen(false)}
            onOpen={OnOpen}
            //keepTooltipInside="#main-sidebar"
            modal={isMobile}
            isMobile={isMobile}
            arrow={true}
        //repositionOnResize
        >
            {close => (
                <MainContainer>
                    <Header text="Notifications" onClose={() =>  close()} hideClose={!isMobile} style={{marginBottom:10}}/>
                    {/* <StyledInfiniteScroll
                        loadMore={getNotifications}
                        hasMore={!isFinished}
                        useWindow={false}
                        //getScrollParent={() => popupRef.current}
                        loader={<div className='m-10' key="loader-notifications"><Loader /></div>}
                    >
                        {notifications.map((not, index) => {
                            return <NotificationCard notification={not} key={not.id} />
                        })}

                    </StyledInfiniteScroll>
                    {isFinished && <p>No more notifications</p>} */}

                    <ShowList
                        Render={NotificationCard}
                        hasMore={canGetMoreNotifications}
                        loadMore={getNotifications}
                        list={notifications}
                        endText={"There's no more notifications."}
                        emptyText={"No notifications yet."}
                    />
                </MainContainer>
            )}
        </StyledPopup>
    )
}

const MainContainer = styled.div`
    box-sizing: border-box;
    padding: 10px 20px;
`

const StyledPopup = styled(Popup)`  
    // use your custom style for ".popup-overlay"  
    &-overlay { 
        background-color: transparent;
    }
    // use your custom style for ".popup-content"  
    &-content {    
        background-color: white;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 400px;
        min-height: 400px;
        max-height: ${props => props.isMobile ? "80%" : "400px"};
        border-radius: 10px;
        //border: 2px #ccc solid;
        box-shadow: 0 0 3px rgba(0,0,0,.16);
    }
`;

